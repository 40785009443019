import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { filter, first, tap } from "rxjs/operators";
import { UwlEndpointEntityService } from "../services/uwl-endpoint-entity.service";
import { TokenHelperService } from "gematik-form-library";

@Injectable()
export class DashboardResolver  {
  constructor(
    private uwlEndpointService: UwlEndpointEntityService,
    private tokenHelperService: TokenHelperService,
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (!this.tokenHelperService.isLoggedIn()) {
      return of(false);
    }
    return this.uwlEndpointService.loaded$.pipe(
      tap((loaded) => {
        if (!loaded) {
          this.uwlEndpointService.getAll();
        }
      }),
      filter((loaded) => !!loaded),
      first(),
    );
  }
}

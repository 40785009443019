import { Subscription } from "rxjs";
import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
import { FormHelperService } from "../../helper/form-helper.service";

@Component({
  selector: "gem-input-decimal",
  templateUrl: "./gem-input-decimal.component.html",
  styleUrls: ["./gem-input-decimal.component.scss"],
})
export class GemInputDecimalComponent implements OnInit, OnDestroy {
  inputControl: UntypedFormControl;
  requiredErrorMessage: string;
  noValidNumberErrorMessage: string;
  langSubscription: Subscription;

  @Input() placeholder: string;
  @Input() hint = "";
  @Input() type = "text";
  @Input() suffix = "";
  @Input() digits = 2;

  @Input() options: any;

  isRequired: boolean;

  @Input() set control(controlObj: UntypedFormControl) {
    this.inputControl = controlObj;
    this.isRequired = this.formHelper.checkRequiredValidator(this.inputControl);
    const placeholderInputValue = this.placeholder;
    const hintInputValue = this.hint;
    if (this.placeholder && this.placeholder !== "") {
      this.placeholder = this.translateService.instant(this.placeholder);
    }
    if (this.hint !== "") {
      this.hint = this.translateService.instant(this.hint);
    }
    this.langSubscription = this.translateService.onLangChange.subscribe(
      (event: LangChangeEvent) => {
        if (this.placeholder && this.placeholder !== "") {
          this.placeholder = this.translateService.instant(placeholderInputValue);
        }
        if (this.hint !== "") {
          this.hint = this.translateService.instant(hintInputValue);
        }
      },
    );
    this.requiredErrorMessage = this.formHelper.createRequiredErrorMessage(this.placeholder);
    this.noValidNumberErrorMessage = this.formHelper.createNoValidNumberErrorMessage(
      this.placeholder,
    );
  }

  get control(): UntypedFormControl {
    return this.inputControl;
  }

  constructor(private translateService: TranslateService, private formHelper: FormHelperService) {}

  ngOnInit() {}

  trim() {
    this.formHelper.trimWhitespace(this.control);
  }

  ngOnDestroy() {
    if (this.langSubscription) {
      this.langSubscription.unsubscribe();
    }
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { FormHelperService } from "../../helper/form-helper.service";
import { Observable, Subscription } from "rxjs";
import { delay, map, startWith } from "rxjs/operators";
import { ValueLabel } from "../value-label";

@Component({
  selector: "gem-autocomplete",
  templateUrl: "./gem-autocomplete.component.html",
  styleUrls: ["./gem-autocomplete.component.scss"],
})
export class GemAutocompleteComponent implements OnInit {
  selectedValue: ValueLabel;
  inputControl = new UntypedFormControl();
  suggestControl = new UntypedFormControl();
  suggestOptions: ValueLabel[];
  filteredOptions: Observable<ValueLabel[]>;
  requiredErrorMessage: string;
  langSubscription: Subscription;
  isRequired: boolean;

  @Input() placeholder: string;
  @Input() delete: boolean;

  @Input() set options(options: ValueLabel[]) {
    this.suggestOptions = options;
    this.preSelectOption();
  }

  get options(): ValueLabel[] {
    return this.suggestOptions;
  }

  @Input() set control(controlObj: UntypedFormControl) {
    this.suggestControl = controlObj;
    this.isRequired = this.formHelper.checkRequiredValidator(this.suggestControl);

    const placeholderInputValue = this.placeholder;

    if (this.placeholder) {
      this.placeholder = this.translateService.instant(this.placeholder);
    }

    this.langSubscription = this.translateService.onLangChange.subscribe(
      (event: LangChangeEvent) => {
        if (this.placeholder && this.placeholder !== "") {
          this.placeholder = this.translateService.instant(placeholderInputValue);
        }
      },
    );

    this.requiredErrorMessage = this.formHelper.createRequiredErrorMessage(this.placeholder);
    if (this.suggestControl.value) {
      this.inputControl.disable({ emitEvent: false });
    }
  }

  @Output() changed: EventEmitter<any> = new EventEmitter();

  get control(): UntypedFormControl {
    return this.suggestControl;
  }

  constructor(private formHelper: FormHelperService, private translateService: TranslateService) {}

  ngOnInit() {
    this.setFilteredOptions();
  }

  ngOnChanges() {
    this.setFilteredOptions();
  }

  setFilteredOptions() {
    this.filteredOptions = this.inputControl?.valueChanges.pipe(
      delay(0),
      startWith<string | ValueLabel>(""),
      map((value) => (typeof value === "string" ? value : value.label)),
      map((label) => (label ? this._filter(label) : this.suggestOptions?.slice())),
    );
  }

  selected(event) {
    if (event && event.option && event.option.value && event.option.value.value) {
      this.suggestControl.setValue(event.option.value.value);
      this.changed.emit(event.option.value.value);
    }
    this.checkIfValid(event.option.value.value);
  }

  selectedFromTabEvent(event) {
    this.suggestControl.setValue(event?.value);
    this.checkIfValid(event?.value);
  }

  displayFn(option?: ValueLabel): string | undefined {
    return option ? option.label : undefined;
  }

  preSelectOption() {
    if (
      this.suggestControl &&
      this.suggestControl?.value &&
      this.suggestControl?.value !== "" &&
      this.suggestOptions?.length > 0
    ) {
      for (const option of this.suggestOptions) {
        if (option.value === this.suggestControl?.value) {
          this.inputControl.setValue(option);
        }
      }
    } else if (
      this.suggestControl &&
      this.suggestControl?.value == null &&
      this.inputControl.disabled
    ) {
      this.clearSelection();
    }
  }

  clearSelection() {
    this.suggestControl.setValue(null);
    this.inputControl.setValue("");
  }

  private _filter(label: string): ValueLabel[] {
    const filterValue = label.toLowerCase();
    return this.suggestOptions.filter(
      (option) => option.label.toLowerCase().indexOf(filterValue) === 0,
    );
  }

  checkIfValid(value) {
    if (value !== this.suggestControl?.value) {
      this.inputControl.setErrors({ required: true });
    }
    this.isRequired = this.formHelper.checkRequiredValidator(this.suggestControl);
  }

  ngOnDestroy() {
    if (this.langSubscription) {
      this.langSubscription.unsubscribe();
    }
  }
}

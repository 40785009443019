<!-- <div>
  <h1 mat-dialog-title class="mat-dialog-title">{{ title }}</h1>
  <div class="mb-3">
    <div class="d-flex align-items-center justify-content-center flex-row">
      <div class="timeline-point p-1"></div>
      <div class="p-1 pe-3">{{ legendOpen }}</div>
      <div class="timeline-point-current p-1"></div>
      <div class="p-1 pe-3">{{ legendCurrent }}</div>
      <div class="timeline-point-finished p-1"></div>
      <div class="p-1">{{ legendFinished }}</div>
    </div>
  </div>
</div> -->
<div class="timeline">
  <div
    *ngFor="let item of value; last as isLast; first as isFirst; let i = index"
    class="timeline-event"
  >
    <div class="timeline-point-track-container">
      <div class="{{ 'timeline-point-' + item?.status }}"></div>
      <div *ngIf="!isLast" [ngClass]="getClassName(i)"></div>
    </div>
    <div class="timeline-content">
      <div>
        <p
          style="font-weight: bold"
          [style.color]="item.status !== 'OPEN' ? 'var(--gematik-blue)' : ''"
          [innerHTML]="item.label"
        ></p>
        <p *ngIf="item.description" [ngbTooltip]="item.description">
          {{ item.description }}
        </p>
        <p class="mb-4">{{ item.date | date : "dd.MM.yyyy, HH:mm:ss" }}</p>
      </div>
    </div>
  </div>
</div>

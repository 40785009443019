import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { Feedback } from "../../models/feedback.model";
import { FormBuilder, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { GemValidators, TokenHelperService } from "gematik-form-library";

@Component({
  selector: "gem-feedback-bug-form",
  styleUrls: ["gem-feedback-bug-form.component.scss"],
  template: `
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="row mb-3">
        <div class="col-sm-12">
          <div class="alert alert-danger gem-alert" role="alert">
            {{ "feedback.bugTitle" | translate }}
          </div>
        </div>
        <div class="col-sm-12 mt-2">
          <p [innerHTML]="'feedback.bugText' | translate"></p>
        </div>
        <div class="col-sm-12 mt-2">
          <gem-textarea
            [placeholder]="'feedback.bugComments' | translate"
            [control]="this.form.get('comments')"
            [maxChars]="500"
            [hint]="
              translateService.instant('feedback.commentsHint') +
              ' (' +
              this.form.get('comments').value.length +
              ' / 500' +
              ')'
            "
          ></gem-textarea>
        </div>

        <div class="col-sm-12 mt-5">
          <gem-input
            [placeholder]="'email' | translate"
            [hint]="'feedback.emailHint' | translate"
            [control]="this.form.get('email')"
          >
          </gem-input>
        </div>

        <div class="col-sm-12 text-center mt-3">
          <button
            class="mat-focus-indicator btn btn-primary mat-button mat-button-base"
            cdkFocusInitial
            [disabled]="!isFormValid()"
          >
            {{ "feedback.send" | translate }}
          </button>
        </div>
      </div>
    </form>
  `,
})
export class GemFeedbackBugFormComponent implements OnInit {
  @Output() feedback: EventEmitter<Feedback> = new EventEmitter<Feedback>();

  form = this.fb.group({
    category: ["BUG", Validators.required],
    rating: [1],
    comments: ["", Validators.required],
    contact: ["WITH_EMAIL"],
    email: ["", [Validators.required, GemValidators.email]],
  });

  radioButtonOptions = [
    { value: "ANONYMOUS", label: this.translateService.instant("feedback.radioButtonAnonymous") },
    { value: "WITH_EMAIL", label: this.translateService.instant("feedback.radioButtonWithEmail") },
  ];

  constructor(
    private fb: FormBuilder,
    public translateService: TranslateService,
    private tokenHelperService: TokenHelperService,
  ) {}

  ngOnInit(): void {
    if (this.tokenHelperService.isLoggedIn()) {
      this.form.get("email").setValue(this.tokenHelperService.getEmail());
    }
  }

  isFormValid(): boolean {
    return this.form.valid;
  }

  onSubmit(): void {
    const feedback: Feedback = this.form.getRawValue();
    this.feedback.emit(feedback);
  }
}

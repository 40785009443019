import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";

import { FormHelperService, TokenHelperService, ValueLabel } from "gematik-form-library";
import { GematikTaskApiService, GematikProcessDto } from "gematik-task-api";
import { GematikProcessDiagram } from "projects/gematik-task-api/src/public-api";
import { GemBpmnDialogComponent } from "../gem-bpmn-dialog/gem-bpmn-dialog.component";
import { DomSanitizer } from "@angular/platform-browser";
import { Observable, Subscription, of } from "rxjs";
import { NavigationEnd, NavigationStart, Router, Scroll } from "@angular/router";
import { trigger, state, transition, style, animate } from "@angular/animations";

import { Store } from "@ngrx/store";
import * as fromStore from "../../../store";
import { map } from "rxjs/operators";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { BreakpointObserver } from "@angular/cdk/layout";
import { TranslateService } from "@ngx-translate/core";
import { DatePipe } from "@angular/common";

@Component({
  selector: "processes-table",
  templateUrl: "./processes-table.component.html",
  styleUrls: ["./processes-table.component.scss"],
  animations: [
    trigger("detailExpand", [
      state("collapsed, void", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition("expanded <=> collapsed", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")),
      transition("expanded <=> void", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")),
    ]),
  ],
})
export class ProcessesTableComponent implements OnInit, OnChanges, OnDestroy {
  processes: GematikProcessDto[];
  columnDefinitions: string[] = [
    "expand",
    "buid",
    "partner",
    "partnerType",
    "role",
    "processName",
    "currentActvityName",
    "createdTimeStamp",
    "lastUpdate",
    "actions",
  ];

  tabletColumnDefinitions: string[] = ["expand", "buid", "createdTimeStamp", "actions"];
  handsetColumnDefinitions: string[] = ["handset"];

  displayedColumns: string[] = [...this.columnDefinitions];

  tabletBreakpoint: string = "768px";
  tabletBreakpointMatched: boolean = false;
  handsetBreakpoint: string = "540px";
  handsetBreakpointMatched: boolean = false;

  filterText: string = "";
  dataSource: MatTableDataSource<GematikProcessDto>;

  @Output() processSelected = new EventEmitter();
  @Output() clearFilter = new EventEmitter();
  @Input() completed: boolean;
  @Input() searchStr: string;
  @Input() set processList(processes: GematikProcessDto[]) {
    this.processes = processes;
    this.dataSource = new MatTableDataSource(processes);
    // this.dataSource.filterPredicate = this.customFilterPredicate;
    this.dataSource.filterPredicate = (row: any, filter: any) => {
      return this.customFilterPredicate(row, filter);
    };
    this.dataSource.paginator = this.paginator;
    if (this.sort) {
      this.dataSource.sort = this.sort;
    }
    this.cdr.detectChanges();
  }
  @Input() type: string;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  buidFilterOptions$: Observable<ValueLabel[]> = of([]);
  partnerFilterOptions$: Observable<ValueLabel[]> = of([]);
  partnerTypeFilterOptions$: Observable<ValueLabel[]> = of([]);
  processOwnerFilterOptions$: Observable<ValueLabel[]> = of([]);
  processNameFilterOptions$: Observable<ValueLabel[]> = of([]);

  selectedFilters: { [id: string]: ValueLabel[] } | any = {
    buid: [],
    partner: [],
    partnerType: [],
    owner: [],
    name: [],
    searchStr: [],
  };

  reset: { search: boolean };

  subscriptions: Subscription[] = [];

  constructor(
    private cdr: ChangeDetectorRef,
    private formHelper: FormHelperService,
    private dialog: MatDialog,
    private taskService: GematikTaskApiService,
    public domSanitizer: DomSanitizer,
    private tokenHelperService: TokenHelperService,
    private router: Router,
    private store: Store<fromStore.UwlState>,
    private breakpointObserver: BreakpointObserver,
    private destroyRef: DestroyRef,
    private translateService: TranslateService,
    private datePipe: DatePipe,
  ) {}

  ngOnInit() {
    const sub = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.reset = {
          ...this.reset,
          search: false,
        };
      } else if (event instanceof Scroll) {
        if (event.routerEvent instanceof NavigationEnd) {
          this.getFilters(event.routerEvent.url);
        }
      } else if (event instanceof NavigationEnd) {
        this.getFilters(event.url);
      }
    });
    this.subscriptions.push(sub);

    const breakpointSub = this.breakpointObserver
      .observe([`(max-width: ${this.tabletBreakpoint})`, `(max-width: ${this.handsetBreakpoint})`])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((state) => {
        this.tabletBreakpointMatched = state.breakpoints[`(max-width: ${this.tabletBreakpoint})`];
        this.handsetBreakpointMatched = state.breakpoints[`(max-width: ${this.handsetBreakpoint})`];
        if (!this.tabletBreakpointMatched && !this.handsetBreakpointMatched) {
          this.displayedColumns = [...this.columnDefinitions];
        } else if (this.tabletBreakpointMatched && !this.handsetBreakpointMatched) {
          this.displayedColumns = [...this.tabletColumnDefinitions];
        } else if (this.tabletBreakpointMatched && this.handsetBreakpointMatched) {
          this.displayedColumns = [...this.handsetColumnDefinitions];
        } else {
          this.displayedColumns = [...this.columnDefinitions];
        }
      });
    this.subscriptions.push(breakpointSub);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.filter();
    if (changes.searchStr) {
      this.applyFilter(this.searchStr);
    }
  }

  private getFilters(url: string): void {
    this.selectedFilters = {
      buid: [],
      partner: [],
      partnerType: [],
      owner: [],
      name: [],
      searchStr: [],
    };
    this.reset = {
      ...this.reset,
      search: true,
    };
    if (url === "/processes/my") {
      this.buidFilterOptions$ = this.store
        .select(this.completed ? fromStore.getCompletedMyProcesses : fromStore.getActiveMyProcesses)
        .pipe(
          map((processes) => {
            return processes.map((process) => {
              return { value: process.buid, label: process.buid };
            });
          }),
        );
      this.partnerFilterOptions$ = this.store
        .select(this.completed ? fromStore.getCompletedMyProcesses : fromStore.getActiveMyProcesses)
        .pipe(
          map((processes) => {
            return processes.map((process) => process.partner);
          }),
          map((values) => Array.from(new Set(values))),
          map((values) =>
            values
              .filter((value) => value)
              .map((value) => {
                return { value, label: value };
              }),
          ),
        );
      this.partnerTypeFilterOptions$ = this.store
        .select(this.completed ? fromStore.getCompletedMyProcesses : fromStore.getActiveMyProcesses)
        .pipe(
          map((processes) => {
            return processes.map((process) => process.partnerType);
          }),
          map((values) => Array.from(new Set(values))),
          map((values) =>
            values.map((value) => {
              return { value, label: value };
            }),
          ),
        );
      this.processOwnerFilterOptions$ = this.store
        .select(this.completed ? fromStore.getCompletedMyProcesses : fromStore.getActiveMyProcesses)
        .pipe(
          map((processes) => {
            return processes.map((process) => process.owner);
          }),
          map((values) => Array.from(new Set(values))),
          map((values) => {
            return values
              .filter((value) => value)
              .map((value) => {
                return { value, label: value };
              });
          }),
        );
      this.processNameFilterOptions$ = this.store
        .select(this.completed ? fromStore.getCompletedMyProcesses : fromStore.getActiveMyProcesses)
        .pipe(
          map((processes) => {
            return processes.map((process) => process.name);
          }),
          map((values) => Array.from(new Set(values))),
          map((values) =>
            values.map((value) => {
              return { value, label: value };
            }),
          ),
        );
    } else if (url === "/processes/subscriber") {
      this.buidFilterOptions$ = this.store
        .select(
          this.completed
            ? fromStore.getCompletedSubscriberProcesses
            : fromStore.getActiveSubscriberProcesses,
        )
        .pipe(
          map((processes) => {
            return processes.map((process) => {
              return { value: process.buid, label: process.buid };
            });
          }),
        );
      this.partnerFilterOptions$ = this.store
        .select(
          this.completed
            ? fromStore.getCompletedSubscriberProcesses
            : fromStore.getActiveSubscriberProcesses,
        )
        .pipe(
          map((processes) => {
            return processes.map((process) => process.partner);
          }),
          map((values) => Array.from(new Set(values))),
          map((values) =>
            values
              .filter((value) => value)
              .map((value) => {
                return { value, label: value };
              }),
          ),
        );
      this.partnerTypeFilterOptions$ = this.store
        .select(
          this.completed
            ? fromStore.getCompletedSubscriberProcesses
            : fromStore.getActiveSubscriberProcesses,
        )
        .pipe(
          map((processes) => {
            return processes.map((process) => process.partnerType);
          }),
          map((values) => Array.from(new Set(values))),
          map((values) =>
            values.map((value) => {
              return { value, label: value };
            }),
          ),
        );
      this.processOwnerFilterOptions$ = this.store
        .select(
          this.completed
            ? fromStore.getCompletedSubscriberProcesses
            : fromStore.getActiveSubscriberProcesses,
        )
        .pipe(
          map((processes) => {
            return processes.map((process) => process.owner);
          }),
          map((values) => Array.from(new Set(values))),
          map((values) =>
            values
              .filter((value) => value)
              .map((value) => {
                return { value, label: value };
              }),
          ),
        );
      this.processNameFilterOptions$ = this.store
        .select(
          this.completed
            ? fromStore.getCompletedSubscriberProcesses
            : fromStore.getActiveSubscriberProcesses,
        )
        .pipe(
          map((processes) => {
            return processes.map((process) => process.name);
          }),
          map((values) => Array.from(new Set(values))),
          map((values) =>
            values.map((value) => {
              return { value, label: value };
            }),
          ),
        );
    }
  }

  applyFilter(filterValue: string) {
    this.filterText = filterValue.trim();
    this.selectedFilters = {
      ...this.selectedFilters,
      searchStr: [{ value: this.filterText, label: this.filterText }],
    };
    this.dataSource.filter = this.selectedFilters;
    if (this.dataSource.paginator) {
      const paginator = this.dataSource.paginator as MatPaginator;
      if (paginator.hasPreviousPage()) {
        this.dataSource.paginator.firstPage();
        this.cdr.detectChanges();
      }
    }
  }

  processRowClicked(process: GematikProcessDto) {
    if (!this.completed && process.openBpmnDiagram) {
      this.taskService.getProcessDiagram(process.backendUrl, process.id).subscribe((res) => {
        let diagram: GematikProcessDiagram = res.body;
        this.openConfirmationDialog(process, diagram);
      });
    }
  }

  openConfirmationDialog(dto: GematikProcessDto, diagram: GematikProcessDiagram): void {
    this.dialog.open(GemBpmnDialogComponent, {
      data: {
        dto,
        diagram,
      },
      panelClass: "bpmn-dialog-style",
    });
  }

  customFilterPredicate(data: any, filters: { [id: string]: ValueLabel[] }): boolean {
    let expression: string = "";
    Object.keys(filters).forEach((key, index, array) => {
      const vls: ValueLabel[] = filters[key];
      if (vls.length > 0) {
        if (key !== "searchStr") {
          let expr: string = "(";
          vls.forEach((vl, index, array) => {
            expr = expr + `data["${key}"].includes("${vl.value}")`;
            if (index !== array.length - 1) {
              expr = expr + " || ";
            } else {
              expr = expr + ")";
            }
          });
          expression = expression + expr + " && ";
        } else {
          if (vls[0].value) {
            const buidSearchExpr: string = `data["buid"].toLowerCase().includes("${vls[0].value.toLowerCase()}")`;
            const partnerSearchExpr: string = `data["partner"].toLowerCase().includes("${vls[0].value.toLowerCase()}")`;
            const partnerTypeSearchExpr: string = `data["partnerType"].toLowerCase().includes("${vls[0].value.toLowerCase()}")`;
            const processOwnerSearchExpr: string = `data["role"].toLowerCase().includes("${vls[0].value.toLowerCase()}")`;
            const processNameSearchExpr: string = this.translateService
              .instant(data["name"])
              .toLowerCase()
              .includes(vls[0].value.toLowerCase());
            const activityNameSearchExpr: string = this.translateService
              .instant(data["currentActvityName"])
              .toLowerCase()
              .includes(vls[0].value.toLowerCase());
            const createdSearchExpr: string = (
              this.datePipe.transform(data["created"], "dd.MM.YYYY HH:mm") as any
            )
              .toLowerCase()
              .includes(vls[0].value.toLowerCase());
            const lastUpdatedSearchExpr: string = (
              this.datePipe.transform(data["lastUpdate"], "dd.MM.YYYY HH:mm") as any
            )
              .toLowerCase()
              .includes(vls[0].value.toLowerCase());

            let expr: string =
              buidSearchExpr +
              " || " +
              partnerSearchExpr +
              " || " +
              partnerTypeSearchExpr +
              " || " +
              processOwnerSearchExpr +
              " || " +
              processNameSearchExpr +
              " || " +
              activityNameSearchExpr +
              " || " +
              createdSearchExpr +
              " || " +
              lastUpdatedSearchExpr;
            if (expression.length === 0) {
              expression = expr + " && ";
            } else {
              expression = expression + expr + " && ";
            }
          }
        }
      }
    });
    expression = expression.substring(0, expression.lastIndexOf("&&"));

    if (!expression) {
      return true;
    }
    return eval(expression);
  }

  onMatSortChange($event: any) {
    const sortedTasks = Object.create(this.processes);
    if (["createdTimeStamp", "lastUpdate"].includes($event.active)) {
      sortedTasks.sort(this.formHelper.sortTasksByCreatedDate);
    } else if (
      ["priority", "currentActvityName", "partner", "processName"].includes($event.active)
    ) {
      sortedTasks.sort(this.formHelper.sortByProperty($event.active));
    } else if (["task"].includes($event.active)) {
      sortedTasks.sort(this.formHelper.sortTasksByProperty($event.active));
    }

    if ($event.direction === "desc") {
      sortedTasks.reverse();
    }
    this.dataSource = new MatTableDataSource(sortedTasks);
    // this.dataSource.filterPredicate = this.customFilterPredicate;
    this.dataSource.filterPredicate = (row: any, filter: any) => {
      return this.customFilterPredicate(row, filter);
    };
    this.dataSource.paginator = this.paginator;
    this.cdr.detectChanges();
  }

  getCustomerContactFullname(task: any) {
    return task.customerContactFirstname + " " + task.customerContactLastname;
  }

  onViewCaseLogs(row: GematikProcessDto): void {
    const url: string = `${row.crmUrl}/index.php?module=Cases&action=DetailView&record=${row.caseLogId}`;
    window.open(url);
  }

  isUserInternal(row: GematikProcessDto): boolean {
    return this.tokenHelperService.isUserInternal() && row.caseLogId !== null;
  }

  getSelectedFilters(key: string): ValueLabel[] {
    return this.selectedFilters[key];
  }

  onFilterChange(event: { checked: boolean; vl: ValueLabel }, key: string): void {
    const { checked, vl } = event;
    if (checked) {
      this.selectedFilters = {
        ...this.selectedFilters,
        [key]: [...this.selectedFilters[key], vl],
      };
    } else {
      this.onRemoveFilter(key, vl);
    }
    this.filter();
  }

  onRemoveFilter(key: string, filter: ValueLabel): void {
    this.selectedFilters = {
      ...this.selectedFilters,
      [key]: this.getSelectedFilters(key).filter((f) => f !== filter),
    };
    this.filter();
    if (key === "searchStr") {
      this.clearFilter.emit();
    }
  }

  private filter(): void {
    this.dataSource.filter = this.selectedFilters;
    if (this.dataSource.paginator) {
      const paginator = this.dataSource.paginator as MatPaginator;
      if (paginator.hasPreviousPage()) {
        this.dataSource.paginator.firstPage();
        this.cdr.detectChanges();
      }
    }
  }

  getCustomContentData(html: string): string {
    const el = document.createElement("div");
    el.innerHTML = html;
    return el.textContent;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormArray } from "@angular/forms";

@Component({
  selector: "gem-file-drop-zone",
  styleUrls: ["gem-file-drop-zone.component.scss"],
  template: `
    <mat-card
      [appearance]="outlined ? 'outlined' : ''"
      [style]="!outlined ? 'box-shadow: none !important' : ''"
    >
      <mat-card-header
        [style.padding-left]="!outlined ? '0' : ''"
        [style.padding-top]="!title ? '0' : ''"
      >
        <mat-card-title-group>
          <mat-card-title *ngIf="title" data-testid="title">
            {{ title }}
          </mat-card-title>
          <mat-card-subtitle *ngIf="subTitle && !displayOnlyFiles" data-testid="subTitle">{{
            subTitle
          }}</mat-card-subtitle>
        </mat-card-title-group>
      </mat-card-header>
      <mat-card-content [style]="!outlined ? 'padding-left: 0; padding-right: 0' : ''">
        <div *ngIf="!displayOnlyFiles" class="dropzone">
          <input
            *ngIf="!disabled"
            data-testid="fileDrop"
            type="file"
            [accept]="allowedList"
            [multiple]="multiple"
            (drop)="onDrop($event)"
            (change)="onChange($event)"
            (cancel)="onCancel($event)"
            #fileDropRef
            id="fileDropRef"
            style="cursor: pointer"
          />
          <label *ngIf="!label; else customLabel" for="fileDropRef"
            >Drag and Drop your file or
            <b class="gem-color-blue">browse</b>
          </label>
          <ng-template #customLabel for="fileDropRef"
            ><span [innerHTML]="label"></span
          ></ng-template>
          <div class="allowed-types">
            {{ "gem-components.gem-file-upload.allowed-file-types" | translate }} {{ allowedList }}
          </div>
          <div *ngIf="incorrectInput" class="error-alert">{{ errorMessage | translate }}</div>
        </div>
        <div *ngIf="!displayOnlyFiles" class="mt-2">
          <mat-chip-listbox>
            <mat-chip-option
              *ngFor="let file of files.controls; index as i"
              [selectable]="false"
              (click)="onDownload(file)"
              (removed)="onRemove(i)"
            >
              <a
                [attr.href]="file.get('id').value ? file.get('downloadLink').value : null"
                target="_blank"
                >{{ file.get("fileName").value }}</a
              >
              <button matChipRemove *ngIf="!disabled" style="color: red">
                <!-- <mat-icon>delete</mat-icon> -->
                <img src="/gematik-shared/assets/icons/delete.svg" />
              </button>
            </mat-chip-option>
          </mat-chip-listbox>
        </div>
        <div *ngIf="displayOnlyFiles" class="row ">
          <div *ngFor="let file of files.controls; index as i" class="col-sm-12">
            <div>
              <a
                [attr.href]="file.get('id').value ? file.get('downloadLink').value : null"
                target="_blank"
              >
                <img src="/assets/icons/gem-file.svg" width="3%" height="3%" />
                {{ file.get("fileName").value }}</a
              >
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  `,
})
export class GemFileDropZoneComponent implements OnInit {
  @Input() title: string;
  @Input() subTitle: string;
  @Input() label: string;
  @Input() allowedExtensions: string[] = [];
  @Input() multiple: boolean = false;
  @Input() disabled: boolean;
  @Input() files: FormArray;
  @Input() outlined: boolean = true;
  @Input() displayOnlyFiles: boolean = false;

  @Output() upload: EventEmitter<FileList> = new EventEmitter();
  @Output() remove: EventEmitter<number> = new EventEmitter();

  ngOnInit() {
    this.allowedList =
      this.allowedExtensions.length > 0 ? this.allowedExtensions.join(", ") : "any";
  }
  allowedList: string;
  incorrectInput: boolean = false;
  errorMessage: string = "";
  private _files: File[];

  onDrop(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.handleDrop(event.dataTransfer.files);
  }

  onChange(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.handleDrop(event.target.files);
  }

  onCancel(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.handleDrop(event.target.files);
  }

  onDownload(event: any): void {
    if (event && event.value && event.value.id) {
      window.open(event.get("downloadLink").value);
    }
  }

  onRemove(index: number): void {
    this.remove.emit(index);
  }

  private handleDrop(files: FileList) {
    this.errorMessage = "";
    this.incorrectInput = false;
    this._files = [];
    this.incorrectInput = !this.multiple && files.length > 1;
    if (this.incorrectInput) {
      this.incorrectInput = true;
      this.errorMessage = "gem-components.gem-file-upload.one-file-only";
      return;
    }
    this.incorrectInput = !this.validateExtensions(files);
    if (this.incorrectInput) {
      this.errorMessage = "gem-components.gem-file-upload.incorrect-file-extension";
      return;
    }
    this._files = Array.from(files);
    this.upload.emit(files);
  }
  private validateExtensions(files: FileList): boolean {
    if (this.allowedExtensions.length === 0) {
      return true;
    }
    let extensions: string[] = [];
    const extensionPattern = /\.([0-9a-z]+)(?=[?#])|(\.)(?:[\w]+)$/gim;
    Array.from(files).map((x) =>
      x.name
        .toLowerCase()
        .match(extensionPattern)
        .map((ext) => extensions.push(ext)),
    );
    const forbidden = extensions.filter((x) => !this.allowedExtensions.includes(x));
    const valid = forbidden.length === 0;
    return valid;
  }
}

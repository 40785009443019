import { LOCATION_INITIALIZED, registerLocaleData } from "@angular/common";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, Injector, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { TranslateLoader, TranslateModule, TranslateService } from "@ngx-translate/core";
import { AppRoutingModule } from "./app-routing.module";
import localeDe from "@angular/common/locales/de";
import { AppComponent } from "./app.component";
import { ToolbarComponent } from "./navigation/toolbar/toolbar.component";
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { EnvServiceProvider } from "gematik-form-library";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MaterialModule } from "./material/material.module";
import { OAuthModule } from "angular-oauth2-oidc";
import { PagesModule } from "./pages/pages.module";
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { environment } from "../environments/environment";
import { EffectsModule } from "@ngrx/effects";
import { reducers, metaReducers } from "./store";
import { EntityDataModule } from "@ngrx/data";
import { RouterState, StoreRouterConnectingModule } from "@ngrx/router-store";
import { MultiTranslateHttpLoader } from "ngx-translate-multi-http-loader";

// shared libraries
import { GematikSharedModule } from "gematik-shared";

registerLocaleData(localeDe);

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new MultiTranslateHttpLoader(httpClient, [
    { prefix: "./assets/i18n/", suffix: ".json?build_id=build_id_replacement_placeholder" },
    {
      prefix: "./gematik-shared/assets/i18n/",
      suffix: ".json?build_id=build_id_replacement_placeholder",
    },
  ]);
}

export function appInitializerFactory(translate: TranslateService, injector: Injector) {
  return () =>
    new Promise<any>((resolve: any) => {
      const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
      locationInitialized.then(() => {
        const currentLang = localStorage.getItem("gem-locale");
        let langToSet = "de";
        if (currentLang) {
          langToSet = currentLang;
        }
        translate.setDefaultLang(langToSet);
        translate.use(langToSet).subscribe(
          () => {
            console.info(`Successfully initialized '${langToSet}' language.'`);
          },
          (err) => {
            console.error(`Problem with '${langToSet}' language initialization.'`);
          },
          () => {
            resolve(null);
          },
        );
      });
    });
}

@NgModule({
  declarations: [AppComponent, ToolbarComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: ["http"],
        sendAccessToken: true,
      },
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    MaterialModule,
    PagesModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictActionSerializability: true,
        strictStateSerializability: true,
      },
    }),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    EffectsModule.forRoot([]),
    EntityDataModule.forRoot({}),
    StoreRouterConnectingModule.forRoot({
      stateKey: "router",
      routerState: RouterState.Minimal,
    }),
    HttpClientModule,
    GematikSharedModule.forRoot(),
  ],

  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true,
    },
    { provide: MAT_DATE_LOCALE, useValue: "de-DE" },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: "DD.MM.YYYY",
        },
        display: {
          dateInput: "DD.MM.YYYY",
          monthYearLabel: "MMM YYYY",
          dateA11yLabel: "LL",
          monthYearA11yLabel: "MMMM YYYY",
        },
      },
    },
    EnvServiceProvider,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

import { Subscription } from "rxjs";
import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
import { FormHelperService } from "../../helper/form-helper.service";

@Component({
  selector: "gem-textarea",
  templateUrl: "./gem-textarea.component.html",
  styleUrls: ["./gem-textarea.component.scss"],
})
export class GemTextareaComponent implements OnInit, OnDestroy {
  inputControl: UntypedFormControl;
  requiredErrorMessage: string;
  minLengthErrorMessage: string;
  langSubscription: Subscription;

  @Input() placeholder: string;
  @Input() hint: string = "";
  @Input() minChars: number = 0;
  @Input() maxChars: number = 500;

  isRequired: boolean;

  @Input() set control(controlObj: UntypedFormControl) {
    this.inputControl = controlObj;
    this.isRequired = this.formHelper.checkRequiredValidator(this.inputControl);

    // const placeholderInputValue = this.placeholder;
    // const hintInputValue = this.hint;

    // if (this.placeholder && this.placeholder !== "") {
    //   this.placeholder = this.translateService.instant(this.placeholder);
    // }
    // if (this.hint !== "") {
    //   this.hint = this.translateService.instant(this.hint);
    // }

    // this.langSubscription = this.translateService.onLangChange.subscribe(
    //   (event: LangChangeEvent) => {
    //     if (this.placeholder && this.placeholder !== "") {
    //       this.placeholder = this.translateService.instant(placeholderInputValue);
    //     }
    //     if (this.hint !== "") {
    //       this.hint = this.translateService.instant(hintInputValue);
    //     }
    //   },
    // );

    this.requiredErrorMessage = this.formHelper.createRequiredErrorMessage(this.placeholder);
    this.minLengthErrorMessage = this.formHelper.createMinLengthErrorMessageFromTranslationKey(
      this.placeholder ? this.placeholder : "field",
    );
  }

  get control(): UntypedFormControl {
    return this.inputControl;
  }

  constructor(private translateService: TranslateService, private formHelper: FormHelperService) {}

  ngOnInit() {}

  ngOnDestroy() {
    if (this.langSubscription) {
      this.langSubscription.unsubscribe();
    }
  }
}

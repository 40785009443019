import { NgModule } from '@angular/core';
import { GematikTaskApiComponent } from './gematik-task-api.component';



@NgModule({
  declarations: [GematikTaskApiComponent],
  imports: [
  ],
  exports: [GematikTaskApiComponent]
})
export class GematikTaskApiModule { }

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";

import * as fromStore from "../../../../store";
import * as fromSharedStore from "gematik-shared";

import { Store } from "@ngrx/store";
import { Observable, Subscription, map, of, take } from "rxjs";

@Component({
  selector: "side-navigation",
  styleUrls: ["side-navigation.component.scss"],

  template: `
    <mat-nav-list>
      <mat-list-item *ngIf="!isHandset" data-testid="toggler" (click)="onToggle()" class="text-end">
        <mat-icon mat-list-icon class="chevron-button" [ngClass]="{ rotated: isExpanded }"
          >chevron_right
        </mat-icon>
      </mat-list-item>
      <div class="d-flex align-items-startxx flex-column mt-3 ms-3 me-3">
        <div class="d-flex justify-content-between">
          <div routerLink="/" style="cursor: pointer">
            <div *ngIf="isExpanded">
              <img src="/gematik-shared/assets/icons/logo.svg" alt="" />
              <span class="gem-color-dark-grey ms-1">myServices</span>
            </div>
            <div *ngIf="!isExpanded">
              <img src="/assets/icons/portal/logo-small.svg" alt="" />
            </div>
          </div>
          <div *ngIf="isHandset">
            <mat-icon
              class="d-block nav-icons"
              style="cursor: pointer"
              svgIcon="gem-close"
              (click)="onCloseHandsetMenu()"
            ></mat-icon>
          </div>
        </div>
        <div class="w-100">
          <profile-display [isExpanded]="isExpanded"></profile-display>
        </div>
        <div class="d-flex justify-content-center mt-2 w-100">
          <hr class="w-100" />
        </div>
        <div>
          <side-navigation-item
            *ngFor="let nav of navs"
            [treeContent]="nav"
            [isExpanded]="isExpanded"
          ></side-navigation-item>
        </div>
        <div class="w-100">
          <footer
            [isExpanded]="isExpanded"
            (closeHandsetMenu)="onCloseHandsetMenu()"
            (startGuidedTour)="onStartGuidedTour($event)"
          ></footer>
        </div>
      </div>
    </mat-nav-list>
  `,
})
export class SideNavigationComponent implements OnInit, OnDestroy {
  @Input() isExpanded: boolean;
  @Input() isHandset: boolean;

  @Output() toggle: EventEmitter<any> = new EventEmitter();
  @Output() closeHandsetMenu: EventEmitter<any> = new EventEmitter();
  @Output() startGuidedTour: EventEmitter<number> = new EventEmitter();

  myOpenActivities$: Observable<number> = this.store
    .select(fromStore.getMyTaskTotals)
    .pipe(map((totals) => totals.open));

  groupOpenActivities$: Observable<number> = this.store
    .select(fromStore.getGroupTaskTotals)
    .pipe(map((totals) => totals.open));

  myOpenProcesses$: Observable<number> = this.store
    .select(fromStore.getMyProcessTotals)
    .pipe(map((totals) => totals.open));

  observableOpenProcesses$: Observable<number> = this.store
    .select(fromStore.getObservableProcessTotals)
    .pipe(map((totals) => totals.open));

  navs: {
    rootLabel: string;
    icon: string;
    route?: string;
    links?: { label: string; route: string; badge?: number | Observable<number> }[];
    treeElementOpened?: boolean;
  }[] = [
    {
      rootLabel: "uwl-oveview",
      icon: "gem-dashboard",
      route: "/dashboard",
    },
    {
      rootLabel: "uwl-tasks",
      icon: "gem-report",
      route: "/tasks",
      links: [
        { label: "uwl-tasks-my", route: "tasks/my", badge: this.myOpenActivities$ },
        { label: "uwl-tasks-group", route: "tasks/group", badge: this.groupOpenActivities$ },
      ],
      treeElementOpened: true,
    },
    {
      rootLabel: "uwl-processes",
      icon: "gem-mediation",
      route: "/processes",
      links: [
        { label: "uwl-processes-my", route: "processes/my", badge: this.myOpenProcesses$ },
        {
          label: "uwl-processes-subscriber",
          route: "processes/subscriber",
          badge: this.observableOpenProcesses$,
        },
      ],
      treeElementOpened: true,
    },
  ];

  subscriptions: Subscription[] = [];

  constructor(private store: Store<fromStore.UwlState>) {}

  ngOnInit(): void {
    const sub = this.store.select(fromSharedStore.isUserAdmin).subscribe((isAdmin) => {
      if (isAdmin) {
        this.navs.push({
          rootLabel: "uwl-administration",
          icon: "settings",
          links: [
            { label: "uwl-config", route: "/endpoints-config" },
            { label: "incidents", route: "/incidents" },
            { label: "templates", route: "/templates" },
            { label: "alerts", route: "/alerts" },
            { label: "uwl-help", route: "/help-config" },
            { label: "redis-cache.title", route: "/redis-cache" },
            { label: "uwl-feedback", route: "/feedbacks" },
          ],
          treeElementOpened: true,
        });
      }
    });
    this.subscriptions.push(sub);
  }

  onToggle(): void {
    this.toggle.emit();
  }

  onCloseHandsetMenu(): void {
    this.closeHandsetMenu.emit();
  }

  onStartGuidedTour(id: number): void {
    this.startGuidedTour.emit(id);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}

import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AuthConfig, OAuthEvent, OAuthService } from "angular-oauth2-oidc";
import { EnvService } from "gematik-form-library";
import { TokenHelperService } from "gematik-form-library";
import { WebSocketAPI } from "./websocket/websocket-api";
import { UwlEndpointEntityService } from "./pages/services/uwl-endpoint-entity.service";
import { Subscription } from "rxjs";
import { TaskCountEntityService } from "./pages/services/task-count-entity.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  // Subscriptions
  uwlEndpointServiceSub: Subscription;

  constructor(
    translate: TranslateService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private oauthService: OAuthService,
    private env: EnvService,
    private router: Router,
    private tokenHelperService: TokenHelperService,
    private uwlEndpointService: UwlEndpointEntityService,
    private taskCountService: TaskCountEntityService,
  ) {
    if (this.tokenHelperService.isExpired()) {
      this.tokenHelperService.logout();
    }

    const lang = localStorage.getItem("gem-locale");
    if (lang) {
      if (lang === "en") {
        translate.use("en");
      } else {
        translate.use("de");
      }
    } else {
      translate.use("de");
    }

    this.matIconRegistry.addSvgIcon(
      "gem-users",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/gem-users.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "settings",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/settings.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "processes",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/processes.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "signpost",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/signpost.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "gem-approval-blue",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/icons/Zustimmung_Blau_gematik.svg",
      ),
    );
    this.matIconRegistry.addSvgIcon(
      "gem-feedback",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/Kontakt_Blau_gematik.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "gem-coming-soon",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/icons/myServices_Blau_gematik.svg",
      ),
    );

    const authConfig: AuthConfig = {
      issuer: "https://login.microsoftonline.com/" + this.env.oauthTennantId + "/v2.0",
      redirectUri: window.location.origin + "/oauth",
      clientId: this.env.oauthClientId,
      responseType: "code",
      clearHashAfterLogin: false,
      strictDiscoveryDocumentValidation: false,
      scope: "openid " + this.env.oauthApplicationIdUri + "/user_impersonation",
    };
    this.oauthService.configure(authConfig);
    this.oauthService.loadDiscoveryDocumentAndTryLogin();
    this.oauthService.setupAutomaticSilentRefresh();
    this.oauthService.events.subscribe(({ type }: OAuthEvent) => {
      switch (type) {
        case "token_received":
          this.initWebsockets();
          tokenHelperService.saveToken(this.oauthService.getAccessToken());
          this.router.navigate([decodeURIComponent(this.oauthService.state)]);
          break;
        case "code_error":
          tokenHelperService.saveToken("");
          this.router.navigate(["/noaccess"]);
          break;
        case "logout":
          tokenHelperService.saveToken("");
          this.router.navigate(["/dashboard"]);
          break;
      }
    });
  }

  ngOnInit(): void {
    if (this.oauthService.hasValidAccessToken()) {
      this.initWebsockets();
    }
  }

  private initWebsockets() {
    this.uwlEndpointServiceSub = this.uwlEndpointService.entities$.subscribe((endpoints) => {
      endpoints.forEach((endpoint) => {
        new WebSocketAPI(this, this.env)._connect(endpoint.url);
        this.taskCountService.getByKey(endpoint.url);
      });
    });
  }

  async handleWebsocketEvent(message, endpoint) {
    await new Promise((resolve) => setTimeout(resolve, 100));
    this.taskCountService.getByKey(endpoint);
  }

  ngOnDestroy(): void {
    if (this.uwlEndpointServiceSub) {
      this.uwlEndpointServiceSub.unsubscribe();
    }
  }
}

import { AbstractControl, FormArray, FormGroup, ValidatorFn } from "@angular/forms";

const emailRegEx: string =
  "^([A-Za-z0-9]+([._-]{0,1}[A-Za-z0-9]+)*)[@]{1}([A-Za-z0-9]+([-]{0,1}[A-Za-z0-9]+)*)[.]{1}([A-Za-z0-9]+([-]{0,1}[A-Za-z0-9]+)*)([.]{1}[a-z]{2,}){0,1}$";
const telephoneRegEx: string = "^[+]{1}[1-9]{1}[0-9]{7,}$";

export class GemValidators {
  static termsCheckboxRequiredTrue(control: AbstractControl): any | null {
    return GemValidators.termsCheckboxRequired(true)(control);
  }

  static email(control: AbstractControl): any | null {
    return GemValidators.pattern(emailRegEx)(control);
  }

  static telephone(control: AbstractControl): any | null {
    return GemValidators.pattern(telephoneRegEx)(control);
  }

  static termsCheckboxRequired(value: boolean): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null =>
      control.value === value ? null : { termsCheckboxRequired: control.value };
  }

  // static emailPattern(value: string): ValidatorFn {
  //   return (control: AbstractControl): { [key: string]: any } | null => {
  //     if (control.value) {
  //       return String(control.value).match(value) ? null : { pattern: true };
  //     }
  //     return null;
  //   };
  // }

  static pattern(value: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value) {
        return String(control.value).match(value) ? null : { pattern: true };
      }
      return null;
    };
  }

  static requireAtLeastOneCheckboxeToBeCheckedValidator(
    fields: string[],
    minRequired = 1,
  ): ValidatorFn {
    return function validate(formGroup: FormGroup) {
      let checked = 0;
      for (const field of fields) {
        const f = formGroup.get(field);
        if (f.value === true) {
          checked++;
        }
      }
      if (checked < minRequired) {
        return {
          requireAtLeastOneCheckboxeToBeChecked: {
            fields,
          },
        };
      }
      return null;
    };
  }
}

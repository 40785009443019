import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { FormHelperService } from "../../helper/form-helper.service";

@Component({
  selector: "gem-file-upload",
  templateUrl: "./gem-file-upload.component.html",
  styleUrls: ["./gem-file-upload.component.css"],
})
export class GemFileUploadComponent implements OnInit, OnDestroy {
  inputControl: UntypedFormControl;
  requiredErrorMessage: string;
  langSubscription: Subscription;
  fileName = "";

  @Input() placeholder: string;
  @Input() hint = "";
  @Input() fileTypes: string;
  @Input() downloadLink: string;
  @Input() multiple: boolean = false;
  @Input() showFileName: boolean = true;

  @Output() change = new EventEmitter();

  isRequired: boolean;

  @Input() set control(controlObj: UntypedFormControl) {
    this.inputControl = controlObj;
    this.isRequired = this.formHelper.checkRequiredValidator(this.inputControl);

    const placeholderInputValue = this.placeholder;
    const hintInputValue = this.hint;

    if (this.placeholder && this.placeholder !== "") {
      this.placeholder = this.translateService.instant(this.placeholder);
    }
    if (this.hint !== "") {
      this.hint = this.translateService.instant(this.hint);
    }

    this.langSubscription = this.translateService.onLangChange.subscribe(
      (event: LangChangeEvent) => {
        if (this.placeholder && this.placeholder !== "") {
          this.placeholder = this.translateService.instant(placeholderInputValue);
        }
        if (this.hint !== "") {
          this.hint = this.translateService.instant(hintInputValue);
        }
      },
    );

    this.requiredErrorMessage = this.formHelper.createRequiredErrorMessage(this.placeholder);
  }

  get control(): UntypedFormControl {
    return this.inputControl;
  }

  constructor(private translateService: TranslateService, private formHelper: FormHelperService) {}

  ngOnInit() {}

  trim() {
    this.formHelper.trimWhitespace(this.control);
  }

  emitChange(event: any) {
    if (event && event.target && event.target.files) {
      const file: File = event.target.files[0];
      if (file) {
        this.fileName = file.name;
      }
    }
  }

  onDownloadClick(): void {
    window.open(this.downloadLink, "_blank");
  }

  ngOnDestroy() {
    if (this.langSubscription) {
      this.langSubscription.unsubscribe();
    }
  }
}

import { Component, Inject } from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";

export interface DialogData {
  title: string;
  text: string;
  confirmAction: any;
  cancelAction: any;
}

@Component({
  selector: "gem-confirmation-dialog",
  templateUrl: "./gem-confirmation-dialog.component.html",
  styleUrls: ["./gem-confirmation-dialog.component.scss"],
})
export class GemConfirmationDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<GemConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {}

  cancelAction(): void {
    this.data.cancelAction();
    this.dialogRef.close(false);
  }

  confirmAction(): void {
    this.data.confirmAction();
    this.dialogRef.close(true);
  }
}

<h1 mat-dialog-title>{{ data.title | translate }}</h1>
<div mat-dialog-content>
  <p>
    {{
      (data.errors && data.errors.length) > 1
        ? ("MULTIPLE_ERRORS_OCCURED" | translate)
        : ("ONE_ERROR_OCCURED" | translate)
    }}
  </p>
  <ul>
    <li *ngFor="let error of data.errors">{{ error }}</li>
  </ul>
</div>
<div mat-dialog-actions>
  <button class="btn btn-secondary resetFilter" (click)="cancelAction()">
    {{ "CANCEL" | translate }}
  </button>
  <button class="btn btn-primary" (click)="confirmAction()" cdkFocusInitial>
    {{ "saveNevertheles" | translate }}
  </button>
</div>

<div class="snackbar-content">
  <div class="snackbar-header">
    <i class="material-icons">warning</i>
    <i class="material-icons">warning</i>
    <span>{{ 'unexpectedErrorOccured' | translate}}</span>
  </div>
  <div class="details" (click)="toggleDetails()">
    <div class="details-header">
      <i [@rotatedState]='state' class="material-icons">chevron_right</i>
      <span>{{ 'details' | translate}}</span>
    </div>
    <div [@openClose]="detailsExpanded ? true : false" class="details-content">
      <table>
        <tbody>
          <tr>
            <td>
              {{ 'error' | translate}}
            </td>
            <td>
              {{data.errorCode | translate}}
            </td>
          </tr>
          <tr>
            <td>
              {{ 'timestamp' | translate}}
            </td>
            <td>
              {{data.date | date: 'dd.MM.yyyy HH:mm'}}
            </td>
          </tr>
          <tr *ngIf="data.id">
            <td>
              {{ 'errorId' | translate}}
            </td>
            <td>
              {{data.id}}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="snackbar-actions">
    <span class="close-error" (click)="close()">{{ 'close' | translate}}</span>
  </div>
</div>

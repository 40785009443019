import { Injectable } from "@angular/core";
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from "@ngrx/data";
import { TaskCount } from "../../models/task-count.model";

@Injectable()
export class TaskCountEntityService extends EntityCollectionServiceBase<TaskCount> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super("TaskCount", serviceElementsFactory);
  }
}

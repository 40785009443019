import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[MultiDigitDecimalNumber]',
})
export class MultiDigitDecimalNumberDirective {
  @Input() digits: number;
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', '-', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];
  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    let regex: RegExp = new RegExp('^[N][V|B]?$|^\\d*\\,?\\d{0,' + this.digits + '}$', 'g');
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    let current: string = this.el.nativeElement.value;
    const position = this.el.nativeElement.selectionStart;
    const next: string = [current.slice(0, position), event.key == 'Decimal' ? ',' : event.key, current.slice(position)].join('');
    if (next && !String(next).match(regex)) {
      event.preventDefault();
    }
  }
}

import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Clipboard } from "@angular/cdk/clipboard";

declare type DialogType = "success" | "error";

interface DialogData {
  title: string;
  text: string;
  link: string;
  type: DialogType;
  style: string;
  copy?: {
    value: string;
    successMessage: string;
    snackBarDuration: number;
  };
}

@Component({
  selector: "gem-dialog",
  templateUrl: "./gem-dialog.component.html",
  styleUrls: ["./gem-dialog.component.scss"],
})
export class GemDialogComponent {
  title: string;
  text: string;
  link: string;

  constructor(
    public dialogRef: MatDialogRef<GemDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private clipboard: Clipboard,
    private _snackBar: MatSnackBar,
  ) {}

  closeDialog(): void {
    if (this.data.copy) {
      this.clipboard.copy(this.data.copy?.value);

      this._snackBar.open(this.data.copy?.successMessage, "", {
        duration: this.data.copy?.snackBarDuration * 1000,
        horizontalPosition: "center",
        verticalPosition: "top",
      });
    }

    this.dialogRef.close();
  }

}

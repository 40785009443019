import * as Stomp from "stompjs";
import * as SockJS from "sockjs-client";
import { AppComponent } from "../app.component";
import { EnvService } from "gematik-form-library";

export class MessageCounter {
  documentVerifications: any;
  tasks: any;
  events: any;
  trainers: any;
  customers: any;

  constructor() {
    this.documentVerifications = 0;
    this.tasks = 0;
    this.events = 0;
    this.trainers = 0;
    this.customers = 0;
  }
}

export class WebSocketAPI {
  webSocketEndPoint: string;
  stompClient: any;
  stompClient2: any;
  appComponent: AppComponent;
  constructor(appComponent: AppComponent, private envService: EnvService) {
    this.appComponent = appComponent;
  }

  _connect(endpoint: string) {
    const token: string = localStorage.getItem("token");
    this.webSocketEndPoint = endpoint + "/gematik-websocket?access_token=" + token;
    const ws = new SockJS(this.webSocketEndPoint);
    this.stompClient = Stomp.over(ws);
    const _this = this;
    _this.stompClient.connect(
      {},
      (frame) => {
        _this.stompClient.subscribe("/gematik-websocket/camunda", (sdkEvent) => {
          _this.handleWebsocketEvent(sdkEvent, endpoint);
        });
      },
      this.errorCallBack
    );
  }

  _disconnect() {
    if (this.stompClient !== null) {
      this.stompClient.disconnect();
    }
  }

  errorCallBack(error) {
    console.log("errorCallBack -> " + error);
  }

  handleWebsocketEvent(message, endpoint) {
    this.appComponent.handleWebsocketEvent(message.body, endpoint);
  }

}

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { HighlightSearchPipe } from "../services/highlight-table-search-pipe";

@NgModule({
  imports: [CommonModule],
  declarations: [HighlightSearchPipe],
  providers: [],
  exports: [HighlightSearchPipe],
})
export class SharedModule {}

import { Component, OnInit } from "@angular/core";

@Component({
  selector: "gem-logo",
  templateUrl: "./gem-logo.component.html",
  styleUrls: ["./gem-logo.component.scss"],
})
export class GemLogoComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}

import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { AuthConfig, OAuthEvent, OAuthService } from "angular-oauth2-oidc";
import { EnvService } from "gematik-form-library";
import { TokenHelperService } from "gematik-form-library";
import { Subscription } from "rxjs";

@Component({
  selector: "gem-toolbar",
  templateUrl: "./toolbar.component.html",
  styleUrls: ["./toolbar.component.scss"],
})
export class ToolbarComponent implements OnInit, OnDestroy {
  lang: string;
  langSubscription: Subscription;

  constructor(
    private oauthService: OAuthService,
    private translate: TranslateService,
    private tokenHelperService: TokenHelperService,
    private env: EnvService,
  ) {}

  ngOnInit() {
    this.translate
      .get(`languages.${this.translate.currentLang === "en" ? "de" : "en"}`)
      .subscribe((res: string) => {
        this.lang = res;
      })
      .unsubscribe();

    this.langSubscription = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.translate
        .get(`languages.${event.lang === "en" ? "de" : "en"}`)
        .subscribe((res: string) => {
          this.lang = res;
        })
        .unsubscribe();
    });
  }

  onLangClick() {
    if (this.translate.currentLang === "de") {
      this.translate.use("en");
      localStorage.setItem("gem-locale", "en");
    } else {
      this.translate.use("de");
      localStorage.setItem("gem-locale", "de");
    }
  }

  onSignInClick(): void {
    this.oauthService.initLoginFlow();
  }

  onSignOutClick(): void {
    const url = window.location.origin;
    this.oauthService.logOut();
    window.location.href = `https://login.windows.net/${this.env.oauthTennantId}/oauth2/logout?post_logout_redirect_uri=${url}`;
    window.sessionStorage.clear();
  }

  public get username() {
    try {
      return this.tokenHelperService.getFullname();
    } catch (error) {
      return null;
    }
  }

  ngOnDestroy() {
    if (this.langSubscription) {
      this.langSubscription.unsubscribe();
    }
  }
}

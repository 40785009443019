<mat-form-field class="gem-input-field">
  <mat-label>{{ placeholder }}</mat-label>
  <input
    [attr.data-testid]="testid"
    [ngClass]="{ strikethrough: delete }"
    (focusout)="trim()"
    trim
    matInput
    [formControl]="inputControl"
    type="{{ type }}"
    [attr.maxlength]="maxlength"
  />
  <div>{{ suffix }}</div>
  <mat-hint>{{ hint }}</mat-hint>
  <mat-error *ngIf="inputControl.hasError('required')">{{ requiredErrorMessage }}</mat-error>
  <mat-error *ngIf="inputControl.hasError('email')">{{ "email_invalid" | translate }}</mat-error>
  <mat-error *ngIf="inputControl.hasError('noMobileNumber')">{{
    "no_mobile_number" | translate
  }}</mat-error>
  <mat-error *ngIf="inputControl.hasError('pwNotSame')">{{ "pwsNotSame" | translate }}</mat-error>
  <mat-error *ngIf="inputControl.hasError('noValidNumber')">{{
    noValidNumberErrorMessage
  }}</mat-error>
  <!-- <mat-error *ngIf="inputControl.hasError('pattern')">{{ "pattern_invalid" | translate }}</mat-error> -->
  <mat-error *ngIf="inputControl.hasError('pattern')">{{ patternErrorMessage }}</mat-error>
  <mat-error *ngIf="inputControl.hasError('unique')">{{ uniqueErrorMessage }}</mat-error>
</mat-form-field>

<label *ngIf="label">{{ (label | translate) + (isRequired ? " *" : "") }}</label>
<mat-radio-group
  aria-label="Select an option"
  [formControl]="inputControl"
  [disabled]="inputControl.disabled"
  (change)="onChange($event)"
>
  <mat-radio-button value="{{ valueLabel.value }}" *ngFor="let valueLabel of valueLabels"
    ><label>{{ valueLabel.label | translate }}</label></mat-radio-button
  >
</mat-radio-group>

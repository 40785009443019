<div>
  <h1 mat-dialog-title class="mat-dialog-title">{{ title }}</h1>
  <div class="mb-3">
    <div class="d-flex align-items-center justify-content-center flex-row">
      <div class="timeline-point p-1"></div>
      <div class="p-1 pe-3">{{ legendOpen }}</div>
      <div class="timeline-point-current p-1"></div>
      <div class="p-1 pe-3">{{ legendCurrent }}</div>
      <div class="timeline-point-finished p-1"></div>
      <div class="p-1">{{ legendFinished }}</div>
    </div>
  </div>
</div>
<div class="timeline">
  <div *ngFor="let item of value; last as isLast" class="timeline-event">
    <div class="timeline-point-track-container">
      <div class="{{ 'timeline-point-' + item?.status }}"></div>
      <div
        *ngIf="!isLast"
        class="timeline-track"
        [ngClass]="item?.status === 'CLOSED' ? 'heighlight' : ''"
      ></div>
    </div>
    <div class="timeline-content">
      <div class="d-flex" [style.font-weight]="item.status === 'CURRENT' ? 'bold' : ''">
        <span [innerHTML]="item.label"></span>
        <span class="timeline-date">{{ item.date | date : "dd.MM.yyyy (HH:mm:ss)" }}</span>
        <span
          *ngIf="item.description"
          class="material-icons ms-1"
          style="cursor: pointer"
          [ngbTooltip]="item.description"
        >
          info
        </span>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'gem-expansion-panel',
  templateUrl: './gem-expansion-panel.component.html',
  styleUrls: ['./gem-expansion-panel.component.scss'],
})
export class GemExpansionPanelComponent implements OnInit {
  @Input() title: string;
  @Input() hasError: boolean;
  @Input() expanded: boolean;

  constructor() {}

  ngOnInit() {}
}

<h1 mat-dialog-title>{{ data.title | translate }}</h1>
<div mat-dialog-content *ngIf="data.errors && data.errors.length > 0" class="mb-3">
  <!-- <p>{{ (data.errors && data.errors.length) > 1 ? ("MULTIPLE_ERRORS_OCCURED" | translate) : ("ONE_ERROR_OCCURED" | translate) }}</p> -->
  <ul *ngIf="data.errors.length > 1">
    <!-- <li *ngFor="let error of data.errors">{{ error }}</li> -->
    <li *ngFor="let error of data.errors" [innerHtml]="error"></li>
  </ul>
  <span *ngIf="data.errors.length === 1" [innerHtml]="data.errors"></span>
</div>
<div mat-dialog-actions class="mb-2">
  <button class="btn btn-primary" (click)="closeDialog()">Ok</button>
</div>

import { FormHelperService } from '../../helper/form-helper.service';
import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'gem-radio-button-group-orange',
  templateUrl: './gem-radio-button-group-orange.component.html',
  styleUrls: ['./gem-radio-button-group-orange.component.scss']
})
export class GemRadioButtonGroupOrangeComponent implements OnInit {

  inputControl: UntypedFormControl;

  requiredErrorMessage: string;
  isRequired: boolean;

  @Input() label;
  @Input() valueLabels;

  @Input() set control(controlObj: UntypedFormControl) {
    this.inputControl = controlObj;

    this.isRequired = this.formHelper.checkRequiredValidator(this.inputControl);
  }

  get control(): UntypedFormControl {
    return this.inputControl;
  }

  constructor(private translateService: TranslateService, private formHelper: FormHelperService) {}

  ngOnInit() {}

}
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { NotFoundPageComponent } from "./pages/not-found-page/not-found-page.component";
import { OauthRedirectComponent } from "./pages/oauth-redirect/oauth-redirect.component";
import { DashboardResolver } from "./pages/resolvers/dashboard.resolver";
import { LegalNoticeComponent } from "./pages/legal-notice/legal-notice.component";

const routes: Routes = [
  { path: "", redirectTo: "/dashboard", pathMatch: "full" },
  {
    path: "dashboard",
    component: DashboardComponent,
    resolve: {
      dashboard: DashboardResolver,
    },
  },
  { path: "legal-notice", component: LegalNoticeComponent },
  { path: "oauth", component: OauthRedirectComponent },
  { path: "**", component: NotFoundPageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [DashboardResolver],
})
export class AppRoutingModule {}

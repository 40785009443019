import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { trigger, state, transition, style, animate } from "@angular/animations";

import { FormHelperService, ValueLabel } from "gematik-form-library";
import { GematikTaskApiService, GematikTaskDto } from "gematik-task-api";
import { TokenHelperService } from "gematik-form-library";
import { GematikCompleteTaskDto, Lock } from "gematik-task-api";

import { Observable, Subscription, of } from "rxjs";
import { GemLockingDialogComponent } from "../completed-task-table/gem-locking-dialog/gem-locking-dialog.component";
import { GemDefaultTaskDialogComponent } from "../gem-default-task-dialog/gem-default-task-dialog.component";
import { GemExtendedTaskDialogComponent } from "../gem-extended-task-dialog/gem-extended-task-dialog.component";
import { GemSimpleTaskDialogComponent } from "../gem-simple-task-dialog/gem-simple-task-dialog.component";
import { DomSanitizer } from "@angular/platform-browser";
import { MatMenuTrigger } from "@angular/material/menu";
import { NavigationEnd, NavigationStart, Router, Scroll } from "@angular/router";

import { Store } from "@ngrx/store";
import * as fromStore from "../../../store";
import { map } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { TimelineService } from "gematik-shared";
import { BreakpointObserver } from "@angular/cdk/layout";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { DatePipe } from "@angular/common";

@Component({
  selector: "task-table",
  templateUrl: "./task-table.component.html",
  styleUrls: ["./task-table.component.scss"],
  animations: [
    trigger("detailExpand", [
      state("collapsed, void", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition("expanded <=> collapsed", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")),
      transition("expanded <=> void", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")),
    ]),
  ],
})
export class TaskTableComponent implements OnInit, OnChanges, OnDestroy {
  sort: any;
  tsks: GematikTaskDto[];
  filterText: string = "";

  columnDefinitions: string[] = [
    "expand",
    "buid",
    "partner",
    "partnerType",
    "taskRole",
    "processName",
    "taskName",
    "createdTimeStamp",
    "dueTimeDate",
    "actions",
  ];
  tabletColumnDefinitions: string[] = ["expand", "buid", "createdTimeStamp", "actions"];
  handsetColumnDefinitions: string[] = ["handset"];

  displayedColumns: string[] = [...this.columnDefinitions];

  tabletBreakpoint: string = "768px";
  tabletBreakpointMatched: boolean = false;
  handsetBreakpoint: string = "540px";
  handsetBreakpointMatched: boolean = false;

  lockingSubscription: Subscription;

  dataSource: MatTableDataSource<GematikTaskDto>;
  private _type: string;

  @Output() taskSelected = new EventEmitter();
  @Output() taskCompleted = new EventEmitter();
  @Output() clearFilter = new EventEmitter();

  @Input() set tasks(tasks: GematikTaskDto[]) {
    this.tsks = tasks;
    this.dataSource = new MatTableDataSource(tasks);
    this.dataSource.filterPredicate = (row: any, filter: any) => {
      return this.customFilterPredicate(row, filter);
    };
    this.dataSource.paginator = this.paginator;
    if (this.sort) {
      this.dataSource.sort = this.sort;
    }
    this.cdr.detectChanges();
  }

  @Input() type: string;
  @Input() searchStr: string;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) set content(content: ElementRef) {
    this.sort = content;
  }

  menuTopLeftPosition = { x: "0", y: "0" };
  @ViewChild(MatMenuTrigger, { static: true }) matMenuTrigger: MatMenuTrigger;

  buidFilterOptions$: Observable<ValueLabel[]> = of([]);
  partnerFilterOptions$: Observable<ValueLabel[]> = of([]);
  partnerTypeFilterOptions$: Observable<ValueLabel[]> = of([]);
  partnerRoleFilterOptions$: Observable<ValueLabel[]> = of([]);
  processNameFilterOptions$: Observable<ValueLabel[]> = of([]);
  taskNameFilterOptions$: Observable<ValueLabel[]> = of([]);

  selectedFilters: { [id: string]: ValueLabel[] } | any = {
    buid: [],
    partner: [],
    partnerType: [],
    role: [],
    processName: [],
    name: [],
    searchStr: [],
  };

  reset: { search: boolean };

  subscriptions: Subscription[] = [];

  constructor(
    private cdr: ChangeDetectorRef,
    private formHelper: FormHelperService,
    private dialog: MatDialog,
    private taskService: GematikTaskApiService,
    private tokenHelperService: TokenHelperService,
    public domSanitizer: DomSanitizer,
    private router: Router,
    private store: Store<fromStore.UwlState>,
    private timelineService: TimelineService,
    private translateService: TranslateService,
    private breakpointObserver: BreakpointObserver,
    private destroyRef: DestroyRef,
    private datePipe: DatePipe,
  ) {}

  ngOnInit() {
    const sub = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.reset = {
          ...this.reset,
          search: false,
        };
      } else if (event instanceof Scroll) {
        if (event.routerEvent instanceof NavigationEnd) {
          this.getFilters(
            event.routerEvent.url === "/"
              ? event.routerEvent.urlAfterRedirects
              : event.routerEvent.url,
          );
        }
      } else if (event instanceof NavigationEnd) {
        this.getFilters(event.url);
      }
    });
    this.subscriptions.push(sub);

    const breakpointSub = this.breakpointObserver
      .observe([`(max-width: ${this.tabletBreakpoint})`, `(max-width: ${this.handsetBreakpoint})`])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((state) => {
        this.tabletBreakpointMatched = state.breakpoints[`(max-width: ${this.tabletBreakpoint})`];
        this.handsetBreakpointMatched = state.breakpoints[`(max-width: ${this.handsetBreakpoint})`];
        if (!this.tabletBreakpointMatched && !this.handsetBreakpointMatched) {
          this.displayedColumns = [...this.columnDefinitions];
        } else if (this.tabletBreakpointMatched && !this.handsetBreakpointMatched) {
          this.displayedColumns = [...this.tabletColumnDefinitions];
        } else if (this.tabletBreakpointMatched && this.handsetBreakpointMatched) {
          this.displayedColumns = [...this.handsetColumnDefinitions];
        } else {
          this.displayedColumns = [...this.columnDefinitions];
        }
      });
    this.subscriptions.push(breakpointSub);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.filter();
    if (changes.searchStr) {
      this.applyFilter(this.searchStr);
    }
  }

  private getFilters(url: string): void {
    this.selectedFilters = {
      buid: [],
      partner: [],
      partnerType: [],
      role: [],
      processName: [],
      name: [],
      searchStr: [],
    };
    this.reset = {
      ...this.reset,
      search: true,
    };
    if (url === "/tasks/my") {
      this.buidFilterOptions$ = this.store.select(fromStore.getOpenMyTasks).pipe(
        map((tasks) => {
          return tasks.map((task) => {
            return { value: task.buid, label: task.buid };
          });
        }),
      );
      this.partnerFilterOptions$ = this.store.select(fromStore.getOpenMyTasks).pipe(
        map((tasks) => {
          return tasks.map((task) => task.partner);
        }),
        map((values) => Array.from(new Set(values))),
        map((values) =>
          values.map((value) => {
            return { value, label: value };
          }),
        ),
      );
      this.partnerTypeFilterOptions$ = this.store.select(fromStore.getOpenMyTasks).pipe(
        map((tasks) => {
          return tasks.map((task) => task.partnerType);
        }),
        map((values) => Array.from(new Set(values))),
        map((values) =>
          values.map((value) => {
            return { value, label: value };
          }),
        ),
      );
      this.partnerRoleFilterOptions$ = this.store.select(fromStore.getOpenMyTasks).pipe(
        map((tasks) => {
          return tasks.map((task) => task.role);
        }),
        map((values) => Array.from(new Set(values))),
        map((values) =>
          values.map((value) => {
            return { value, label: value };
          }),
        ),
      );
      this.processNameFilterOptions$ = this.store.select(fromStore.getOpenMyTasks).pipe(
        map((tasks) => {
          return tasks.map((task) => task.processName);
        }),
        map((values) => Array.from(new Set(values))),
        map((values) =>
          values.map((value) => {
            return { value, label: value };
          }),
        ),
      );
      this.taskNameFilterOptions$ = this.store.select(fromStore.getOpenMyTasks).pipe(
        map((tasks) => {
          return tasks.map((task) => task.name);
        }),
        map((values) => Array.from(new Set(values))),
        map((values) =>
          values.map((value) => {
            return { value, label: value };
          }),
        ),
      );
    } else if (url === "/tasks/group") {
      this.buidFilterOptions$ = this.store.select(fromStore.getOpenGroupTasks).pipe(
        map((tasks) => {
          return tasks.map((task) => {
            return { value: task.buid, label: task.buid };
          });
        }),
      );
      this.partnerFilterOptions$ = this.store.select(fromStore.getOpenGroupTasks).pipe(
        map((tasks) => {
          return tasks.map((task) => task.partner);
        }),
        map((values) => Array.from(new Set(values))),
        map((values) =>
          values.map((value) => {
            return { value, label: value };
          }),
        ),
      );
      this.partnerTypeFilterOptions$ = this.store.select(fromStore.getOpenGroupTasks).pipe(
        map((tasks) => {
          return tasks.map((task) => task.partnerType);
        }),
        map((values) => Array.from(new Set(values))),
        map((values) =>
          values.map((value) => {
            return { value, label: value };
          }),
        ),
      );
      this.partnerRoleFilterOptions$ = this.store.select(fromStore.getOpenGroupTasks).pipe(
        map((tasks) => {
          return tasks.map((task) => task.role);
        }),
        map((values) => Array.from(new Set(values))),
        map((values) =>
          values.map((value) => {
            return { value, label: value };
          }),
        ),
      );
      this.processNameFilterOptions$ = this.store.select(fromStore.getOpenGroupTasks).pipe(
        map((tasks) => {
          return tasks.map((task) => task.processName);
        }),
        map((values) => Array.from(new Set(values))),
        map((values) =>
          values.map((value) => {
            return { value, label: value };
          }),
        ),
      );
      this.taskNameFilterOptions$ = this.store.select(fromStore.getOpenGroupTasks).pipe(
        map((tasks) => {
          return tasks.map((task) => task.name);
        }),
        map((values) => Array.from(new Set(values))),
        map((values) =>
          values.map((value) => {
            return { value, label: value };
          }),
        ),
      );
    }
  }

  applyFilter(filterValue: string) {
    this.filterText = filterValue.trim();
    this.selectedFilters = {
      ...this.selectedFilters,
      searchStr: [{ value: this.filterText, label: this.filterText }],
    };
    this.dataSource.filter = this.selectedFilters;
    if (this.dataSource.paginator) {
      const paginator = this.dataSource.paginator as MatPaginator;
      if (paginator.hasPreviousPage()) {
        this.dataSource.paginator.firstPage();
        this.cdr.detectChanges();
      }
    }
  }

  getLockDto(task: GematikTaskDto): Lock {
    const lock: Lock = {
      entityName: task.processName,
      id: task.buid,
      username: this.tokenHelperService.getUsername(),
      timeout: 15 * 60,
      taskId: task.id,
    };
    return lock;
  }

  taskRowClicked(task: GematikTaskDto) {
    const lock: Lock = this.getLockDto(task);

    this.lockingSubscription = this.taskService
      .check(task.backendUrl, lock)
      .subscribe((response) => {
        if (response.body) {
          const body: Lock = response.body;
          const dialogRef = this.dialog.open(GemLockingDialogComponent, {
            data: { lock: body },
          });
          dialogRef.afterClosed().subscribe((result) => {
            if (result) {
              this.lockingSubscription = this.taskService.lock(task.backendUrl, lock).subscribe();
              this.openTaskForm(task, lock);
            }
          });
        } else {
          // lock record
          this.lockingSubscription = this.taskService.lock(task.backendUrl, lock).subscribe();
          this.openTaskForm(task, lock);
        }
      });
  }

  private openTaskForm(task: GematikTaskDto, lock: Lock) {
    // task.assignee = lock.username;
    // this.taskSelected.emit(task);
    this.taskSelected.emit({
      ...task,
      assignee: lock.username,
    });

    if (task.type == "FORM") {
      window.open(task.frontendUrl, "_blank");
    }
    if (task.type == "SIMPLE") {
      const dialogRef = this.openConfirmationDialog(
        task,
        null,
        () => this.completeSimpleTask(task),
        () => this.cancelClicked(task),
      );
    }
    if (task.type == "SIMPLE_INPUT") {
      const dialogRef = this.openConfirmationDialog(
        task,
        new UntypedFormControl(),
        (control: UntypedFormControl) => this.completeSimpleTaskInput(task, control),
        () => this.cancelClicked(task),
      );
    }

    if (task.type == "DEFAULT") {
      const dialogRef = this.openDefaultDialog(
        task,
        null,
        () => this.completeDefaultTask(task, "APPROVE"),
        () => this.completeDefaultTask(task, "REJECT"),
        () => this.cancelClicked(task),
      );
    }

    if (task.type == "DEFAULT_INPUT") {
      const dialogRef = this.openDefaultDialog(
        task,
        new UntypedFormControl(),
        (control: UntypedFormControl) => this.completeDefaultTaskInput(task, control, "APPROVE"),
        (control: UntypedFormControl) => this.completeDefaultTaskInput(task, control, "REJECT"),
        () => this.cancelClicked(task),
      );
    }

    if (task.type == "EXTENDED") {
      const dialogRef = this.openExtendedDialog(
        task,
        null,
        () => this.completeExtendedTask(task, "APPROVE"),
        () => this.completeExtendedTask(task, "REJECT"),
        () => this.completeExtendedTask(task, "EXTENDED"),
        () => this.cancelClicked(task),
      );
    }

    if (task.type == "EXTENDED_INPUT") {
      const dialogRef = this.openExtendedDialog(
        task,
        new UntypedFormControl(),
        (control: UntypedFormControl) => this.completeExtendedTaskInput(task, control, "APPROVE"),
        (control: UntypedFormControl) => this.completeExtendedTaskInput(task, control, "REJECT"),
        (control: UntypedFormControl) => this.completeExtendedTaskInput(task, control, "EXTENDED"),
        () => this.cancelClicked(task),
      );
    }
  }

  completeSimpleTask(task: GematikTaskDto) {
    const lock: Lock = this.getLockDto(task);
    let dto: GematikCompleteTaskDto = new GematikCompleteTaskDto();
    dto.taskId = task.id;
    dto.outcome = "OK";
    this.taskService.completeTask(task.backendUrl, dto).subscribe((res) => {
      this.taskCompleted.emit();
    });
  }

  completeSimpleTaskInput(task: GematikTaskDto, control: UntypedFormControl) {
    const lock: Lock = this.getLockDto(task);
    let dto: GematikCompleteTaskDto = new GematikCompleteTaskDto();
    dto.taskId = task.id;
    dto.outcome = "OK";
    dto.reason = control.value;
    this.taskService.completeTask(task.backendUrl, dto).subscribe((res) => {
      this.taskCompleted.emit();
    });
  }

  completeDefaultTask(task: GematikTaskDto, outcome: string) {
    const lock: Lock = this.getLockDto(task);
    let dto: GematikCompleteTaskDto = new GematikCompleteTaskDto();
    dto.taskId = task.id;
    dto.outcome = outcome;
    this.taskService.completeTask(task.backendUrl, dto).subscribe((res) => {
      this.taskCompleted.emit();
    });
  }

  completeDefaultTaskInput(task: GematikTaskDto, control: UntypedFormControl, outcome: string) {
    const lock: Lock = this.getLockDto(task);
    let dto: GematikCompleteTaskDto = new GematikCompleteTaskDto();
    dto.taskId = task.id;
    dto.outcome = outcome;
    dto.reason = control.value;
    this.taskService.completeTask(task.backendUrl, dto).subscribe((res) => {
      this.taskCompleted.emit();
    });
  }

  completeExtendedTask(task: GematikTaskDto, outcome: string) {
    const lock: Lock = this.getLockDto(task);
    let dto: GematikCompleteTaskDto = new GematikCompleteTaskDto();
    dto.taskId = task.id;
    dto.outcome = outcome;
    this.taskService.completeTask(task.backendUrl, dto).subscribe((res) => {
      this.taskCompleted.emit();
    });
  }

  completeExtendedTaskInput(task: GematikTaskDto, control: UntypedFormControl, outcome: string) {
    const lock: Lock = this.getLockDto(task);
    let dto: GematikCompleteTaskDto = new GematikCompleteTaskDto();
    dto.taskId = task.id;
    dto.outcome = outcome;
    dto.reason = control.value;
    this.taskService.completeTask(task.backendUrl, dto).subscribe((res) => {
      this.taskCompleted.emit();
    });
  }

  cancelClicked(task: GematikTaskDto) {
    const lock: Lock = this.getLockDto(task);
    this.taskService.release(task.backendUrl, lock).subscribe();
  }

  openConfirmationDialog(
    task: GematikTaskDto,
    control: UntypedFormControl,
    confirmAction: any,
    cancelAction: any,
  ): MatDialogRef<GemSimpleTaskDialogComponent, any> {
    return this.dialog.open(GemSimpleTaskDialogComponent, {
      data: {
        task,
        control,
        confirmAction,
        cancelAction,
      },
      // panelClass: "simple-task-dialog-style",
    });
  }

  openDefaultDialog(
    task: GematikTaskDto,
    control: UntypedFormControl,
    approveAction: any,
    rejectAction: any,
    cancelAction: any,
  ): MatDialogRef<GemDefaultTaskDialogComponent, any> {
    return this.dialog.open(GemDefaultTaskDialogComponent, {
      data: {
        task,
        control,
        approveAction,
        rejectAction,
        cancelAction,
      },
      // panelClass: "simple-task-dialog-style",
    });
  }

  openExtendedDialog(
    task: GematikTaskDto,
    control: UntypedFormControl,
    approveAction: any,
    rejectAction: any,
    extendedAction: any,
    cancelAction: any,
  ): MatDialogRef<GemExtendedTaskDialogComponent, any> {
    return this.dialog.open(GemExtendedTaskDialogComponent, {
      data: {
        task,
        control,
        approveAction,
        rejectAction,
        extendedAction,
        cancelAction,
      },
      // panelClass: "simple-task-dialog-style",
    });
  }

  customFilterPredicate(data: any, filters: { [id: string]: ValueLabel[] }): boolean {
    let expression: string = "";
    Object.keys(filters).forEach((key, index, array) => {
      const vls: ValueLabel[] = filters[key];
      if (vls.length > 0) {
        if (key !== "searchStr") {
          let expr: string = "(";
          vls.forEach((vl, index, array) => {
            expr = expr + `data["${key}"].includes("${vl.value}")`;
            if (index !== array.length - 1) {
              expr = expr + " || ";
            } else {
              expr = expr + ")";
            }
          });
          expression = expression + expr + " && ";
        } else {
          if (vls[0].value) {
            const buidSearchExpr: string = `data["buid"].toLowerCase().includes("${vls[0].value.toLowerCase()}")`;
            const partnerSearchExpr: string = `data["partner"].toLowerCase().includes("${vls[0].value.toLowerCase()}")`;
            const partnerTypeSearchExpr: string = `data["partnerType"].toLowerCase().includes("${vls[0].value.toLowerCase()}")`;
            const partnerRoleSearchExpr: string = `data["role"].toLowerCase().includes("${vls[0].value.toLowerCase()}")`;
            const assigneeSearchExpr: string = `data["assigneeName"]?.toLowerCase().includes("${vls[0].value.toLowerCase()}")`;
            const processNameSearchExpr: string = this.translateService
              .instant(data["processName"])
              .toLowerCase()
              .includes(vls[0].value.toLowerCase());
            const activityNameSearchExpr: string = this.translateService
              .instant(data["name"])
              .toLowerCase()
              .includes(vls[0].value.toLowerCase());
            const createdSearchExpr: string = (
              this.datePipe.transform(data["created"], "dd.MM.YYYY HH:mm") as any
            )
              .toLowerCase()
              .includes(vls[0].value.toLowerCase());

            let expr: string =
              buidSearchExpr +
              " || " +
              partnerSearchExpr +
              " || " +
              partnerTypeSearchExpr +
              " || " +
              partnerRoleSearchExpr +
              " || " +
              assigneeSearchExpr +
              " || " +
              processNameSearchExpr +
              " || " +
              activityNameSearchExpr +
              " || " +
              createdSearchExpr;
            if (expression.length === 0) {
              expression = expr + " && ";
            } else {
              expression = expression + expr + " && ";
            }
          }
        }
      }
    });
    expression = expression.substring(0, expression.lastIndexOf("&&"));

    if (!expression) {
      return true;
    }
    return eval(expression);
  }

  onMatSortChange($event: any) {
    const sortedTasks = Object.create(this.tsks);
    if (["createdTimeStamp", "dueTimeDate"].includes($event.active)) {
      sortedTasks.sort(this.formHelper.sortTasksByCreatedDate);
    } else if (["priority", "taskName", "partner", "processName"].includes($event.active)) {
      sortedTasks.sort(this.formHelper.sortByProperty($event.active));
    } else if (["task"].includes($event.active)) {
      sortedTasks.sort(this.formHelper.sortTasksByProperty($event.active));
    }

    if ($event.direction === "desc") {
      sortedTasks.reverse();
    }
    this.dataSource = new MatTableDataSource(sortedTasks);
    this.dataSource.filterPredicate = (row: any, filter: any) => {
      return this.customFilterPredicate(row, filter);
    };
    this.dataSource.paginator = this.paginator;
    this.cdr.detectChanges();
  }

  getCustomerContactFullname(task: any) {
    return task.customerContactFirstname + " " + task.customerContactLastname;
  }

  getIdentityRole(row: any): string {
    let role: string = "";
    if (row.role) {
      role = row.role;
    }
    if (row.assigneeName) {
      return role.concat(" (", row.assigneeName, ")");
    } else {
      return role;
    }
  }

  onUnclaim(task: GematikTaskDto): void {
    const lock: Lock = this.getLockDto(task);
    this.lockingSubscription = this.taskService.release(task.backendUrl, lock).subscribe();
  }

  onTimeline(task: GematikTaskDto): void {
    this.timelineService.showTimeline(
      task.backendUrl,
      task.buid,
      this.translateService.currentLang,
    );
  }

  onViewCaseLogs(task: GematikTaskDto): void {
    const url: string = `${task.crmUrl}/index.php?module=Cases&action=DetailView&record=${task.caseLogId}`;
    window.open(url);
  }

  onRightClick(event: MouseEvent, data) {
    if (this._type === "my") {
      event.preventDefault();

      this.menuTopLeftPosition.x = event.clientX + "px";
      this.menuTopLeftPosition.y = event.clientY + "px";

      this.matMenuTrigger.menuData = { item: data };

      this.matMenuTrigger.openMenu();
    }
  }

  isUserInternal(task: GematikTaskDto): boolean {
    return this.tokenHelperService.isUserInternal() && task.caseLogId !== null;
  }

  onFilterChange(event: { checked: boolean; vl: ValueLabel }, key: string): void {
    const { checked, vl } = event;
    if (checked) {
      this.selectedFilters = {
        ...this.selectedFilters,
        [key]: [...this.selectedFilters[key], vl],
      };
    } else {
      this.onRemoveFilter(key, vl);
    }
    this.filter();
  }

  onRemoveFilter(key: string, filter: ValueLabel): void {
    this.selectedFilters = {
      ...this.selectedFilters,
      [key]: this.getSelectedFilters(key).filter((f) => f !== filter),
    };
    this.filter();
    if (key === "searchStr") {
      this.clearFilter.emit();
    }
  }

  private filter(): void {
    this.dataSource.filter = this.selectedFilters;
    if (this.dataSource.paginator) {
      const paginator = this.dataSource.paginator as MatPaginator;
      if (paginator.hasPreviousPage()) {
        this.dataSource.paginator.firstPage();
        this.cdr.detectChanges();
      }
    }
  }

  getSelectedFilters(key: string): ValueLabel[] {
    return this.selectedFilters[key];
  }

  getCustomContentData(html: string): string {
    const el = document.createElement("div");
    el.innerHTML = html;
    return el.textContent;
  }

  ngOnDestroy() {
    if (this.lockingSubscription) {
      this.lockingSubscription.unsubscribe();
    }
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}

<!-- <mat-form-field [ngClass]="{'mat-form-field-invalid' : showError, 'ng-invalid' : showError}">
  <input #pickerInput matInput [matDatepicker]="picker" placeholder="{{placeholder + (isRequired ? ' *': '')}}" [(ngModel)]="date" (dateChange)="dateChanged($event)" [disabled]="inputControl.disabled">
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</mat-form-field>
<div class="error" *ngIf="showError"> {{errorMessage}}</div> -->

<mat-form-field [ngClass]="{ 'mat-form-field-invalid': showError, 'ng-invalid': showError }">
  <input
    #pickerInput
    matInput
    [matDatepicker]="picker"
    placeholder="{{ placeholder + (isRequired ? ' *' : '') }}"
    [formControl]="inputControl"
    (dateChange)="dateChanged($event)"
    [disabled]="inputControl.disabled"
    [min]="min ? min : null"
  />
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
  <mat-error *ngIf="inputControl.hasError('required') && inputControl.touched">{{
    requiredErrorMessage
  }}</mat-error>
</mat-form-field>
<div class="error" *ngIf="showError">{{ errorMessage }}</div>

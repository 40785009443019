import { NgModule } from "@angular/core";
import { UtilsModule } from "../lib/utils/utils.module";
import { GemComponentsModule } from "./gem-components/gem-components.module";

@NgModule({
  declarations: [],
  imports: [GemComponentsModule, UtilsModule],
  exports: [GemComponentsModule, UtilsModule],
})
export class GematikFormLibraryModule {}

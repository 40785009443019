import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "gem-timeline",
  templateUrl: "./gem-timeline.component.html",
  styleUrls: ["./gem-timeline.component.scss"],
})
export class GemTimelineComponent implements OnInit {
  @Input() value: any[] = [];
  @Input() title: string;
  @Input() legendOpen: string;
  @Input() legendCurrent: string;
  @Input() legendFinished: string;

  constructor() {}

  ngOnInit(): void {}
}

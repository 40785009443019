<span>
  <input
    type="file"
    [accept]="fileTypes"
    class="file-input"
    #fileUpload
    hidden
    [multiple]="multiple"
    [formControl]="inputControl"
    (change)="emitChange($event)"
  />
  <div class="file-upload text-center">
    <div *ngIf="showFileName">
      <!-- <span style="font-size: 14px" *ngIf="inputControl.value">{{ fileName }}</span> -->
      <span style="font-size: 14px" *ngIf="!inputControl.value">{{
        placeholder + (isRequired ? " *" : "")
      }}</span>

      <span [ngbTooltip]="fileName">
        <mat-icon
          *ngIf="inputControl.value"
          style="color: var(--gematik-blue)"
          fontIcon="description"
        ></mat-icon>
      </span>
    </div>
    <mat-icon
      class="material-icons nav-icons icon-lg"
      svgIcon="gem-upload-blue"
      style="cursor: pointer"
      (click)="fileUpload.click()"
    ></mat-icon>

    <button
      *ngIf="downloadLink"
      mat-mini-fab
      color="primary"
      class="upload-btn"
      (click)="onDownloadClick()"
    >
      <mat-icon>file_download</mat-icon>
    </button>
  </div>
</span>
<mat-hint>{{ hint }}</mat-hint>
<mat-error *ngIf="inputControl.hasError('required')">{{ requiredErrorMessage }}</mat-error>

import { Component, OnDestroy, OnInit } from "@angular/core";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { EnvService } from "gematik-form-library";
import { Alert, PortalService } from "gematik-shared";
import { Observable, Subscription } from "rxjs";

@Component({
  selector: "data-privacy",
  styleUrls: ["data-privacy.component.scss"],
  template: `
    <gem-header [showQuickAction]="false"></gem-header>
    <div class="container mt-0" style="--bs-gutter-x: 0; --bs-gutter-y: 0">
      <gematik-alerts [alerts]="alerts$ | async"></gematik-alerts>
    </div>
    <div class="row mt-3 gib-flex-center text--large">
      <div class="col-sm-12 form-width">
        <div class="row">
          <div class="col-sm-12">
            <div class="d-flex justify-content-between mt-3">
              <h1>
                <span>Datenschutzerklärung – gematik myServices</span>
              </h1>
            </div>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-sm-12">
            <h2>1. Allgemeine Hinweise</h2>
            <p>
              Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren
              personenbezogenen Daten passiert, wenn Sie diese Webseite besuchen und unsere Angebote
              nutzen. Personenbezogene Daten sind alle Informationen, die sich auf eine
              identifizierte oder identifizierbare natürliche Person beziehen. Hierunter fallen z.B.
              Angaben wie Name, Adresse, Beruf, E-Mail-Adresse, Gesundheitszustand, Einkommen,
              Familienstand, genetische Merkmale, Telefonnummer und gegebenenfalls auch Nutzerdaten
              wie die IP-Adresse. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie
              unserer unter diesem Text aufgeführten Datenschutzerklärung.
            </p>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-sm-12">
            <h3>1.1 Verantwortlicher (Art. 4 Nr. 7 DS-GVO)</h3>
            <p>
              Der Verantwortliche für die Verarbeitung Ihrer personenbezogenen Daten im Rahmen der
              Nutzung dieser Webseite
              <a href="http://www.gematik.de" target="_blank">www.gematik.de</a> (nachfolgend auch
              Webseite) ist die gematik GmbH, Friedrichstr. 136, 10117 Berlin (nachfolgend „Wir“
              oder „Verantwortlicher“), vertreten durch die Geschäftsleitung. Die Kontaktdaten des
              Verantwortlichen sind:
            </p>
            <p class="mt-2">Telefon: +49 30 400 41-0</p>
            <p>Fax: +49 30 400 41-111</p>
            <p>E-Mail: <a href="mailto:info@gematik.de">info&#64;gematik.de</a></p>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-sm-12">
            <h3>1.2 Datenschutzbeauftragter</h3>
            <p>
              Wir haben einen Datenschutzbeauftragten bestellt. Dieser ist unter
              <a href="mailto:datenschutz@gematik.de">datenschutz&#64;gematik.de</a> zu erreichen.
            </p>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-sm-12">
            <h3>1.3 Widerspruchsmöglichkeit</h3>
            <p>
              Sofern Sie der Verarbeitung Ihrer Daten durch uns nach Maßgabe dieser
              Datenschutzerklärung insgesamt oder für einzelne Maßnahmen widersprechen wollen,
              können Sie dies unter den im Impressum angegebenen Kontaktdaten tun. Bitte beachten
              Sie, dass im Falle eines solchen Widerspruchs die Nutzung der Webseite und der Abruf
              der hierüber angebotenen Leistungen und Services unter Umständen nur eingeschränkt
              oder überhaupt nicht möglich sind.
            </p>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-sm-12">
            <h2>2. Datenverarbeitung, Rechtsgrundlage, Bereitstellung und Speicherdauer</h2>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-sm-12">
            <h3>2.1 Nutzung der gematik myServices Dienste</h3>
            <p>
              Wenn Sie die Dienste des gematik myServices Portals nutzen möchten, können Sie sich
              durch das Anlegen eines Accounts registrieren. Dazu stehen folgende Möglichkeiten zur
              Verfügung:
            </p>
            <ul class="mt-3">
              <li>
                <strong>Person</strong> – Wenn Sie sich persönlich registrieren und (zunächst) nur
                persönliche Services nutzen möchten, müssen Sie folgende Angaben machen: Vorname,
                Nachname und E-Mail-Adresse. Optional können Sie auch eine Telefonnummer angeben.
              </li>
              <li>
                <strong>Organisation</strong> – Wenn Sie eine Organisation registrieren und
                zusätzlich Services im Namen bzw. für diese Organisation nutzen möchten, müssen Sie
                folgende Angaben machen: Grund der Kontaktaufnahme, Firmenname, Firmenkurzname,
                Straße, Postleitzahl, Ort, und Land der Organisation sowie Vorname, Nachname und
                E-Mail-Adresse eines Ansprechpartners. Optional können Sie auch die Steuernummer und
                Umsatznummer der Organisation sowie eine Telefonnummer des genannten
                Ansprechpartners angeben.
              </li>
            </ul>
            <p class="mt-3">
              Die Zulässigkeit dieser Verarbeitung richtet sich nach Art. 6 Abs. 1 b) DS-GVO
              (Vertrag oder vorvertragliche Maßnahme). Die Bereitstellung Ihrer Daten ist
              erforderlich, da Sie ansonsten keine Dienste des gematik myServices Portals nutzen
              können.
            </p>
            <p class="mt-3">
              Der Zweck der Datenverarbeitung liegt insbesondere in der Bereitstellung eines
              zentralen Identitätsmanagements, der Bereitstellung von effizienten Selfservices und
              der Optimierung von Prozessen.
            </p>
            <p class="mt-3">
              Die verarbeiteten personenbezogenen Daten werden nach Ablauf der gesetzlichen
              Aufbewahrungspflichten gelöscht, sofern wir nicht ein berechtigtes Interesse an der
              weiteren Aufbewahrung haben. Es werden in jedem Fall nur diejenigen Daten weiterhin
              gespeichert, die für die Erreichung des entsprechenden Zwecks auch wirklich zwingend
              benötigt werden.
            </p>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-sm-12">
            <h3>2.2 Feedbackformular</h3>
            <p>
              Wenn Sie unser Portal bzw. die darin bereitgestellten Dienste bewerten, uns
              Verbesserungsvorschläge zukommen oder eine Störung melden möchten, steht Ihnen hierfür
              ein Feedbackformular zur Verfügung. Im Rahmen dieses Formulars müssen Sie in
              Abhängigkeit der jeweils gewählten Funktion die folgenden Angaben machen:
            </p>
            <ul class="mt-3">
              <li>
                <strong>My Services bewerten</strong> – Wenn Sie My Services bewerten möchten,
                müssen Sie folgende Angaben machen: Bewertung (Skala von eins bis fünf). Optional
                können Sie auch einen Kommentar hinterlassen und/oder eine E-Mail-Adresse angeben.
              </li>
              <li>
                <strong>Verbesserungsidee</strong> – Wenn Sie eine Verbesserungsidee hinterlassen
                möchten, müssen Sie folgende Angaben machen: Beschreibung der Verbesserungsidee.
                Optional können Sie auch eine E-Mail-Adresse angeben.
              </li>
              <li>
                <strong>Störung melden</strong> – Wenn Sie eine Störung melden möchten, müssen Sie
                folgende Angaben machen: Beschreibung der Störung und E-Mail-Adresse.
              </li>
            </ul>
            <p class="mt-3">
              Die Zulässigkeit der Verarbeitung bei Nutzung der Funktionen „My Services bewerten“
              und „Verbesserungsidee“ richtet sich nach Art. 6 Abs. 1 a) DS-GVO (Einwilligung). Die
              Bereitstellung Ihrer personenbezogenen Daten ist optional und erfolgt freiwillig.
            </p>
            <p class="mt-3">
              Die Zulässigkeit der Verarbeitung bei Nutzung der Funktion „Störung melden“ richtet
              sich nach Art. 6 Abs. 1 f) DS-GVO (berechtigtes Interesse). Unser berechtigtes
              Interesse liegt in der ordnungsgemäßen Bearbeitung und Nachverfolgung von gemeldeten
              Störungen und der Möglichkeit mit der meldenden Person in Kontakt zu treten, sofern
              dies zur Beseitigung einer Störung notwendig ist.
            </p>
            <p class="mt-3">
              Der Zweck der Datenverarbeitung besteht in der kontinuierlichen Weiterentwicklung des
              Portals und einer gesteigerten Reaktionsmöglichkeit auf gemeldete Störungen und
              Probleme innerhalb des Portals.
            </p>
            <p class="mt-3">
              Die verarbeiteten personenbezogenen Daten werden nach Ablauf der gesetzlichen
              Aufbewahrungspflichten gelöscht, sofern wir nicht ein berechtigtes Interesse an der
              weiteren Aufbewahrung haben. Es werden in jedem Fall nur diejenigen Daten weiterhin
              gespeichert, die für die Erreichung des entsprechenden Zwecks auch wirklich zwingend
              benötigt werden.
            </p>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-sm-12">
            <h3>2.3 Aufruf und Nutzung der Webseite</h3>
            <p>
              Bei jedem Zugriff auf die Webseite und deren Unterseiten werden Nutzungsdaten durch
              den jeweiligen Internetbrowser übermittelt und in Protokolldateien gespeichert (Server
              Logfiles). Die dabei gespeicherten Datensätze enthalten die folgenden Daten:
            </p>
            <ul class="mt-3">
              <li>Datum und Uhrzeit des Abrufs</li>
              <li>Name der aufgerufenen Unterseite</li>
              <li>IP-Adresse</li>
              <li>Referrer-URL (Herkunfts-URL, von der aus Sie auf die Webseite gekommen sind)</li>
              <li>Übertragene Datenmenge</li>
              <li>Produkt- und Versionsinformationen des verwendeten Browsers</li>
            </ul>
            <p class="mt-3">
              Die Protokolldateien werden von uns in anonymisierter Form ausgewertet, um die
              Webseite weiter zu verbessern und nutzerfreundlicher zu gestalten, Fehler schneller zu
              finden und zu beheben sowie Serverkapazitäten zu steuern. So kann beispielsweise
              nachvollzogen werden, zu welcher Zeit die Nutzung der Webseite besonders beliebt ist
              und entsprechendes Datenvolumen zur Verfügung gestellt werden.
            </p>
            <p class="mt-3">
              Die Zulässigkeit dieser Verarbeitung richtet sich nach Art. 6 Abs. 1 f) DS-GVO
              (berechtigtes Interesse). Unser berechtigtes Interesse liegt in der
              Zurverfügungstellung einer Webseite mit Informationen und dem Anbieten von Leistungen
              gegenüber unseren Kunden sowie der Optimierung des Webseitenbetriebs. Die
              Bereitstellung der Daten ist weder gesetzlich noch vertraglich vorgeschrieben. Die
              Nichtbereitstellung hat zur Folge, dass die Daten nicht zur Optimierung der Webseite
              genutzt werden können.
            </p>
            <p class="mt-3">
              Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes nicht mehr
              erforderlich sind. Im Falle der Erfassung der Daten zur Bereitstellung der Webseite
              ist dies der Fall, wenn die jeweilige Sitzung beendet ist. Im Falle der Speicherung
              der Daten in Logfiles ist dies nach spätestens sieben Tagen der Fall. Eine
              darüberhinausgehende Speicherung ist möglich. In diesem Fall werden die IP-Adressen
              der Nutzer gelöscht oder verfremdet, sodass eine Zuordnung des aufrufenden Clients
              nicht mehr möglich ist.
            </p>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-sm-12">
            <h3>2.4 Verwendung von Cookies</h3>
            <p>
              Wir verwenden auf dieser Webseite ausschließlich notwendige Cookies, um die
              Darstellung unserer Webseite zu gewährleisten. Die Zulässigkeit dieser Verarbeitung
              richtet sich nach § 25 Abs. 2 Nr. 2 TDSG, wobei eine Einwilligung nicht erforderlich
              ist, da die Cookies nicht zur Analyse des Nutzerverhaltens eingesetzt werden.
            </p>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-sm-12">
            <h2>3. Ihre Rechte</h2>
            <p>
              Ihnen stehen folgende Rechte zu: Sie haben ein Recht auf Auskunft (Art. 15 DS-GVO),
              Berichtigung (Art. 16 DS-GVO), Löschung (Art. 17 DS-GVO), Einschränkung der
              Verarbeitung (Art. 18 DS-GVO) sowie auf Datenübertragung (Art. 20 DS-GVO).
            </p>
            <p class="mt-3">
              Sie haben jederzeit das Recht, sich bei einer Aufsichtsbehörde zu beschweren (Art. 77
              DS-GVO)
            </p>
            <p class="mt-3">
              <strong
                >Sofern Ihre personenbezogenen Daten auf Grundlage von Art. 6 Abs. 1 f) DS-GVO
                verarbeitet werden, haben Sie ein Widerspruchsrecht, soweit dafür Gründe vorliegen,
                die sich aus Ihrer besonderen Situation ergeben oder sich der Widerspruch gegen
                Direktwerbung richtet (Art. 21 DS-GVO). Bei einem Widerspruch gegen Direktwerbung
                werden wir Ihnen keine werblichen Nachrichten mehr zukommen lassen.</strong
              >
            </p>
            <p class="mt-3">
              Sie können eine gegenüber uns erteilte Einwilligung jederzeit mit Wirkung für die
              Zukunft widerrufen. Das hat allerdings keinen Einfluss auf die bis dahin erfolgte
              Datenverarbeitung auf Basis dieser Einwilligung.
            </p>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-sm-12">
            <h2>4. Empfänger</h2>
            <p>
              Die bei Aufruf und Nutzung der Webseite erhobenen Daten und die von Ihnen bei der
              Kontaktaufnahme gemachten Angaben werden an uns übermittelt und von uns gespeichert.
              Im Übrigen können Ihre Daten an folgende Kategorien von Empfängern weitergegeben
              werden:
            </p>
            <ul class="mt-3">
              <li>
                Organisationseinheiten sowie Personen bei uns, die mit der Verarbeitung befasst sind
                (z.B. Kundenservice, IT)
              </li>
              <li>Auftragsverarbeiter (z.B. IT-Dienstleister, Host der Webseite)</li>
              <li>Vertragspartner</li>
            </ul>
          </div>
        </div>

        <div class="row mt-3 mb-3">
          <div class="col-sm-12">
            <h2>5. Links zu Drittseiten</h2>
            <p>
              Beim Besuch der Webseite könnten Inhalte angezeigt werden, die mit den Webseiten
              Dritter verlinkt sind. Wir haben keinen Zugang zu den Cookies oder anderen Funktionen,
              die von Drittseiten eingesetzt werden, noch können wir diese kontrollieren. Solche
              Drittseiten unterliegen nicht unseren Datenschutzbestimmungen.
            </p>
          </div>
        </div>
      </div>
    </div>

    <gem-footer></gem-footer>
  `,
})
export class DataPrivacyComponent implements OnInit, OnDestroy {
  lang: string;
  alerts$: Observable<Alert[]>;
  subscriptions: Subscription[] = [];

  constructor(
    private translate: TranslateService,
    private env: EnvService,
    private portalService: PortalService,
  ) {}

  ngOnInit(): void {
    this.alerts$ = this.portalService.getAlerts(this.env.backendUrl, "UWL");
    this.translate
      .get(`languages.${this.translate.currentLang === "en" ? "de" : "en"}`)
      .subscribe((res: string) => {
        this.lang = res;
      })
      .unsubscribe();

    const langSub = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.translate
        .get(`languages.${event.lang === "en" ? "de" : "en"}`)
        .subscribe((res: string) => {
          this.lang = res;
        })
        .unsubscribe();
    });
    this.subscriptions.push(langSub);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}

import { TaskService } from "./camunda/task.service";
import { ProcessService } from "./camunda/process.service";
import { IncidentService } from "./camunda/incident.service";
import { UwlService } from "./uwl/uwl.service";
import { TemplateService } from "./uwl/template.service";
import { HelpService } from "./uwl/help.service";
import { AlertService } from "./uwl/alert.service";
import { PersonalProfileService } from "./profile/personal-profile.service";
import { MyServicesService } from "./my-services/my-services.service";
import { QuickActionsService } from "./quick-actions/quick-actions.service";
import { OrganisationalProfileService } from "./profile/organisational-profile.service";
import { RightsService } from "./rights/rights.service";
import { RedisCacheService } from "./redis-cache/redis-cache.service";
import { ProfileService } from "./profile/profile.service";

export const services: any[] = [
  UwlService,
  TaskService,
  ProcessService,
  IncidentService,
  TemplateService,
  HelpService,
  PersonalProfileService,
  MyServicesService,
  QuickActionsService,
  OrganisationalProfileService,
  AlertService,
  RightsService,
  RedisCacheService,
  ProfileService,
];

export * from "./uwl/uwl.service";
export * from "./camunda/task.service";
export * from "./camunda/process.service";
export * from "./camunda/incident.service";
export * from "./uwl/template.service";
export * from "./uwl/help.service";
export * from "./profile/personal-profile.service";
export * from "./my-services/my-services.service";
export * from "./quick-actions/quick-actions.service";
export * from "./profile/organisational-profile.service";
export * from "./uwl/alert.service";
export * from "./rights/rights.service";
export * from "./redis-cache/redis-cache.service";
export * from "./profile/profile.service";

<h1 mat-dialog-title>{{ data.title }}</h1>
<div mat-dialog-content>
  <p [innerHTML]="data.text"></p>
</div>
<div mat-dialog-actions class="mb-2">
  <button class="btn btn-secondary resetFilter" (click)="cancelAction()">
    {{ "CANCEL" | translate }}
  </button>
  <button
    class="btn"
    [ngClass]="data.confirmClass ? data.confirmClass : 'btn-primary'"
    (click)="confirmAction()"
    cdkFocusInitial
  >
    {{ data.confirmText ? data.confirmText : "Ok" }}
  </button>
</div>

<h1 mat-dialog-title>{{ data.title }}</h1>
<div mat-dialog-content>
  <p [innerHTML]="data.text"></p>
  <gem-input [placeholder]="data.confirmationPlaceholderText" [control]="control"></gem-input>
</div>
<div mat-dialog-actions>
  <button class="btn btn-secondary resetFilter" (click)="cancelAction()">
    {{ "CANCEL" | translate }}
  </button>
  <button class="btn btn-primary" (click)="confirmAction()" cdkFocusInitial>Ok</button>
</div>

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { GemComponentsModule } from "gematik-form-library";
import { UtilsModule } from "gematik-form-library";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NotFoundPageComponent } from "./not-found-page/not-found-page.component";
import { OauthRedirectComponent } from "./oauth-redirect/oauth-redirect.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { EntityDataService, EntityDefinitionService, EntityMetadataMap } from "@ngrx/data";
import { TaskCountDataService } from "./services/task-count-data.service";
import { UwlEndpointDataService } from "./services/uwl-endpoint-data.service";
import { UwlEndpointEntityService } from "./services/uwl-endpoint-entity.service";
import { TaskCountEntityService } from "./services/task-count-entity.service";
import { LegalNoticeComponent } from "./legal-notice/legal-notice.component";

const entityMetadata: EntityMetadataMap = {
  TaskCount: {},
  Endpoint: {},
};

@NgModule({
  declarations: [
    DashboardComponent,
    NotFoundPageComponent,
    OauthRedirectComponent,
    LegalNoticeComponent,
  ],
  imports: [CommonModule, GemComponentsModule, UtilsModule, NgbModule],
  providers: [
    UwlEndpointDataService,
    TaskCountDataService,
    UwlEndpointEntityService,
    TaskCountEntityService,
  ],
})
export class PagesModule {
  constructor(
    private entityDefinitionService: EntityDefinitionService,
    private entityDataService: EntityDataService,
    private taskCountDataService: TaskCountDataService,
    private uwlEndpointDataService: UwlEndpointDataService,
  ) {
    entityDefinitionService.registerMetadataMap(entityMetadata);
    entityDataService.registerService("TaskCount", taskCountDataService);
    entityDataService.registerService("Endpoint", uwlEndpointDataService);
  }
}

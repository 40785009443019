import { Component, Input, OnInit } from "@angular/core";

import * as fromStore from "../../../../store";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";

import { GematikProfile } from "../../../../models/profile.model";

@Component({
  selector: "profile-display",
  styleUrls: ["profile-display.component.scss"],
  template: `
    <div class="d-flex justify-content-center mt-2" style="width: 100%; ">
      <a href="" routerLink="profile"
        ><img
          [src]="getAvatar(profile$ | async)"
          [ngClass]="isExpanded ? 'avatar' : 'avatar-small'"
        />
      </a>
    </div>
    <div class="d-flex justify-content-center mt-2" style="width: 100%">
      <a href="" routerLink="profile" class="gem-link-blue">
        <p data-testid="fullname" *ngIf="isExpanded" class="gem-color-blue fw-bold">
          {{ (profile$ | async)?.firstName }} {{ (profile$ | async)?.lastName }}
        </p>
        <p data-testid="initials" *ngIf="!isExpanded" class="gem-color-blue fw-bold">
          {{ getInitials(profile$ | async) }}
        </p>
      </a>
    </div>
    <div *ngIf="isExpanded" class="d-flex justify-content-center mt-2" style="width: 100%">
      <div class="pt-1 pb-1 ps-3 pe-3 gem-bg-color-light-grey" style="border-radius: 50px">
        <span class="fs-6 gem-color-dark-grey">{{ (profile$ | async)?.organisation }}</span>
      </div>
    </div>
  `,
})
export class ProfileDisplayComponent implements OnInit {
  @Input() isExpanded: boolean;

  profile$: Observable<GematikProfile>;

  constructor(private store: Store<fromStore.UwlState>) {}

  ngOnInit(): void {
    this.profile$ = this.store.select(fromStore.getProfile);
  }

  getInitials(profile: GematikProfile): string {
    if (profile) {
      const fullName = profile.firstName + " " + profile.lastName;
      return fullName
        .split(" ")
        .map((n) => n[0])
        .join("");
    }
    return "Anonymous";
  }

  getAvatar(profile: GematikProfile): string {
    if (profile) {
      return `data:${profile.avatar?.mimeType};base64,${profile.avatar?.data}`;
    }
    return null;
  }
}

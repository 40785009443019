<mat-form-field class="gem-input-decimal-field">
  <span>
    <input
      (focusout)="trim()"
      trim
      matInput
      placeholder="{{ placeholder }} "
      [formControl]="inputControl"
      type="{{ type }}"
      [multiNumber]="{
        isNumber: options?.isNumber,
        isDecimal: options?.isDecimal,
        maxValue: options?.maxValue
      }"
    />
    <div>{{ suffix }}</div>
  </span>
  <mat-hint>{{ hint }}</mat-hint>
  <mat-error *ngIf="inputControl.hasError('required')">{{ requiredErrorMessage }}</mat-error>
  <mat-error *ngIf="inputControl.hasError('email')">{{ "email_invalid" | translate }}</mat-error>
  <mat-error *ngIf="inputControl.hasError('noMobileNumber')">{{
    "no_mobile_number" | translate
  }}</mat-error>
  <mat-error *ngIf="inputControl.hasError('pwNotSame')">{{ "pwsNotSame" | translate }}</mat-error>
  <mat-error *ngIf="inputControl.hasError('noValidNumber')">{{
    noValidNumberErrorMessage
  }}</mat-error>
</mat-form-field>

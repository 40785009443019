import { Component, Inject, Input } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

interface DialogData {
  places: google.maps.places.PlaceResult[];
}

@Component({
  selector: 'gem-input-google-address-select',
  templateUrl: 'gem-input-google-address-select.component.html',
})
export class GemInputGoogleAddressSelect {

  places: google.maps.places.PlaceResult[] = [];

  constructor(
    public dialogRef: MatDialogRef<GemInputGoogleAddressSelect>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) { 
    this.places = data.places;
  }

  onAddressClicked(place: google.maps.places.PlaceResult): void {
    this.dialogRef.close(place);
  }

}

import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { ValueLabel } from "../value-label";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { Observable, Subscription } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { MatCheckboxChange } from "@angular/material/checkbox";

@Component({
  selector: "gem-filter",
  styleUrls: ["gem-filter.component.scss"],
  template: `
    <div>
      <button
        data-testid="filter"
        class="filter"
        [ngClass]="filterToggle ? 'filter-opened' : 'filter-closed'"
        (click)="onFilterClick()"
      >
        {{ title }}
      </button>

      <div
        *ngIf="options.length > 0"
        data-testid="dropDown"
        [formGroup]="form"
        class="filterDropDownMenu"
        [ngClass]="!filterToggle ? 'hidden' : ''"
      >
        <mat-form-field appearance="outline" class="mt-2">
          <mat-label>{{ searchLabel }}</mat-label>
          <input data-testid="search" matInput [formControl]="searchControl" />
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
        <ul formArrayName="selected" style="overflow-x: auto; max-height: 200px;">
          <li *ngFor="let option of filteredOptions$ | async; let i = index">
            <mat-checkbox
              data-testid="option"
              [formControlName]="i"
              (change)="onChange($event, option)"
            >
              {{ option.label | translate }}
            </mat-checkbox>
          </li>
        </ul>
      </div>
    </div>
  `,
})
export class GemFilterComponent implements OnInit, OnChanges, OnDestroy {
  @Input() title: string;
  @Input() searchLabel: string;
  @Input() options: ValueLabel[];
  @Input() value: ValueLabel[];
  @Input() reset: { search: boolean };
  @Output() change: EventEmitter<{ checked: boolean; vl: ValueLabel }> = new EventEmitter();

  form: FormGroup = this.fb.group({
    selected: this.fb.array([]),
  });
  filterToggle: boolean = false;
  searchControl: FormControl = this.fb.control(null);
  filteredOptions$: Observable<ValueLabel[]>;
  subscriptions: Subscription[] = [];

  private insideClick: boolean = false;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.filteredOptions$ = this.searchControl.valueChanges.pipe(
      startWith(""),
      map((value) => (typeof value === "string" ? value : value?.label)),
      map((label) => (label ? this.filter(label) : this.options.slice())),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    // asynchronouse options
    if (changes.options && changes.options.currentValue) {
      if (changes.options.currentValue.length > 0) {
        this.form = this.fb.group({
          selected: this.fb.array(Object.keys(changes.options.currentValue).map((key) => false)),
        });
        this.searchControl.setValue("");
      }
    }

    if (changes.value && changes.value.currentValue) {
      const value: ValueLabel[] = changes.value.currentValue;
      let selected = Object.keys(this.options).map((key) => false);
      value.forEach((v) => {
        const index = this.options.findIndex((option) => option === v);
        selected[index] = true;
      });
      this.form.patchValue({ selected });
    }

    if (changes.reset && changes.reset.currentValue && changes.reset.currentValue.search) {
      this.searchControl.setValue(null);
    }
  }

  onFilterClick(): void {
    this.filterToggle = !this.filterToggle;
  }

  onChange(event: MatCheckboxChange, option: any): void {
    this.change.emit({ checked: event.checked, vl: option });
  }

  @HostListener("click")
  click() {
    this.insideClick = true;
  }

  @HostListener("document:click")
  clickOut() {
    if (!this.insideClick) {
      this.filterToggle = false;
    }
    this.insideClick = false;
  }

  private filter(label: string): ValueLabel[] {
    const filterValue = label.toLocaleLowerCase();
    return this.options.filter((option) => option.label.toLowerCase().includes(filterValue));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}

import { Injectable } from "@angular/core";
import { DefaultDataService, HttpUrlGenerator } from "@ngrx/data";
import { HttpClient } from "@angular/common/http";
import { EMPTY, Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { TaskCount } from "../../models/task-count.model";
import { GematikTaskApiService } from "gematik-task-api";

@Injectable()
export class TaskCountDataService extends DefaultDataService<TaskCount> {
  constructor(
    http: HttpClient,
    httpUrlGenerator: HttpUrlGenerator,
    private taskService: GematikTaskApiService,
  ) {
    super("TaskCount", http, httpUrlGenerator);
  }

  getById(key: string): Observable<TaskCount> {
    return this.taskService.getTasksCount(key).pipe(
      map((res) => {
        const taskCount: TaskCount = res.body;
        taskCount.id = key;
        return res.body;
      }),
      catchError((error) => EMPTY),
    );
  }
}

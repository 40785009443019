import { Directive, AfterViewInit, OnDestroy, Optional, EventEmitter, Output } from "@angular/core";
import { MatAutocompleteTrigger } from "@angular/material/autocomplete";

@Directive({ selector: "[tab-directive]" })
export class TabDirective implements AfterViewInit, OnDestroy {
  @Output() changeEvent = new EventEmitter();

  observable: any;

  constructor(@Optional() private autoTrigger: MatAutocompleteTrigger) {}
  ngAfterViewInit() {
    this.observable = this.autoTrigger?.panelClosingActions.subscribe((x) => {
      if (this.autoTrigger?.activeOption) {
        this.autoTrigger.writeValue(this.autoTrigger.activeOption.value);
        this.changeEvent.emit(this.autoTrigger.activeOption.value);
      }
    });
  }

  ngOnDestroy() {
    this.observable?.unsubscribe();
  }
}

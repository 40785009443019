import { ModuleWithProviders, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { TranslateModule } from "@ngx-translate/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ReactiveFormsModule } from "@angular/forms";

// shared libraries
import { GemComponentsModule } from "gematik-form-library";

// components
import { GemFeedbackCategoryFormComponent } from "./gematik-feedback/components/gem-feedback-category-form/gem-feedback-category-form.component";
import { GemFeedbackGeneralFormComponent } from "./gematik-feedback/components/gem-feedback-general-form/gem-feedback-general-form.component";
import { GemFeedbackFeatureFormComponent } from "./gematik-feedback/components/gem-feedback-feature-form/gem-feedback-feature-form.component";
import { GemFeedbackBugFormComponent } from "./gematik-feedback/components/gem-feedback-bug-form/gem-feedback-bug-form.component";

// containers
import { GemFeedbackComponent } from "./gematik-feedback/containers/gem-feedback.component";
import { GemFooterComponent } from "./gematik-footer/containers/gem-footer.component";
import { GemLoadingSpinnerComponent } from "./gematik-loading-spinner/containers/gem-loading-spinner/gem-loading-spinner.component";

// services
import { LoadingService } from "./services/loading.service";
import { TimelineService } from "./services/timeline.service";
import { SnackBarService } from "./services/snack-bar.service";

// interceptors
import { HttpRequestInterceptor } from "./interceptors/http-request-interceptor";

@NgModule({
  declarations: [
    GemFeedbackComponent,
    GemFeedbackCategoryFormComponent,
    GemFeedbackGeneralFormComponent,
    GemFeedbackFeatureFormComponent,
    GemFeedbackBugFormComponent,
    GemFooterComponent,
    GemLoadingSpinnerComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    GemComponentsModule,
    NgbModule,
  ],
  exports: [GemFooterComponent, GemLoadingSpinnerComponent],
})
export class GematikSharedModule {
  static forRoot(): ModuleWithProviders<GematikSharedModule> {
    return {
      ngModule: GematikSharedModule,
      providers: [
        LoadingService,
        TimelineService,
        SnackBarService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HttpRequestInterceptor,
          multi: true,
        },
      ],
    };
  }
}

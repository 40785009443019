<div class="row">
  <div class="col-md-8 col-sm-12 padding-lr-0">
    <mat-form-field class="gem-input-field" style="width: 100%">
      <mat-label>{{ streetNamePlaceholder }}</mat-label>
      <input
        #streetNameElRef
        trim
        matInput
        [formControl]="streetNameInputControl"
        type="text"
        [(ngModel)]="streetName"
        id="streetName"
        name="streetName"
      />
      <mat-hint>{{ streetNameHint }}</mat-hint>
      <mat-error *ngIf="streetNameInputControl.hasError('required')">{{
        streetNameRequiredErrorMessage
      }}</mat-error>
    </mat-form-field>
  </div>
  <div class="col-md-1 padding-lr-0"></div>
  <div class="col-md-3 col-sm-12 padding-lr-0">
    <div class="row">
      <div [ngClass]="showCheckBoxes ? 'col-sm-11 ps-0 pe-0' : 'col-sm-12 ps-0 pe-0'">
        <mat-form-field style="width: 100%">
          <mat-label>{{ streetNumberPlaceholder }}</mat-label>
          <input
            #streetNumberElRef
            id="streetNumberElRef"
            matInput
            type="text"
            [formControl]="streetNumberInputControl"
            [(ngModel)]="streetNumber"
            (change)="onAddressChange()"
          />
          <mat-hint>{{ streetNumberHint }}</mat-hint>
          <mat-error *ngIf="streetNumberInputControl.hasError('required')">{{
            streetNumberRequiredErrorMessage
          }}</mat-error>
        </mat-form-field>
      </div>
      <div *ngIf="showCheckBoxes" class="col-sm-1 ps-0 pe-0">
        <gem-checkbox class="mr-2 d-flex mt-2" [control]="streetCheckboxControl"></gem-checkbox>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-5 col-sm-12 padding-lr-0">
    <div class="row">
      <div [ngClass]="showCheckBoxes ? 'col-sm-11 ps-0 pe-0' : 'col-sm-12 ps-0 pe-0'">
        <mat-form-field style="width: 100%">
          <mat-label>{{ postalCodePlaceholder }}</mat-label>
          <input
            matInput
            placeholder=""
            type="text"
            [formControl]="postalCodeInputControl"
            [(ngModel)]="postalCode"
            (change)="onAddressChange()"
          />
        </mat-form-field>
      </div>
      <div *ngIf="showCheckBoxes" class="col-sm-1 ps-0 pe-0">
        <gem-checkbox class="mr-2 d-flex mt-2" [control]="postalCodeCheckboxControl"></gem-checkbox>
      </div>
    </div>
  </div>
  <div class="col-md-2 padding-lr-0"></div>
  <div class="col-md-5 col-sm-12 padding-lr-0">
    <div class="row">
      <div [ngClass]="showCheckBoxes ? 'col-sm-11 ps-0 pe-0' : 'col-sm-12 ps-0 pe-0'">
        <mat-form-field style="width: 100%">
          <mat-label>{{ cityPlaceholder }}</mat-label>
          <input
            matInput
            type="text"
            [formControl]="cityInputControl"
            [(ngModel)]="city"
            (change)="onAddressChange()"
          />
        </mat-form-field>
      </div>
      <div *ngIf="showCheckBoxes" class="col-sm-1 ps-0 pe-0">
        <gem-checkbox class="mr-2 d-flex mt-2" [control]="cityCheckboxControl"></gem-checkbox>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-6 col-sm-12 padding-lr-0">
    <div class="row">
      <div [ngClass]="showCheckBoxes ? 'col-sm-11 ps-0 pe-0' : 'col-sm-12 ps-0 pe-0'">
        <mat-form-field style="width: 100%">
          <mat-label>{{ countryPlaceholder }}</mat-label>
          <input
            matInput
            type="text"
            [formControl]="countryInputControl"
            [(ngModel)]="country"
            (change)="onAddressChange()"
          />
        </mat-form-field>
      </div>
      <div *ngIf="showCheckBoxes" class="col-sm-1 ps-0 pe-0">
        <gem-checkbox class="mr-2 d-flex mt-2" [control]="countryCheckboxControl"></gem-checkbox>
      </div>
    </div>
  </div>
</div>
<mat-form-field style="width: 100%" hidden>
  <span>
    <input
      matInput
      placeholder="Sub Locality Level 1"
      type="text"
      disabled
      [value]="subLocalityLevel1"
    />
  </span>
</mat-form-field>
<mat-form-field style="width: 100%" hidden>
  <span>
    <input
      matInput
      placeholder="Administrative Area Level 3"
      type="text"
      disabled
      [value]="administrativeAreaLevel3"
    />
  </span>
</mat-form-field>
<mat-form-field style="width: 100%" hidden>
  <span>
    <input
      matInput
      placeholder="Administrative Area Level 1"
      type="text"
      disabled
      [value]="administrativeAreaLevel1"
    />
  </span>
</mat-form-field>

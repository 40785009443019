import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-gematik-task-api',
  template: `
    <p>
      gematik-task-api works!
    </p>
  `,
  styles: [
  ]
})
export class GematikTaskApiComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

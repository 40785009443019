import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MaterialModule } from "./material/material.module";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ReactiveFormsModule } from "@angular/forms";

@NgModule({
  providers: [TranslateService],
  declarations: [],
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: "never" }),
    MaterialModule,
  ],
  exports: [MaterialModule, TranslateModule, ReactiveFormsModule],
})
export class UtilsModule {}

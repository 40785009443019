<mat-expansion-panel
  [className]="hasError ? 'mat-expansion-panel error-class' : 'mat-expansion-panel'"
  [expanded]="expanded"
>
  <mat-expansion-panel-header>
    <mat-panel-title>
      <div class="d-flex align-items-center">
        <!-- <div class="d-flex align-items-center mr-5"> -->
        <ng-content select="[header]"></ng-content>
        <!-- </div> -->
        <div>{{ title | translate }}</div>
      </div>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <ng-content select="[body]"></ng-content>
</mat-expansion-panel>

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormArray, FormBuilder, UntypedFormGroup } from "@angular/forms";

export interface GemDocument {
  id: string;
  fileName: string;
  mimeType: string;
  file: File;
  downloadLink: string;
}

@Component({
  selector: "gem-documents",
  styleUrls: ["gem-documents.component.scss"],
  template: `
    <mat-card appearance="outlined"
      ><mat-card-header>
        <mat-card-title-group>
          <mat-card-title>
            <span>{{ title }}</span>
          </mat-card-title>
          <gem-file-upload
            *ngIf="!disabled"
            [placeholder]="'files'"
            [fileTypes]="fileUploadTypes"
            [control]="fileControl.get('file')"
            [multiple]="multiple"
            [showFileName]="false"
            (change)="onFileUpload($event)"
          >
          </gem-file-upload>
          <mat-card-subtitle *ngIf="helpText && documents?.length === 0">
            <div>{{ helpText }}</div>
          </mat-card-subtitle>
        </mat-card-title-group>
      </mat-card-header>

      <mat-card-content>
        <mat-chip-listbox #chipList aria-label="File Selection">
          <mat-chip-option
            data-testid="file"
            *ngFor="let file of documents?.controls"
            (click)="onDownloadFile(file)"
            (removed)="onRemoveFile(file)"
          >
            <img
              matChipAvatar
              src="/assets/icons/gem-file.svg"
              style="margin-left: 10px!important;"
            />
            <a
              [attr.href]="file.get('id').value ? file.get('downloadLink').value : null"
              target="_blank"
              >{{ file.get("fileName").value }}</a
            >

            <button matChipRemove *ngIf="!disabled">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip-option>
        </mat-chip-listbox>
        <div *ngIf="errorMsg" class="mat-error text-center mt-3">{{ errorMsg | translate }}</div>
      </mat-card-content>
    </mat-card>
  `,
})
export class GemDocumentsComponent implements OnInit {
  @Input() title: string;
  @Input() helpText: string;
  @Input() fileUploadTypes: string;
  @Input() documents: FormArray;
  @Input() multiple: boolean;
  @Input() disabled: boolean;
  @Input() errorMsg: string;

  @Output() upload: EventEmitter<FileList> = new EventEmitter();
  @Output() remove: EventEmitter<GemDocument> = new EventEmitter();

  fileControl: UntypedFormGroup = this.fb.group({
    file: [null],
  });

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {}

  onFileUpload(event): void {
    if (event) {
      this.upload.emit(event.target.files);
      this.fileControl.get("file").setValue(null);
    }
  }

  onRemoveFile(event): void {
    this.remove.emit(event);
  }

  onDownloadFile(event): void {
    const document: GemDocument = event.value;
    if (document.downloadLink) {
      window.open(document.downloadLink, "_blank");
    }
  }
}

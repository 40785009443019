import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { FormHelperService } from "../../helper/form-helper.service";

@Component({
  selector: "gem-input",
  templateUrl: "./gem-input.component.html",
  styleUrls: ["./gem-input.component.scss"],
})
export class GemInputComponent implements OnInit, OnDestroy {
  inputControl: UntypedFormControl;
  requiredErrorMessage: string;
  noValidNumberErrorMessage: string;
  langSubscription: Subscription;
  uniqueErrorMessage: string;

  @Input() placeholder: string;
  @Input() hint = "";
  @Input() type = "text";
  @Input() suffix = "";
  @Input() patternErrorMessage = "";
  @Input() delete: boolean;
  @Input() testid: string = "gemInput";
  @Input() maxlength: number;

  isRequired: boolean;

  @Input() set control(controlObj: UntypedFormControl) {
    this.inputControl = controlObj;
    this.isRequired = this.formHelper.checkRequiredValidator(this.inputControl);

    const placeholderInputValue = this.placeholder;
    const hintInputValue = this.hint;

    if (this.placeholder && this.placeholder !== "") {
      this.placeholder = this.translateService.instant(this.placeholder);
    }
    if (this.hint !== "") {
      this.hint = this.translateService.instant(this.hint);
    }

    this.langSubscription = this.translateService.onLangChange.subscribe(
      (event: LangChangeEvent) => {
        if (this.placeholder && this.placeholder !== "") {
          this.placeholder = this.translateService.instant(placeholderInputValue);
        }
        if (this.hint !== "") {
          this.hint = this.translateService.instant(hintInputValue);
        }
        this.requiredErrorMessage = this.formHelper.createRequiredErrorMessage(this.placeholder);

        if (!this.patternErrorMessage) {
          this.patternErrorMessage = this.formHelper.createPatternErrorMessageFromTranslationKey(
            this.placeholder ? this.placeholder : "field",
          );
        }

        this.noValidNumberErrorMessage = this.formHelper.createNoValidNumberErrorMessage(
          this.placeholder,
        );
        this.uniqueErrorMessage = this.formHelper.createUniqueErrorMessageFromTranslationKey(
          this.placeholder,
        );
      },
    );

    this.requiredErrorMessage = this.formHelper.createRequiredErrorMessage(this.placeholder);
    this.uniqueErrorMessage = this.formHelper.createUniqueErrorMessageFromTranslationKey(
      this.placeholder,
    );

    if (!this.patternErrorMessage) {
      this.patternErrorMessage = this.formHelper.createPatternErrorMessageFromTranslationKey(
        this.placeholder ? this.placeholder : "field",
      );
    }

    this.noValidNumberErrorMessage = this.formHelper.createNoValidNumberErrorMessage(
      this.placeholder,
    );
  }

  get control(): UntypedFormControl {
    return this.inputControl;
  }

  constructor(private translateService: TranslateService, private formHelper: FormHelperService) {}

  ngOnInit() {}

  trim() {
    this.formHelper.trimWhitespace(this.control);
  }

  ngOnDestroy() {
    if (this.langSubscription) {
      this.langSubscription.unsubscribe();
    }
  }
}

import { Component } from "@angular/core";

@Component({
  selector: "gem-loading-spinner",
  styleUrls: ["gem-loading-spinner.component.scss"],
  template: `<div class="backdrop"></div>
    <div class="loading-spinner">
      <div class="lds-gem">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>`,
})
export class GemLoadingSpinnerComponent {
  constructor() {}
}

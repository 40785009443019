import { Injectable } from "@angular/core";
import { GemDialogComponent } from "./gem-dialog/gem-dialog.component";
import { GemConfirmationDialogComponent } from "./gem-confirmation-dialog/gem-confirmation-dialog.component";
import { GemErrorDialogComponent } from "./gem-error-dialog/gem-error-dialog.component";
import { GemTextConfirmationDialogComponent } from "./gem-text-confirmation-dialog/gem-text-confirmation-dialog.component";
import { GemConfirmationErrorDialogComponent } from "./gem-confirmation-error-dialog/gem-confirmation-error-dialog.component";
import {
  GemServerErrorDialogComponent,
  GemServerError,
} from "./gem-server-error-dialog/gem-server-error-dialog.component";
import {
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable({
  providedIn: "root",
})
export class GemDialogService {
  constructor(private dialog: MatDialog, private snackBar: MatSnackBar) {}

  openDialog(title: string, text: string, type: string, closeAction?: any): void {
    const dialogRef = this.dialog.open(GemDialogComponent, {
      data: {
        title,
        text,
        type,
      },
    });
    if (closeAction) {
      dialogRef.afterClosed().subscribe((result) => {
        closeAction();
      });
    }
  }

  openLinkDialog(title: string, text: string, link: string, type: string) {
    this.dialog.open(GemDialogComponent, {
      data: {
        title,
        text,
        link,
        type,
      },
    });
  }

  openConfirmationDialog(
    title: string,
    text: string,
    confirmAction: any,
    cancelAction: any,
  ): MatDialogRef<GemConfirmationDialogComponent, any> {
    return this.dialog.open(GemConfirmationDialogComponent, {
      data: {
        title,
        text,
        confirmAction,
        cancelAction,
      },
    });
  }

  openErrorDialog(title: string, errors: Array<string>) {
    this.dialog.open(GemErrorDialogComponent, {
      data: {
        title,
        errors,
      },
    });
  }

  openConfirmationErrorDialog(title: string, errors: Array<string>, confirmAction: any) {
    this.dialog.open(GemConfirmationErrorDialogComponent, {
      data: {
        title,
        errors,
        confirmAction,
      },
    });
  }

  openConfirmationTextDialog(title: string, text: string, confirmationPlaceholderText: string) {
    // returns observable with confirmationText(string) or null if cancelled
    return this.dialog
      .open(GemTextConfirmationDialogComponent, {
        data: {
          title,
          text,
          confirmationPlaceholderText,
        },
      })
      .afterClosed();
  }

  openServerErrorDialog(error: GemServerError) {
    this.snackBar.openFromComponent(GemServerErrorDialogComponent, {
      // 30sec
      duration: 30 * 1000,
      data: error,
      verticalPosition: "bottom",
    });
  }
}

<label *ngIf="label">{{ (label | translate) + (isRequired ? " *" : "") }}</label
><br *ngIf="label" />
<mat-radio-group
  aria-label="Select an option"
  class="vertical-radio-group"
  [formControl]="inputControl"
  [disabled]="inputControl.disabled"
>
  <mat-radio-button value="{{ valueLabel.value }}" *ngFor="let valueLabel of valueLabels"
    ><label>{{ valueLabel.label | translate }}</label></mat-radio-button
  >
</mat-radio-group>

<gem-toolbar style="position: relative"></gem-toolbar>

<div class="background-grey mb-3 gib-flex-center">
  <router-outlet></router-outlet>
</div>

<div class="mt-3 mb-3 row gib-flex-center">
  <div class="col-12 max-width-1000 text-center">
    <gem-footer></gem-footer>
  </div>
</div>

import { Injectable } from "@angular/core";
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from "@ngrx/data";
import { Endpoint } from "projects/uwl-frontend/src/app/services/uwl/uwl.service";

@Injectable()
export class UwlEndpointEntityService extends EntityCollectionServiceBase<Endpoint> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super("Endpoint", serviceElementsFactory);
  }
}

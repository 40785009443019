import { Directive, ElementRef, HostListener, Input, AfterViewInit } from "@angular/core";

@Directive({
  selector: "[multiNumber]",
})
export class MultiNumberDirective implements AfterViewInit {
  @Input("multiNumber") options: { isNumber: boolean; isDecimal: boolean; maxValue: number };

  private specialKeys: Array<string> = [
    "Backspace",
    "Tab",
    "End",
    "Home",
    // "-",
    "ArrowLeft",
    "ArrowRight",
    "Del",
    "Delete",
  ];
  private regEx = undefined;

  constructor(private el: ElementRef) {}

  ngAfterViewInit(): void {
    if (this.options.isNumber) {
      this.regEx = /^([N]{1})([VB]{1})?$|^(0|[1-9]\d*)$/g;
    } else if (this.options.isDecimal) {
      this.regEx = /^([N]{1})([VB]{1})?$|(^100([.]0{1,2})?)$|(^\d{1,2}([,]\d{0,3})?)$/g;
    }
  }

  @HostListener("keydown", ["$event"])
  onKeyDown(event: KeyboardEvent) {
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    this.validateRegEx(event);
  }

  private validateRegEx(event) {
    let current: string = this.el.nativeElement.value;
    const next: string = current.concat(event.key);

    if (next && !String(next).match(this.regEx)) {
      event.preventDefault();
    } else {
      // There is a match, we need to check the maxValue in case of a number
      if (this.options.isNumber) {
        if (!isNaN(+next) && +next > this.options.maxValue) {
          event.preventDefault();
        }
      }
    }
  }
}

import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { Observable, Subscription, of } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { ValueLabel } from "../value-label";
import { FormHelperService } from "../../helper/form-helper.service";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";

@Component({
  selector: "gem-autocomplete-vl",
  styleUrls: ["gem-autocomplete-vl.component.scss"],
  template: `
    <form>
      <mat-form-field>
        <input
          #input
          type="text"
          matInput
          placeholder="{{ placeholder | translate }}"
          [formControl]="control"
          [matAutocomplete]="auto"
          [ngClass]="{ strikethrough: delete }"
          (input)="filter('input')"
          (focus)="filter('focus')"
          (focusout)="filter('focusout')"
        />
        <mat-autocomplete
          requireSelection
          #auto="matAutocomplete"
          [displayWith]="displayFn"
          (optionSelected)="onOptionSelected($event)"
        >
          @for (option of filteredOptions | async; track option) {
          <mat-option [value]="option">{{ option.label }}</mat-option>
          }
        </mat-autocomplete>
        <mat-error *ngIf="control.hasError('required')">{{ requiredErrorMessage }}</mat-error>
      </mat-form-field>
    </form>
  `,
})
export class GemAutocompleteVlComponent implements OnInit, OnDestroy {
  @Input() control: FormControl<string | ValueLabel>;
  @Input() options: ValueLabel[];
  @Input() placeholder: string;
  @Input() delete: boolean;

  @Output() changed: EventEmitter<{ value: ValueLabel; event: string }> = new EventEmitter();

  @ViewChild("input") input: ElementRef<HTMLInputElement>;

  requiredErrorMessage: string;

  subscriptions: Subscription[] = [];

  constructor(private formHelper: FormHelperService, private translateService: TranslateService) {}

  filteredOptions: Observable<ValueLabel[]>;

  ngOnInit() {
    this.filteredOptions = this.control.valueChanges.pipe(
      startWith(""),
      map((value) => {
        const name = typeof value === "string" ? value : value?.label;
        return name ? this._filter(name as string) : this.options.slice();
      }),
    );
    this.requiredErrorMessage = this.formHelper.createRequiredErrorMessage(
      this.translateService.instant(this.placeholder),
    );
    const sub = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.requiredErrorMessage = this.formHelper.createRequiredErrorMessage(
        this.translateService.instant(this.placeholder),
      );
    });
    this.subscriptions.push(sub);
  }

  displayFn(user: ValueLabel): string {
    return user && user.label ? user.label : "";
  }

  onOptionSelected(event): void {
    if (event && event.option && event.option.value && event.option.value) {
      this.changed.emit({ value: event.option.value, event: "optionSelected" });
    }
  }

  filter(event: string): void {
    const filterValue = this.input.nativeElement.value.toLowerCase();
    this.filteredOptions = of(this._filter(filterValue));
    if (!filterValue) {
      this.changed.emit({ value: null, event: "filter" });
    }
  }

  private _filter(name: string): ValueLabel[] {
    const filterValue = name.toLowerCase();
    return this.options.filter((option) => option.label.toLowerCase().includes(filterValue));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}

import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { UntypedFormControl, Validators } from "@angular/forms";
import { Observable, Subscription, of } from "rxjs";
import { ValueLabel } from "../value-label";
import { startWith, map } from "rxjs/operators";
import { FormHelperService } from "../../helper/form-helper.service";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";

@Component({
  selector: "gem-suggest-select",
  templateUrl: "./gem-suggest-select.component.html",
  styleUrls: ["./gem-suggest-select.component.scss"],
})
export class GemSuggestSelectComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  selectedValue: ValueLabel;
  inputControl = new UntypedFormControl();
  suggestControl = new UntypedFormControl();
  suggestOptions: ValueLabel[] = [];
  filteredOptions: Observable<ValueLabel[]>;
  requiredErrorMessage: string;
  langSubscription: Subscription;

  @Input() placeholder: string;
  @Input() hint = "";
  @Input() delete: boolean;

  isRequired: boolean;

  @Input() set options(options: ValueLabel[]) {
    this.suggestOptions = options;
    this.preSelectOption();
  }

  get options(): ValueLabel[] {
    return this.suggestOptions;
  }

  @Input() set control(controlObj: UntypedFormControl) {
    this.suggestControl = controlObj;
    if (controlObj.disabled) {
      this.inputControl.disable();
    }
    this.isRequired = this.formHelper.checkRequiredValidator(this.suggestControl);

    const placeholderInputValue = this.placeholder;
    const hintInputValue = this.hint;

    if (this.placeholder) {
      this.placeholder = this.translateService.instant(this.placeholder);
    }
    if (this.hint !== "") {
      this.hint = this.translateService.instant(this.hint);
    }

    this.langSubscription = this.translateService.onLangChange.subscribe(
      (event: LangChangeEvent) => {
        if (this.placeholder && this.placeholder !== "") {
          this.placeholder = this.translateService.instant(placeholderInputValue);
        }
        if (this.hint !== "") {
          this.hint = this.translateService.instant(hintInputValue);
        }
        this.requiredErrorMessage = this.formHelper.createRequiredErrorMessage(this.placeholder);
      },
    );

    this.requiredErrorMessage = this.formHelper.createRequiredErrorMessage(this.placeholder);

    if (this.suggestControl.value) {
      this.inputControl.disable({ emitEvent: false });
    }
    this.handleSuggestControl();
  }

  get control(): UntypedFormControl {
    return this.suggestControl;
  }

  constructor(private formHelper: FormHelperService, private translateService: TranslateService) {}

  ngAfterViewInit(): void {
    this.requiredErrorMessage = this.formHelper.createRequiredErrorMessage(this.placeholder);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.control && changes.control.currentValue && !changes.control.currentValue.value) {
      this.clearSelection();
    }
  }

  ngOnInit() {
    this.filteredOptions = this.inputControl?.valueChanges.pipe(
      startWith<string | ValueLabel>(""),
      map((value) => (typeof value === "string" ? value : value.label)),
      map((label) => (label ? this.filter(label) : this.suggestOptions?.slice())),
    );
    this.handleSuggestControl();
  }

  selected(event) {
    if (event && event.option && event.option.value && event.option.value.value) {
      this.suggestControl.setValue(event.option.value.value);
    }
  }

  displayFn(vl?: ValueLabel): string | undefined {
    return vl ? this.translateService.instant(vl.label) : undefined;
  }

  private filter(label: string): ValueLabel[] {
    const filterValue = label.toLowerCase();
    return this.suggestOptions.filter((option) => option.label.toLowerCase().includes(filterValue));
  }

  checkIfValid() {
    const hasRequiredValidator: boolean = this.suggestControl.hasValidator(Validators.required);
    if (hasRequiredValidator) {
      for (const option of this.suggestOptions) {
        if (option.value !== this.suggestControl?.value) {
          this.inputControl.setErrors({ required: true });
        }
      }
      this.isRequired = this.formHelper.checkRequiredValidator(this.suggestControl);
    }
  }

  clearSelection() {
    this.suggestControl.setValue(null);
    this.inputControl.setValue("");
    this.inputControl.enable();
  }

  preSelectOption() {
    if (
      this.suggestControl &&
      this.suggestControl?.value &&
      this.suggestControl?.value !== "" &&
      this.suggestOptions?.length > 0
    ) {
      for (const option of this.suggestOptions) {
        if (option.value === this.suggestControl?.value) {
          this.inputControl.setValue(option);
        }
      }
    } else if (
      this.suggestControl &&
      this.suggestControl?.value == null &&
      this.inputControl.disabled
    ) {
      this.clearSelection();
    }
  }

  handleSuggestControl() {
    this.suggestControl.valueChanges.subscribe((value) => {
      if (value) {
        this.inputControl.disable({ emitEvent: false });
      } else {
        this.inputControl.setValue("");
      }
    });
    this.suggestControl.registerOnDisabledChange((isDisabled) => {
      if (isDisabled) {
        this.inputControl.disable({ emitEvent: false });
      } else {
        this.inputControl.enable({ emitEvent: false });
      }
    });
  }

  ngOnDestroy() {
    if (this.langSubscription) {
      this.langSubscription.unsubscribe();
    }
  }
}

<mat-form-field>
  <mat-label>{{ placeholder }}</mat-label>
  <input
    type="text"
    matInput
    [matAutocomplete]="auto"
    [formControl]="inputControl"
    (blur)="checkIfValid()"
    [ngClass]="{ strikethrough: delete }"
  />
  <mat-icon
    *ngIf="suggestControl.value && !suggestControl.disabled"
    matSuffix
    (click)="clearSelection()"
    >delete</mat-icon
  >
  <mat-autocomplete
    #auto="matAutocomplete"
    [displayWith]="displayFn.bind(this)"
    (optionSelected)="selected($event)"
  >
    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
      {{ option.label | translate }}
    </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="inputControl.hasError('required')">{{ requiredErrorMessage }}</mat-error>
</mat-form-field>

import { Injectable, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { GuidedTour, Orientation } from "ngx-guided-tour";

@Injectable()
export class PortalGuidedTourService implements OnInit {
  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {}

  getGuidedTour(id: number): GuidedTour {
    const dashboardTour: GuidedTour = {
      tourId: "dashboard-tour",
      skipCallback: () => {},
      completeCallback: () => {},
      steps: [
        {
          title: this.translateService.instant("homePageTourStep1Title"),
          selector: "#guidedTour",
          content: this.translateService.instant("homePageTourStep1Content"),
          orientation: Orientation.Top,
          closeAction: () => {},
        },
        {
          title: this.translateService.instant("homePageTourStep1Title"),
          selector: "#feedback",
          content: this.translateService.instant("homePageTourStep1Content"),
          orientation: Orientation.Top,
          closeAction: () => {},
        },
      ],
    };
    switch (id) {
      case 1:
        return dashboardTour;
      default:
        return null;
    }
  }
}

import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface DialogData {
  title: string;
  errors: Array<string>;
}

@Component({
  selector: 'gem-error-dialog',
  templateUrl: './gem-error-dialog.component.html',
  styleUrls: ['./gem-error-dialog.component.scss'],
})
export class GemErrorDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<GemErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  closeDialog(): void {
    this.dialogRef.close();
  }
}

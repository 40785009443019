import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

export interface GemTimelineDialogData {
  title: string;
  legendOpen: string;
  legendCurrent: string;
  legendFinished: string;
  events: any[];
}

@Component({
  selector: "gem-timeline-dialog",
  styleUrls: ["gem-timeline-dialog.component.scss"],
  template: `
    <div mat-dialog-content class="mat-dialog-content">
      <div>
        <gem-timeline
          [value]="data.events"
          [title]="data.title ? (data.title | translate) : ('timeline_dialog_title' | translate)"
          [legendOpen]="
            data.legendOpen ? (data.legendOpen | translate) : ('legend_open_activity' | translate)
          "
          [legendCurrent]="
            data.legendCurrent
              ? (data.legendCurrent | translate)
              : ('legend_current_activity' | translate)
          "
          [legendFinished]="
            data.legendFinished
              ? (data.legendFinished | translate)
              : ('legend_finished_activity' | translate)
          "
        ></gem-timeline>
      </div>
    </div>
    <div mat-dialog-actions class="text-center mb-3">
      <button
        data-testid="confirm"
        class="mat-focus-indicator btn btn-primary mat-button mat-button-base"
        (click)="confirmAction()"
        cdkFocusInitial
      >
        {{ "timeline.buttons.close" | translate }}
      </button>
    </div>
  `,
})
export class GemTimelineDialogComponent implements OnInit, OnDestroy {
  constructor(
    public dialogRef: MatDialogRef<GemTimelineDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GemTimelineDialogData,
  ) {}

  dialogData: GemTimelineDialogData;

  ngOnInit(): void {}

  confirmAction(): void {
    this.dialogRef.close(true);
  }

  ngOnDestroy(): void {}
}

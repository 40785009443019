<h1 mat-dialog-title>Choose Address</h1>
<div mat-dialog-content>
    <mat-selection-list [multiple]="false">
        <mat-list-option *ngFor="let place of places" [value]="place"
            (click)="onAddressClicked(place)"
            (keyup.Space)="onAddressClicked(place)"
            (keyup.enter)="onAddressClicked(place)">
            {{ place.formatted_address }}
        </mat-list-option>
    </mat-selection-list>
</div>
import { ActionReducerMap, createFeatureSelector } from "@ngrx/store";

import * as fromTasks from "./task.reducer";
import * as fromProcesses from "./process.reducer";
import * as fromEndpoints from "./endpoint.reducer";
import * as fromIncidents from "./incident.reducer";
import * as fromTemplates from "./template.reducer";
import * as fromHelp from "./help.reducer";
import * as fromTaskTotals from "./task-totals.reducer";
import * as fromProcessTotals from "./process-totals.reducer";
import * as fromProfile from "./profile.reducer";
import * as fromMyServices from "./my-services.reducer";
import * as fromQuickActions from "./quick-action.reducer";
import * as fromOrganisationalProfile from "./organisation-profile.reducer";
import * as fromPersonalProfile from "./personal-profile.reducer";
import * as fromAlerts from "./alert.reducer";
import * as fromRights from "./my-rights.reducer";
import * as fromSharedStore from "gematik-shared";

export interface UwlState {
  [fromTasks.OPEN_FEATURE_KEY]: fromTasks.TaskState;
  [fromProcesses.ACTIVE_FEATURE_KEY]: fromProcesses.ProcessState;
  [fromTasks.COMPLETED_FEATURE_KEY]: fromTasks.TaskState;
  [fromProcesses.COMPLETED_FEATURE_KEY]: fromProcesses.ProcessState;
  [fromIncidents.FEATURE_KEY]: fromIncidents.IncidentState;
  [fromEndpoints.FEATURE_KEY]: fromEndpoints.EndpointState;
  [fromTemplates.FEATURE_KEY]: fromTemplates.TemplateState;
  [fromHelp.FEATURE_KEY]: fromHelp.HelpState;
  [fromSharedStore.GROUPS_FEATURE_KEY]: fromSharedStore.EntraIdGroupsState;
  [fromTaskTotals.FEATURE_KEY]: fromTaskTotals.TaskTotalsState;
  [fromProcessTotals.FEATURE_KEY]: fromProcessTotals.ProcessTotalsState;
  [fromProfile.FEATURE_KEY]: fromProfile.State;
  [fromMyServices.FEATURE_KEY]: fromMyServices.MyServicesState;
  [fromQuickActions.FEATURE_KEY]: fromQuickActions.QuickActionState;
  [fromOrganisationalProfile.FEATURE_KEY]: fromOrganisationalProfile.OrganisationProfileState;
  [fromPersonalProfile.FEATURE_KEY]: fromPersonalProfile.PersonalProfileState;
  [fromAlerts.FEATURE_KEY]: fromAlerts.AlertState;
  [fromRights.FEATURE_KEY]: fromRights.MyRightState;
}

export const reducers: ActionReducerMap<UwlState> = {
  [fromTasks.OPEN_FEATURE_KEY]: fromTasks.taskOpenReducer,
  [fromProcesses.ACTIVE_FEATURE_KEY]: fromProcesses.processActiveReducer,
  [fromTasks.COMPLETED_FEATURE_KEY]: fromTasks.taskCompletedReducer,
  [fromProcesses.COMPLETED_FEATURE_KEY]: fromProcesses.processCompletedReducer,
  [fromIncidents.FEATURE_KEY]: fromIncidents.reducer,
  [fromEndpoints.FEATURE_KEY]: fromEndpoints.reducer,
  [fromTemplates.FEATURE_KEY]: fromTemplates.reducer,
  [fromHelp.FEATURE_KEY]: fromHelp.reducer,
  [fromSharedStore.GROUPS_FEATURE_KEY]: fromSharedStore.entraIdGroupReducer,
  [fromTaskTotals.FEATURE_KEY]: fromTaskTotals.reducer,
  [fromProcessTotals.FEATURE_KEY]: fromProcessTotals.reducer,
  [fromProfile.FEATURE_KEY]: fromProfile.reducer,
  [fromMyServices.FEATURE_KEY]: fromMyServices.reducer,
  [fromQuickActions.FEATURE_KEY]: fromQuickActions.reducer,
  [fromOrganisationalProfile.FEATURE_KEY]: fromOrganisationalProfile.reducer,
  [fromPersonalProfile.FEATURE_KEY]: fromPersonalProfile.reducer,
  [fromAlerts.FEATURE_KEY]: fromAlerts.reducer,
  [fromRights.FEATURE_KEY]: fromRights.reducer,
};

export const getUwlStore = createFeatureSelector<UwlState>("uwl");

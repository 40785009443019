import { Injectable } from "@angular/core";
import jwtDecode from "jwt-decode";

@Injectable({
  providedIn: "root",
})
export class TokenHelperService {
  constructor() {}

  saveToken(token: string) {
    localStorage.setItem("token", token);
  }
  getToken(): string {
    return localStorage.getItem("token");
  }

  decodeToken(): any {
    return jwtDecode(this.getToken());
  }

  getRoles(): string[] {
    return this.decodeToken().roles !== undefined ? this.decodeToken().roles : [];
  }

  getGroups(): string[] {
    return this.decodeToken().groups !== undefined ? this.decodeToken().groups : [];
  }

  getFullname(): string {
    let token = this.decodeToken();
    return token.given_name + " " + token.family_name;
  }

  getUsername(): string {
    return this.decodeToken().oid;
  }

  getEmail(): string {
    const unique_name: string = this.decodeToken().unique_name;
    const email: string = unique_name.substring(unique_name.indexOf("#") + 1);
    return email;
  }

  getFirstname(): string {
    return this.decodeToken().given_name;
  }

  getLastname(): string {
    return this.decodeToken().family_name;
  }

  containsRole(rights: string): boolean {
    const rolesArr: string[] = rights.split(",");
    const groupsArr: string[] = rights.split(",");
    return (
      rolesArr.some((r) => this.getRoles().includes(r)) ||
      groupsArr.some((g) => this.getGroups().includes(g))
    );
  }

  isLoggedIn(): boolean {
    if (this.getToken()) {
      return true;
    }
    return false;
  }

  isUserInternal(): boolean {
    const decodedToken = this.decodeToken();
    if (decodedToken.unique_name) {
      const uniqueName: string = decodedToken.unique_name;
      if (
        uniqueName.includes("@gematik.de") ||
        uniqueName.includes("@thomasdonathgematik.onmicrosoft.com")
      ) {
        return true;
      }
      return false;
    }
    return false;
  }

  isExpired(): boolean {
    try {
      const decodeToken = this.decodeToken();
      const expirationDate = new Date(new Date(0).setUTCSeconds(decodeToken.exp));
      if (new Date() < expirationDate) {
        return false;
      }
      return true;
    } catch (error) {
      return true;
    }
  }

  logout() {
    localStorage.removeItem("token");
  }
}

import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'gem-google-map',
  templateUrl: './gem-google-map.component.html',
  styleUrls: ['./gem-google-map.component.css']
})
export class GemGoogleMapComponent implements OnInit, OnChanges {

  @Input() apiKey: string;
  @Input() language: string;
  @Input() lat: string;
  @Input() lng: string;
  @Input() mapHeight: string;

  safeUrl: SafeUrl;

  constructor(private sanitizer: DomSanitizer) { }
  
  ngOnInit(): void {
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`https://www.google.com/maps/embed/v1/place?key=${this.apiKey}&q=${this.lat},${this.lng}&language=${this.language}`);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`https://www.google.com/maps/embed/v1/place?key=${this.apiKey}&q=${this.lat},${this.lng}&language=${this.language}`);
  }

}

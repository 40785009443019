import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { LoadingService } from "../services/loading.service";

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  counter: number = 0;

  constructor(private loadingService: LoadingService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.counter++;

    this.loadingService.show();

    return next.handle(request).pipe(
      finalize(() => {
        this.counter--;
        if (this.counter === 0) {
          this.loadingService.hide();
        }
      }),
    );
  }
}

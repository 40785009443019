<mat-form-field>
  <mat-label>{{ placeholder }}</mat-label>
  <mat-select [formControl]="inputControl" [disabled]="disabled">
    <mat-select-trigger [ngClass]="{ strikethrough: delete }">
      {{ getOptionLabel(inputControl.value) }}
    </mat-select-trigger>

    <mat-option *ngFor="let option of options" [value]="option.value" [disabled]="option.disabled">
      {{ option.label | translate }}
    </mat-option>
  </mat-select>
  <mat-hint>{{ hint }}</mat-hint>
  <mat-error *ngIf="inputControl.hasError('required')">{{ requiredErrorMessage }}</mat-error>
</mat-form-field>

import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { FormHelperService } from "../../helper/form-helper.service";

@Component({
  selector: "gem-radio-button-group-vertical-orange",
  styleUrls: ["gem-radio-button-group-vertical-orange.component.scss"],
  template: `<label *ngIf="label">{{ (label | translate) + (isRequired ? " *" : "") }}</label
    ><br *ngIf="label" />
    <mat-radio-group
      aria-label="Select an option"
      class="vertical-radio-group"
      [formControl]="inputControl"
      [disabled]="inputControl.disabled"
    >
      <mat-radio-button value="{{ valueLabel.value }}" *ngFor="let valueLabel of valueLabels"
        ><label>{{ valueLabel.label | translate }}</label></mat-radio-button
      >
    </mat-radio-group> `,
})
export class GemRadioButtonGroupVerticalOrangeComponent implements OnInit {
  inputControl: UntypedFormControl;

  requiredErrorMessage: string;
  isRequired: boolean;

  @Input() label;
  @Input() valueLabels;

  @Input() set control(controlObj: UntypedFormControl) {
    this.inputControl = controlObj;

    this.isRequired = this.formHelper.checkRequiredValidator(this.inputControl);
  }

  get control(): UntypedFormControl {
    return this.inputControl;
  }

  constructor(private translateService: TranslateService, private formHelper: FormHelperService) {}

  ngOnInit() {}
}

import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { DeviceDetectorService } from "ngx-device-detector";
// import { ValueLabel } from "projects/gematik-form-library/src/lib/gem-components/value-label";
// import { Observable, throwError } from "rxjs";
// import { catchError } from "rxjs/operators";
// import { GemValidators } from "../../../helper/gem-validators.service";

export interface HttpConfig {
  body?: any;
  headers?: HttpHeaders;
  observe?: any;
}

export interface GemFeedbackDialogData {
  title: string;
  text: string;
  backendUrl: string;
  application: string;
  component: string;
}

@Component({
  selector: "gem-feedback-dialog",
  templateUrl: "./gem-feedback-dialog.component.html",
  styleUrls: ["./gem-feedback-dialog.component.scss"],
})
export class GemFeedbackDialogComponent implements OnInit {
  // categories: ValueLabel[] = [
  //   { value: "GENERAL", label: this.translateService.instant("feedbackOptionGeneral") },
  //   {
  //     value: "FEATURE",
  //     label: this.translateService.instant("feedbackOptionFeature"),
  //   },
  //   { value: "BUG", label: this.translateService.instant("feedbackOptionBug") },
  // ];

  // form: UntypedFormGroup;
  // rating = 0;

  constructor(
    public dialogRef: MatDialogRef<GemFeedbackDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GemFeedbackDialogData,
    private fb: UntypedFormBuilder,
    private http: HttpClient,
    private snackBar: MatSnackBar,
    public translateService: TranslateService,
    private deviceService: DeviceDetectorService,
  ) {}

  ngOnInit(): void {
    // this.initForm();
  }

  // onSubmit(): void {
  //   this.createFeedback().subscribe(
  //     (res) => {
  //       this.snackBar.open(this.translateService.instant("feedbackSuccessMessage"), "", {
  //         duration: 3 * 1000,
  //         horizontalPosition: "center",
  //         verticalPosition: "top",
  //       });
  //     },
  //     (err) => {
  //       this.snackBar.open(this.translateService.instant("feedbackErrorMessage"), "", {
  //         duration: 3 * 1000,
  //         horizontalPosition: "center",
  //         verticalPosition: "top",
  //       });
  //     },
  //   );

  //   this.dialogRef.close();
  // }

  // onCancel(): void {
  //   this.dialogRef.close();
  // }

  // getCategories(): ValueLabel[] {
  //   return this.categories;
  // }

  // isFormValid(): Boolean {
  //   return this.form.valid && this.rating > 0;
  // }

  // private initForm(): void {
  //   this.form = this.fb.group({
  //     category: ["GENERAL"],
  //     email: ["", Validators.compose([GemValidators.email])],
  //     comment: [""],
  //   });
  // }

  // private createFeedback(): Observable<HttpConfig> {
  //   const metadata = {
  //     ...this.deviceService.getDeviceInfo(),
  //     isMobile: this.deviceService.isMobile(),
  //     isTablet: this.deviceService.isTablet(),
  //     isDesktop: this.deviceService.isDesktop(),
  //   };

  //   const payload = {
  //     rating: this.rating,
  //     email: this.form.get("email").value,
  //     comment: this.form.get("comment").value,
  //     application: this.data.application,
  //     component: this.data.component,
  //     metadata: JSON.stringify(metadata),
  //     categoryName: this.form.get("category").value,
  //   };
  //   return this.http.post(`${this.data.backendUrl}/public/feedback`, payload).pipe(
  //     catchError((error: HttpErrorResponse) => {
  //       return throwError(error);
  //     }),
  //   );
  // }
}

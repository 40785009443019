import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, Inject } from '@angular/core';
import {
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA,
} from '@angular/material/snack-bar';

export class GemServerError {
  errorCode: string;
  errorInfos: Array<any>;
  date: Date;
  id: string;
}

@Component({
  selector: 'gem-server-error-dialog',
  templateUrl: './gem-server-error-dialog.component.html',
  styleUrls: ['./gem-server-error-dialog.component.scss'],
  animations: [
    trigger('rotatedState', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(90deg)' })),
      transition('rotated => default', animate('300ms ease-out')),
      transition('default => rotated', animate('300ms ease-in')),
    ]),
    trigger('openClose', [
      state('true', style({ height: '*', padding: '5px' })),
      state('false', style({ height: '0px', padding: '0' })),
      transition('false <=> true', animate(300)),
    ]),
  ],
})
export class GemServerErrorDialogComponent {
  detailsExpanded = false;
  state = 'default';

  constructor(
    private snackBarRef: MatSnackBarRef<GemServerErrorDialogComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: GemServerError
  ) {}

  toggleDetails() {
    this.detailsExpanded = !this.detailsExpanded;
    this.detailsExpanded ? (this.state = 'rotated') : (this.state = 'default');
  }

  close() {
    this.snackBarRef.dismiss();
  }
}

<mat-toolbar>
  <mat-toolbar-row>
    <div class="logo-wrapper" routerLink="/">
      <img src="/gematik-shared/assets/images/logo/gematik.png" class="custom-logo" alt="gematik" />
      {{ "portal_app_title" | translate }}
    </div>
    <span class="toolbar-spacer"></span>
    <div class="profile-div" *ngIf="false">
      <mat-icon class="material-icons nav-icons" svgIcon="gem-users"></mat-icon>
      <span *ngIf="!username" class="username" (click)="onSignInClick()"
        >&nbsp;&nbsp;{{ "sign_in" | translate }}</span
      >
      <span *ngIf="username" class="username" [matMenuTriggerFor]="profileMenu"
        >&nbsp;&nbsp;{{ this.tokenHelperService.getFullname() }}</span
      >
    </div>
    <div *ngIf="false">
      <a class="language-toggle" (click)="onLangClick()">|&nbsp;{{ lang }}</a>
    </div>
    <mat-menu #profileMenu="matMenu">
      <button id="logout-button" mat-menu-item (click)="onSignOutClick()">
        {{ "logout" | translate }}
      </button>
    </mat-menu>
  </mat-toolbar-row>
</mat-toolbar>

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { HttpConfig } from "../http-config";
import { Observable } from "rxjs";
import { EnvService } from "gematik-form-library";

export interface Endpoint {
  id: number;
  name: string;
  url: string;
  roles: string;
  uuid: string;
}

export interface CrmRight {
  id: string;
  name: string;
}

export class UwlHelpConfig {
  id: number;
  helpkey: string;
  lang: string;
  title: string;
  html: string;

  constructor() {
    this.id = null;
    this.helpkey = "";
    this.lang = "";
    this.title = "";
    this.html = "";
  }
}

@Injectable({
  providedIn: "root",
})
export class UwlService {
  httpConfig: HttpConfig = {
    headers: new HttpHeaders(),
    observe: "response",
  };

  constructor(
    private http: HttpClient,
    private formBuilder: UntypedFormBuilder,
    private env: EnvService,
  ) {}

  createUpdateEndpoint(endpoint: Endpoint): Observable<HttpConfig> {
    return this.http.post(this.env.backendUrl + "/uwl/endpoint", endpoint, this.httpConfig);
  }

  deleteEndpoint(id: number): Observable<HttpConfig> {
    return this.http.delete(this.env.backendUrl + "/uwl/endpoint/" + id, this.httpConfig);
  }

  findAllEndpoints(): Observable<HttpConfig> {
    return this.http.get(this.env.backendUrl + "/uwl/endpoint", this.httpConfig);
  }

  createUpdateHelpConfig(config: UwlHelpConfig): Observable<HttpConfig> {
    return this.http.post(this.env.backendUrl + "/uwl/help-config", config, this.httpConfig);
  }

  deleteHelpConfig(config: UwlHelpConfig): Observable<HttpConfig> {
    return this.http.delete(this.env.backendUrl + "/uwl/help-config/" + config.id, this.httpConfig);
  }

  findHelpConfig(id: string, lang: string): Observable<HttpConfig> {
    return this.http.get(
      this.env.backendUrl + "/uwl/help-config/" + id + "/" + lang,
      this.httpConfig,
    );
  }

  findAllHelpConfigs(): Observable<HttpConfig> {
    return this.http.get(this.env.backendUrl + "/uwl/help-config", this.httpConfig);
  }

  mapUwlEndpointToForm(endpoint: Endpoint) {
    if (endpoint !== null) {
      return this.formBuilder.group(endpoint);
    } else {
      const object: Endpoint = {
        id: -1,
        url: "",
        name: "",
        roles: "",
        uuid: "",
      };
      return this.formBuilder.group(object);
    }
  }

  mapFormToUwlHelpConfig(form: UntypedFormGroup) {
    return form.getRawValue();
  }

  mapUwlHelpConfigToForm(config: UwlHelpConfig) {
    if (config !== null) {
      return this.formBuilder.group(config);
    } else {
      return this.formBuilder.group(new UwlHelpConfig());
    }
  }

  mapFormToUwlEndpoint(form: UntypedFormGroup) {
    return form.getRawValue();
  }

  getTemplates(): Observable<HttpConfig> {
    return this.http.get(this.env.notificationsBackendUrl + "/templates", this.httpConfig);
  }

  getCrmRights(email: string): Observable<HttpConfig> {
    return this.http.get(`${this.env.backendUrl}/uwl/crm/rights/${email}`, this.httpConfig);
  }
}

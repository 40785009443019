import { ViewportScroller } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { GemFeedbackComponent } from "gematik-shared";
import { EnvService } from "gematik-form-library";
import { Subscription } from "rxjs";

@Component({
  selector: "app-legal-notice",
  templateUrl: "./legal-notice.component.html",
  styleUrls: ["./legal-notice.component.scss"],
})
export class LegalNoticeComponent implements OnInit {
  lang: string;
  langSub: Subscription;
  routerSub: Subscription;

  constructor(
    private readonly viewport: ViewportScroller,
    private translate: TranslateService,
    private env: EnvService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.translate
      .get(`languages.${this.translate.currentLang === "en" ? "de" : "en"}`)
      .subscribe((res: string) => {
        this.lang = res;
      })
      .unsubscribe();

    this.langSub = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.translate
        .get(`languages.${event.lang === "en" ? "de" : "en"}`)
        .subscribe((res: string) => {
          this.lang = res;
        })
        .unsubscribe();
    });
  }

  onScrollToTop(): void {
    this.viewport.scrollToPosition([0, 0]);
  }

  onFeedback(): void {
    this.dialog.open(GemFeedbackComponent, {
      data: {
        backendUrl: this.env.feedbackBackendUrl,
        application: "Portal",
        component: "legal-notice",
      },
      width: "500px",
    });
  }

  ngOnDestroy() {
    if (this.langSub) {
      this.langSub.unsubscribe();
    }
    if (this.routerSub) {
      this.routerSub.unsubscribe();
    }
  }
}

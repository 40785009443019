import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { EnvService } from "gematik-form-library";
import { TokenHelperService } from "gematik-form-library";
import { Endpoint } from "projects/uwl-frontend/src/app/services/uwl/uwl.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { TaskCountEntityService } from "../services/task-count-entity.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  myTasksCount: number = 0;
  groupTasksCount: number = 0;
  uwlUrl: string = this.env.uwlUrl;
  registrationUrl: string = this.env.registrationUrl;
  boardingUrl: string = this.env.boardingUrl;

  taskGroupCount$: Observable<number>;
  taskMyCount$: Observable<number>;

  uwlEndpoints$: Observable<Endpoint[]>;

  constructor(
    private tokenHelperService: TokenHelperService,
    private env: EnvService,
    private taskCountService: TaskCountEntityService,
    private httpClient: HttpClient,
    private _snackBar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    this.taskGroupCount$ = this.taskCountService.entities$.pipe(
      map((tasks) => {
        let count = 0;
        tasks.forEach((task) => {
          count = count + task.group;
        });
        return count;
      }),
    );
    this.taskMyCount$ = this.taskCountService.entities$.pipe(
      map((tasks) => {
        let count = 0;
        tasks.forEach((task) => {
          count = count + task.my;
        });
        return count;
      }),
    );
  }

  public get isLoggedIn() {
    try {
      return this.tokenHelperService.isLoggedIn();
    } catch (error) {
      return false;
    }
  }

  startSLR(): void {
    fetch("./assets/json/slr.json")
      .then((res) => res.json())
      .then((json) => {
        this.httpClient
          .post("https://slr-api.pip.dev.gematik.solutions/public/slr/startReviewProcesses", json)
          .subscribe((res) => {
            this._snackBar.open("SLR started successfully", "", {
              duration: 2 * 1000,
              horizontalPosition: "center",
              verticalPosition: "top",
            });
          });
      });
  }
}

<div class="row mt-1" *ngIf="false">
  <div class="col-sm-12">
    <div class="row" *ngIf="isLoggedIn">
      <div class="col-sm-2 mt-3" *ngIf="isLoggedIn">
        <div class="card">
          <div class="card-header">{{ "activity_monitor" | translate }}</div>
          <div class="card-body">
            <mat-icon class="material-icons nav-icons" svgIcon="gem-users"></mat-icon>
            <h5 class="card-title">
              {{ "my_activities" | translate }}: {{ taskMyCount$ | async }}
            </h5>
            <mat-icon class="material-icons nav-icons" svgIcon="gem-users"></mat-icon>
            <h5 class="card-title">
              {{ "group_activities" | translate }}: {{ taskGroupCount$ | async }}
            </h5>
            <p class="card-text"></p>
            <div class="link-button">
              <a [href]="uwlUrl" target="_blank" class="btn btn-primary center">{{
                "open_activity_overview" | translate
              }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row mt-1" *ngIf="false">
  <div class="col-sm-12">
    <div class="row">
      <div class="col-sm-2" *ngIf="!isLoggedIn">
        <div class="card mt-3 mb-2">
          <div class="card-header">{{ "register" | translate }}</div>
          <div class="card-body">
            <span class="material-icons gem-link">open_in_new</span>
            <a [href]="registrationUrl" target="_blank">
              <h5 class="card-title">{{ "register" | translate }}</h5>
            </a>
            <p class="card-text">{{ "register_text" | translate }}</p>
          </div>
        </div>
      </div>
      <div class="col-sm-2" *ngIf="isLoggedIn">
        <div class="card">
          <div class="card-header">{{ "self_service" | translate }}</div>
          <div class="card-body">
            <span class="material-icons gem-link">open_in_new</span>
            <a href="{{ registrationUrl }}/my-profile" target="_blank">
              <h5 class="card-title">{{ "manage_my_profile" | translate }}</h5>
            </a>
            <p class="card-text">{{ "manage_my_profile_text" | translate }}</p>
          </div>
        </div>
      </div>
      <div class="col-sm-2" *ngIf="isLoggedIn">
        <div class="card">
          <div class="card-header">{{ "self_service" | translate }}</div>
          <div class="card-body">
            <span class="material-icons gem-link">open_in_new</span>
            <a href="{{ registrationUrl }}/partner-profile" target="_blank">
              <h5 class="card-title">{{ "manage_my_partner_profile" | translate }}</h5>
            </a>
            <p class="card-text">{{ "manage_my_partner_profile_text" | translate }}</p>
          </div>
        </div>
      </div>
      <div class="col-sm-2" *ngIf="isLoggedIn">
        <div class="card">
          <div class="card-header">{{ "self_service" | translate }}</div>
          <div class="card-body">
            <span class="material-icons gem-link">open_in_new</span>
            <a href="{{ registrationUrl }}/my-profile" target="_blank">
              <h5 class="card-title">{{ "request_new_partner_role" | translate }}</h5>
            </a>
            <p class="card-text">{{ "request_new_partner_role_text" | translate }}</p>
          </div>
        </div>
      </div>
      <div class="col-sm-2" *ngIf="isLoggedIn">
        <div class="card">
          <div class="card-header">{{ "self_service" | translate }}</div>
          <div class="card-body">
            <span class="material-icons gem-link">open_in_new</span>
            <a href="{{ boardingUrl }}/onboarding/-1" target="_blank">
              <h5 class="card-title">{{ "start_onboarding" | translate }}</h5>
            </a>
            <p class="card-text">{{ "start_onboarding_text" | translate }}</p>
          </div>
        </div>
      </div>
      <div class="col-sm-2" *ngIf="isLoggedIn">
        <div class="card">
          <div class="card-header">{{ "self_service" | translate }}</div>
          <div class="card-body">
            <span class="material-icons gem-link">open_in_new</span>
            <a href="{{ boardingUrl }}/offboarding/-1" target="_blank">
              <h5 class="card-title">{{ "start_offboarding" | translate }}</h5>
            </a>
            <p class="card-text">{{ "start_offboarding_text" | translate }}</p>
          </div>
        </div>
      </div>

      <div class="col-sm-2" *ngIf="isLoggedIn">
        <div class="card">
          <div class="card-header">SLR Helper</div>
          <div class="card-body">
            <div>
              <button type="button" class="btn btn-primary center" (click)="startSLR()">
                Initiate Process
              </button>
            </div>
            <div>
              <a href="assets/json/slr.json" class="btn btn-primary center" target="_blank"
                >Download JSON</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row mt-1" *ngIf="false">
  <div class="col-sm-12">
    <div class="row" *ngIf="isLoggedIn">
      <div class="col-sm-6 mb-3" *ngIf="isLoggedIn">
        <div class="card">
          <div class="card-header">{{ "system_access" | translate }}</div>
          <div class="card-body">
            <span class="material-icons gem-link">open_in_new</span>
            <a href="https://www.gemmunity.de" target="_blank">
              <h5 class="link-title">{{ "gemmunity" | translate }}</h5>
            </a>
            <p class="card-text">&nbsp;- {{ "gemmunity_text" | translate }}</p>
            <br />
            <span class="material-icons gem-link">open_in_new</span>
            <a href="https://service.gematik.de" target="_blank">
              <h5 class="link-title">{{ "jira" | translate }}</h5>
            </a>
            <p class="card-text">&nbsp;- {{ "jira_text" | translate }}</p>
            <br />
            <span class="material-icons gem-link">open_in_new</span>
            <a href="https://www.ti-itsm.de" target="_blank">
              <h5 class="link-title">{{ "itsm" | translate }}</h5>
            </a>
            <p class="card-text">&nbsp;- {{ "itsm_text" | translate }}</p>
            <br />
            <span class="material-icons gem-link">open_in_new</span>
            <a href="https://www.ti-itsm.de" target="_blank">
              <h5 class="link-title">{{ "wissensdatenbank" | translate }}</h5>
            </a>
            <p class="card-text">&nbsp;- {{ "wissensdatenbank_text" | translate }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="mt-1">
  <div class="d-flex justify-content-center pt-5">
    <img src="/assets/icons/myServices_Blau_gematik.svg" width="50%" height="50%" />
  </div>
  <div class="d-flex justify-content-center mt-3 pb-5">
    <h1>{{ "comingSoon" | translate }}</h1>
  </div>
</div>

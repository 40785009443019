import { NgModule } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CommonModule } from "@angular/common";
import { TaskTableComponent } from "./task-table/task-table.component";
import { GemBpmnDiagramComponent } from "./gem-bpmn-diagram/gem-bpmn-diagram.component";
import { CompletedTaskTableComponent } from "./completed-task-table/completed-task-table.component";
import { MatPaginatorIntl, MatPaginatorModule } from "@angular/material/paginator";
import { MatTableModule } from "@angular/material/table";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { MatTabsModule } from "@angular/material/tabs";
import { MatInputModule } from "@angular/material/input";
import { TranslateModule, TranslateService } from "@ngx-translate/core";

import { ProcessesTableComponent } from "./processes-table/processes-table.component";
import { EndpointTableComponent } from "./endpoint-table/endpoint-table.component";
import { EndpointFormComponent } from "./endpoint-form/endpoint-form.component";
import { MatCardModule } from "@angular/material/card";
import { MatTooltipModule } from "@angular/material/tooltip";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { GemComponentsModule } from "gematik-form-library";
import { MaterialPaginatorI18n } from "gematik-form-library";
import { GemSimpleTaskDialogComponent } from "./gem-simple-task-dialog/gem-simple-task-dialog.component";
import { MatSortModule } from "@angular/material/sort";
import { GemBpmnDialogComponent } from "./gem-bpmn-dialog/gem-bpmn-dialog.component";
import { MatIconModule } from "@angular/material/icon";
import { HighlightSearchPipe } from "../../services/highlight-table-search-pipe";
import { GemLockingDialogComponent } from "./completed-task-table/gem-locking-dialog/gem-locking-dialog.component";
import { GemHtmlEditDialogComponent } from "./gem-html-edit-dialog/gem-html-edit-dialog.component";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { HelpConfigFormComponent } from "./help-config-form/help-config-form.component";
import { HelpConfigTableComponent } from "./help-config-table/help-config-table.component";
import { GemHtmlDisplayDialogComponent } from "./gem-html-display-dialog/gem-html-display-dialog.component";
import { GemDefaultTaskDialogComponent } from "./gem-default-task-dialog/gem-default-task-dialog.component";
import { GemExtendedTaskDialogComponent } from "./gem-extended-task-dialog/gem-extended-task-dialog.component";
import { TemplatesTableComponent } from "./templates-table/templates-table.component";
import { TemplatesFormComponent } from "./templates-form/templates-form.component";
import { IncidentsTableComponent } from "./incidents-table/incidents-table.component";
import { GemBpmnIncidentDialogComponent } from "./gem-bpmn-incident-dialog/gem-bpmn-incident-dialog.component";
import { CompletedTaskDetailsDialogComponent } from "./completed-task-table/completed-task-details-dialog/completed-task-details-dialog.component";
import { MatMenuModule } from "@angular/material/menu";
import { MatChipsModule } from "@angular/material/chips";
import { SharedModule } from "../../shared/shared.module";

@NgModule({
  declarations: [
    TaskTableComponent,
    CompletedTaskTableComponent,
    ProcessesTableComponent,
    EndpointTableComponent,
    EndpointFormComponent,
    GemSimpleTaskDialogComponent,
    GemDefaultTaskDialogComponent,
    GemExtendedTaskDialogComponent,
    GemBpmnDiagramComponent,
    GemBpmnDialogComponent,
    GemLockingDialogComponent,
    GemHtmlEditDialogComponent,
    HelpConfigFormComponent,
    HelpConfigTableComponent,
    GemHtmlDisplayDialogComponent,
    TemplatesTableComponent,
    TemplatesFormComponent,
    IncidentsTableComponent,
    GemBpmnIncidentDialogComponent,
    CompletedTaskDetailsDialogComponent,
  ],
  imports: [
    GemComponentsModule,
    CommonModule,
    MatBottomSheetModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    MatFormFieldModule,
    MatTabsModule,
    MatInputModule,
    MatCardModule,
    MatMenuModule,
    TranslateModule,
    MatTooltipModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    MatDialogModule,
    MatSnackBarModule,
    MatIconModule,
    AngularEditorModule,
    MatChipsModule,
    SharedModule,
  ],
  exports: [
    TaskTableComponent,
    CompletedTaskTableComponent,
    ProcessesTableComponent,
    MatBottomSheetModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    MatFormFieldModule,
    MatTabsModule,
    MatInputModule,
    TranslateModule,
    EndpointTableComponent,
    EndpointFormComponent,
    HelpConfigTableComponent,
    HelpConfigFormComponent,
    GemSimpleTaskDialogComponent,
    GemDefaultTaskDialogComponent,
    GemExtendedTaskDialogComponent,
    GemBpmnDiagramComponent,
    TemplatesTableComponent,
    TemplatesFormComponent,
    IncidentsTableComponent,
  ],
  providers: [
    {
      provide: MatPaginatorIntl,
      deps: [TranslateService],
      useClass: MaterialPaginatorI18n,
    },
  ],
})
export class ComponentsModule {}

import { Subscription } from "rxjs";
import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  AfterViewInit,
  EventEmitter,
  Output,
} from "@angular/core";
import { FormControl, UntypedFormControl } from "@angular/forms";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
import { FormHelperService } from "../../helper/form-helper.service";
import { GoogleMap, MapGeocoder } from "@angular/google-maps";
import { GemInputGoogleAddressSelect } from "../gem-input-google-address-select/gem-input-google-address-select.component";
import { MatDialog } from "@angular/material/dialog";

export class GemInputGoogleAddressEvent {
  bounds: google.maps.LatLngBounds;
  markerPositions: google.maps.LatLngLiteral[];
}

@Component({
  selector: "gem-input-google-address",
  templateUrl: "./gem-input-google-address.component.html",
  styleUrls: ["./gem-input-google-address.component.scss"],
})
export class GemInputGoogleAddressComponent implements OnInit, OnDestroy, AfterViewInit {
  @Output() change = new EventEmitter();
  @ViewChild("streetNameElRef") streetNameElRef: ElementRef;
  @ViewChild("streetNumberElRef") streetNumberElRef: ElementRef;

  @ViewChild(GoogleMap) map: GoogleMap;
  markerPositions: google.maps.LatLngLiteral[] = [];

  streetNameInputControl: UntypedFormControl;
  streetNameIsRequired: boolean;
  streetNameRequiredErrorMessage: string;

  streetNumberInputControl: UntypedFormControl;
  streetNumberIsRequired: boolean;
  streetNumberRequiredErrorMessage: string;

  postalCodeInputControl: UntypedFormControl;
  postalCodeIsRequired: boolean;
  postalCodeRequiredErrorMessage: string;

  cityInputControl: UntypedFormControl;
  cityIsRequired: boolean;
  cityRequiredErrorMessage: string;

  countryInputControl: UntypedFormControl;
  countryIsRequired: boolean;
  countryRequiredErrorMessage: string;

  langSubscription: Subscription;

  @Input() streetNamePlaceholder: string;
  @Input() streetNameHint = "";
  @Input() streetNumberPlaceholder: string;
  @Input() streetNumberHint = "";
  @Input() postalCodePlaceholder: string;
  @Input() postalCodeHint = "";
  @Input() cityPlaceholder: string;
  @Input() cityHint = "";
  @Input() countryPlaceholder: string;
  @Input() countryHint = "";
  @Input() showCheckBoxes: boolean = false;
  @Input() streetCheckboxControl: FormControl;
  @Input() postalCodeCheckboxControl: FormControl;
  @Input() cityCheckboxControl: FormControl;
  @Input() countryCheckboxControl: FormControl;

  route: string = "";
  streetName: string = "";
  streetNumber: string = "";
  subLocalityLevel1: string = "";
  city: string = "";
  administrativeAreaLevel3: string = "";
  administrativeAreaLevel1: string = "";
  country: string = "";
  postalCode: string = "";

  @Input() set streetNameControl(controlObj: UntypedFormControl) {
    this.setControl(controlObj, "STREET_NAME");
  }

  get streetNameControl(): UntypedFormControl {
    return this.streetNameInputControl;
  }

  @Input() set streetNumberControl(controlObj: UntypedFormControl) {
    this.setControl(controlObj, "STREET_NUMBER");
  }

  get streetNumberControl(): UntypedFormControl {
    return this.streetNumberInputControl;
  }

  @Input() set postalCodeControl(controlObj: UntypedFormControl) {
    this.setControl(controlObj, "POSTAL_CODE");
  }

  get postalCodeControl(): UntypedFormControl {
    return this.postalCodeInputControl;
  }

  @Input() set cityControl(controlObj: UntypedFormControl) {
    this.setControl(controlObj, "CITY");
  }

  get cityControl(): UntypedFormControl {
    return this.cityInputControl;
  }

  @Input() set countryControl(controlObj: UntypedFormControl) {
    this.setControl(controlObj, "COUNTRY");
  }

  get countryControl(): UntypedFormControl {
    return this.countryInputControl;
  }

  private setControl(controlObj: UntypedFormControl, type: string) {
    if (type === "STREET_NAME") {
      this.streetNameInputControl = controlObj;
      this.streetNameIsRequired = this.formHelper.checkRequiredValidator(
        this.streetNameInputControl,
      );
      this.streetName = controlObj.value;

      const placeholderInputValue = this.streetNamePlaceholder;
      const hintInputValue = this.streetNameHint;

      if (this.streetNamePlaceholder && this.streetNamePlaceholder !== "") {
        this.streetNamePlaceholder = this.translateService.instant(this.streetNamePlaceholder);
      }
      if (this.streetNameHint !== "") {
        this.streetNameHint = this.translateService.instant(this.streetNameHint);
      }

      this.langSubscription = this.translateService.onLangChange.subscribe(
        (event: LangChangeEvent) => {
          if (this.streetNamePlaceholder && this.streetNamePlaceholder !== "") {
            this.streetNamePlaceholder = this.translateService.instant(placeholderInputValue);
          }
          if (this.streetNameHint !== "") {
            this.streetNameHint = this.translateService.instant(hintInputValue);
          }
          this.streetNameRequiredErrorMessage = this.formHelper.createRequiredErrorMessage(
            this.streetNamePlaceholder,
          );
        },
      );

      this.streetNameRequiredErrorMessage = this.formHelper.createRequiredErrorMessage(
        this.streetNamePlaceholder,
      );
    } else if (type === "STREET_NUMBER") {
      this.streetNumberInputControl = controlObj;
      this.streetNumberIsRequired = this.formHelper.checkRequiredValidator(
        this.streetNumberInputControl,
      );
      this.streetNumber = controlObj.value;

      const placeholderInputValue = this.streetNumberPlaceholder;
      const hintInputValue = this.streetNumberHint;

      if (this.streetNumberPlaceholder && this.streetNumberPlaceholder !== "") {
        this.streetNumberPlaceholder = this.translateService.instant(this.streetNumberPlaceholder);
      }
      if (this.streetNumberHint !== "") {
        this.streetNumberHint = this.translateService.instant(this.streetNumberHint);
      }

      this.langSubscription = this.translateService.onLangChange.subscribe(
        (event: LangChangeEvent) => {
          if (this.streetNumberPlaceholder && this.streetNumberPlaceholder !== "") {
            this.streetNumberPlaceholder = this.translateService.instant(placeholderInputValue);
          }
          if (this.streetNumberHint !== "") {
            this.streetNumberHint = this.translateService.instant(hintInputValue);
          }
          this.streetNumberRequiredErrorMessage = this.formHelper.createRequiredErrorMessage(
            this.streetNumberPlaceholder,
          );
        },
      );

      this.streetNumberRequiredErrorMessage = this.formHelper.createRequiredErrorMessage(
        this.streetNumberPlaceholder,
      );
    } else if (type === "POSTAL_CODE") {
      this.postalCodeInputControl = controlObj;
      this.postalCodeIsRequired = this.formHelper.checkRequiredValidator(
        this.postalCodeInputControl,
      );
      this.postalCode = controlObj.value;

      const placeholderInputValue = this.postalCodePlaceholder;
      const hintInputValue = this.postalCodeHint;

      if (this.postalCodePlaceholder && this.postalCodePlaceholder !== "") {
        this.postalCodePlaceholder = this.translateService.instant(this.postalCodePlaceholder);
      }
      if (this.postalCodeHint !== "") {
        this.postalCodeHint = this.translateService.instant(this.postalCodeHint);
      }

      this.langSubscription = this.translateService.onLangChange.subscribe(
        (event: LangChangeEvent) => {
          if (this.postalCodePlaceholder && this.postalCodePlaceholder !== "") {
            this.postalCodePlaceholder = this.translateService.instant(placeholderInputValue);
          }
          if (this.postalCodeHint !== "") {
            this.postalCodeHint = this.translateService.instant(hintInputValue);
          }
          this.postalCodeRequiredErrorMessage = this.formHelper.createRequiredErrorMessage(
            this.postalCodePlaceholder,
          );
        },
      );

      this.postalCodeRequiredErrorMessage = this.formHelper.createRequiredErrorMessage(
        this.postalCodePlaceholder,
      );
    } else if (type === "CITY") {
      this.cityInputControl = controlObj;
      this.cityIsRequired = this.formHelper.checkRequiredValidator(this.cityInputControl);
      this.city = controlObj.value;

      const placeholderInputValue = this.cityPlaceholder;
      const hintInputValue = this.cityHint;

      if (this.cityPlaceholder && this.cityPlaceholder !== "") {
        this.cityPlaceholder = this.translateService.instant(this.cityPlaceholder);
      }
      if (this.cityHint !== "") {
        this.cityHint = this.translateService.instant(this.cityHint);
      }

      this.langSubscription = this.translateService.onLangChange.subscribe(
        (event: LangChangeEvent) => {
          if (this.cityPlaceholder && this.cityPlaceholder !== "") {
            this.cityPlaceholder = this.translateService.instant(placeholderInputValue);
          }
          if (this.cityHint !== "") {
            this.cityHint = this.translateService.instant(hintInputValue);
          }
          this.cityRequiredErrorMessage = this.formHelper.createRequiredErrorMessage(
            this.cityPlaceholder,
          );
        },
      );

      this.cityRequiredErrorMessage = this.formHelper.createRequiredErrorMessage(
        this.cityPlaceholder,
      );
    } else if (type === "COUNTRY") {
      this.countryInputControl = controlObj;
      this.countryIsRequired = this.formHelper.checkRequiredValidator(this.countryInputControl);
      this.country = controlObj.value;

      const placeholderInputValue = this.countryPlaceholder;
      const hintInputValue = this.countryHint;

      if (this.countryPlaceholder && this.countryPlaceholder !== "") {
        this.countryPlaceholder = this.translateService.instant(this.countryPlaceholder);
      }
      if (this.countryHint !== "") {
        this.countryHint = this.translateService.instant(this.countryHint);
      }

      this.langSubscription = this.translateService.onLangChange.subscribe(
        (event: LangChangeEvent) => {
          if (this.countryPlaceholder && this.countryPlaceholder !== "") {
            this.countryPlaceholder = this.translateService.instant(placeholderInputValue);
          }
          if (this.countryHint !== "") {
            this.countryHint = this.translateService.instant(hintInputValue);
          }
          this.countryRequiredErrorMessage = this.formHelper.createRequiredErrorMessage(
            this.countryPlaceholder,
          );
        },
      );

      this.countryRequiredErrorMessage = this.formHelper.createRequiredErrorMessage(
        this.countryPlaceholder,
      );
    }
  }

  constructor(
    private translateService: TranslateService,
    private formHelper: FormHelperService,
    private geocoder: MapGeocoder,
    public dialog: MatDialog,
  ) {}

  ngOnInit() {}

  ngAfterViewInit(): void {
    const options: google.maps.places.AutocompleteOptions = {
      types: ["address"],
    };
    const searchBox = new google.maps.places.Autocomplete(
      this.streetNameElRef.nativeElement,
      options,
    );
    searchBox.addListener("place_changed", () => {
      const places: google.maps.places.PlaceResult[] = [];
      places.push(searchBox.getPlace());
      this.mapGooglePlaceToAddress(places);
      this.streetNumberElRef.nativeElement.focus();
    });
  }

  onAddressChange(): void {
    const search = `${this.streetName} ${this.streetNumber}, ${this.city}, ${this.country}`;
    this.geocoder
      .geocode({
        address: search,
      })
      .subscribe(({ results }) => {
        this.mapGooglePlaceToAddress(results);
        this.streetNumberElRef.nativeElement.focus();
      });
  }

  private mapGooglePlaceToAddress(places: google.maps.places.PlaceResult[]): void {
    if (places.length === 0) {
      return;
    }

    let place: google.maps.places.PlaceResult;

    if (places.length > 1) {
      const dialogRef = this.dialog.open(GemInputGoogleAddressSelect, {
        data: {
          places: places,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        place = result;
        this.autocompleteFields(place);
        this.streetNumberElRef.nativeElement.focus();
      });
    } else {
      this.autocompleteFields(places[0]);
    }
  }

  private autocompleteFields(place: google.maps.places.PlaceResult): void {
    this.clearFields();

    const bounds = new google.maps.LatLngBounds();
    let marker = {
      lat: 0,
      lng: 0,
    };

    let foundRoute: boolean = false;

    if (!place.address_components) {
      return;
    }

    for (var i = 0; i < place.address_components.length; i++) {
      for (var j = 0; j < place.address_components[i].types.length; j++) {
        if (place.address_components[i].types[j] == "route") {
          this.route = place.address_components[i].long_name;
          this.streetName = this.route;
          foundRoute = true;
          this.streetNameInputControl.setValue(this.streetName);
        } else if (place.address_components[i].types[j] == "street_number") {
          this.streetNumber = place.address_components[i].long_name;
          this.streetNumberInputControl.setValue(this.streetNumber);
        } else if (place.address_components[i].types[j] == "sublocality_level_1") {
          this.subLocalityLevel1 = place.address_components[i].long_name;
        } else if (place.address_components[i].types[j] == "locality") {
          this.city = place.address_components[i].long_name;
          this.cityInputControl.setValue(this.city);
        } else if (place.address_components[i].types[j] == "administrative_area_level_3") {
          this.administrativeAreaLevel3 = place.address_components[i].long_name;
        } else if (place.address_components[i].types[j] == "administrative_area_level_1") {
          this.administrativeAreaLevel1 = place.address_components[i].long_name;
        } else if (place.address_components[i].types[j] == "country") {
          this.country = place.address_components[i].long_name;
          this.countryInputControl.setValue(this.country);
        } else if (place.address_components[i].types[j] == "postal_code") {
          this.postalCode = place.address_components[i].long_name;
          this.postalCodeInputControl.setValue(this.postalCode);
        }
      }
    }

    if (!foundRoute) {
      this.streetNameElRef.nativeElement.value = "";
    }

    if (!place.geometry || !place.geometry.location) {
      return;
    }
    if (place.geometry.viewport) {
      bounds.union(place.geometry.viewport);
    } else {
      bounds.extend(place.geometry.location);
    }

    if (place.geometry.location) {
      this.markerPositions = [];

      marker = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
    }

    this.markerPositions.push(marker);

    const event: GemInputGoogleAddressEvent = new GemInputGoogleAddressEvent();
    event.bounds = bounds;
    event.markerPositions = this.markerPositions;

    this.change.emit(event);
  }

  private clearFields(): void {
    this.subLocalityLevel1 = "";
    this.city = "";
    this.administrativeAreaLevel3 = "";
    this.administrativeAreaLevel1 = "";
    this.country = "";
    this.postalCode = "";
    this.streetNumber = "";
  }

  trim() {
    this.formHelper.trimWhitespace(this.streetNameControl);
  }

  ngOnDestroy() {
    if (this.langSubscription) {
      this.langSubscription.unsubscribe();
    }
  }
}

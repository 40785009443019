import { Injectable } from "@angular/core";
import { DefaultDataService, HttpUrlGenerator } from "@ngrx/data";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Endpoint, UwlService } from "projects/uwl-frontend/src/app/services/uwl/uwl.service";

@Injectable()
export class UwlEndpointDataService extends DefaultDataService<Endpoint> {
  constructor(
    http: HttpClient,
    httpUrlGenerator: HttpUrlGenerator,
    private uwlService: UwlService,
  ) {
    super("Endpoint", http, httpUrlGenerator);
  }

  getAll(): Observable<Endpoint[]> {
    return this.uwlService.findAllEndpoints().pipe(map((res) => res.body));
  }
}

import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "gem-timeline",
  templateUrl: "./gem-timeline.component.html",
  styleUrls: ["./gem-timeline.component.scss"],
})
export class GemTimelineComponent implements OnInit {
  @Input() value: any[] = [];
  @Input() title: string;
  @Input() legendOpen: string;
  @Input() legendCurrent: string;
  @Input() legendFinished: string;

  constructor() {}

  ngOnInit(): void {}

  getClassName(index: number): string {
    const currentStatus: string = this.value[index].status;
    const nextStatus: string = this.value[index + 1].status;
    if (currentStatus === "CLOSED" && nextStatus === "CURRENT") {
      return `timeline-track-CLOSED-LINEAR`;
    } else if (currentStatus === "CURRENT" && nextStatus === "OPEN") {
      return `timeline-track-CURRENT-LINEAR`;
    } else {
      return `timeline-track-${this.value[index].status}`;
    }
  }
}

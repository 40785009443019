import { createAction, props } from "@ngrx/store";
import { AddressDto, GematikProfile, PartnerDto, PartnerRoleDto } from "../../models/profile.model";
import { AssignmentGroupIdentityExtendedDto } from "../../models/profile.model";

export enum ProfileActions {
  LOAD_PROFILE = "[Profile] Load Profile",
  LOAD_PROFILE_SUCCESS = "[Profile] Load Profile Success",
  LOAD_PROFILE_FAIL = "[Profile] Load Profile Fail",
  UPDATE_PROFILE = "[Profile] Update Profile",
  UPDATE_PROFILE_SUCCESS = "[Profile] Update Profile Success",
  UPDATE_PROFILE_FAIL = "[Profile] Update Profile Fail",
  LOAD_AGIS = "[Profile] Load Assignment Group Identities",
  LOAD_AGIS_SUCCESS = "[Profile] Load Assignment Group Identities Success",
  LOAD_AGIS_FAIL = "[Profile] Load Assignment Group Identities Fail",
  LOAD_AGI_ADDRESSES = "[Profile] Load Assignment Group Identity Addresses",
  LOAD_AGI_ADDRESSES_SUCCESS = "[Profile] Load Assignment Group Identity Addresses Success",
  LOAD_AGI_ADDRESSES_FAIL = "[Profile] Load Assignment Group Identity Addresses Fail",
  ADD_AGI_ADDRESS = "[Profile] Add Assignment Group Identity Address",
  ADD_AGI_ADDRESS_SUCCESS = "[Profile] Add Assignment Group Identity Address Success",
  ADD_AGI_ADDRESS_FAIL = "[Profile] Add Assignment Group Identity Address Fail",
  UPDATE_AGI_ADDRESS = "[Profile] Update Assignment Group Identity Address",
  UPDATE_AGI_ADDRESS_SUCCESS = "[Profile] Update Assignment Group Identity Address Success",
  UPDATE_AGI_ADDRESS_FAIL = "[Profile] Update Assignment Group Identity Address Fail",
  DELETE_AGI_ADDRESS = "[Profile] Delete Assignment Group Identity Address",
  DELETE_AGI_ADDRESS_SUCCESS = "[Profile] Delete Assignment Group Identity Address Success",
  DELETE_AGI_ADDRESS_FAIL = "[Profile] Delete Assignment Group Identity Address Fail",
  LOAD_AG_ADDRESSES = "[Profile] Load Assignment Group Addresses",
  LOAD_AG_ADDRESSES_SUCCESS = "[Profile] Load Assignment Group Addresses Success",
  LOAD_AG_ADDRESSES_FAIL = "[Profile] Load Assignment Group Addresses Fail",
  ADD_AG_ADDRESS = "[Profile] Add Assignment Group Address",
  ADD_AG_ADDRESS_SUCCESS = "[Profile] Add Assignment Group Address Success",
  ADD_AG_ADDRESS_FAIL = "[Profile] Add Assignment Group Address Fail",
  UPDATE_AG_ADDRESS = "[Profile] Update Assignment Group Address",
  UPDATE_AG_ADDRESS_SUCCESS = "[Profile] Update Assignment Group Address Success",
  UPDATE_AG_ADDRESS_FAIL = "[Profile] Update Assignment Group Address Fail",
  DELETE_AG_ADDRESS = "[Profile] Delete Assignment Group Address",
  DELETE_AG_ADDRESS_SUCCESS = "[Profile] Delete Assignment Group Address Success",
  DELETE_AG_ADDRESS_FAIL = "[Profile] Delete Assignment Group Address Fail",
  LOAD_PARTNER = "[Profile] Load Partner",
  LOAD_PARTNER_SUCCESS = "[Profile] Load Partner Success",
  LOAD_PARTNER_FAIL = "[Profile] Load Partner Fail",
  UPDATE_PARTNER = "[Profile] Update Partner",
  UPDATE_PARTNER_SUCCESS = "[Profile] Update Partner Success",
  UPDATE_PARTNER_FAIL = "[Profile] Update Partner Fail",
  LOAD_PARTNER_ROLES = "[Profile] Load Partner Roles",
  LOAD_PARTNER_ROLES_SUCCESS = "[Profile] Load Partner Roles Success",
  LOAD_PARTNER_ROLES_FAIL = "[Profile] Load Partner Roles Fail",
}

export const LoadProfile = createAction(ProfileActions.LOAD_PROFILE);
export const LoadProfileSuccess = createAction(
  ProfileActions.LOAD_PROFILE_SUCCESS,
  props<{ payload: GematikProfile }>(),
);
export const LoadProfileFail = createAction(
  ProfileActions.LOAD_PROFILE_FAIL,
  props<{ payload: any }>(),
);
export const UpdateProfile = createAction(
  ProfileActions.UPDATE_PROFILE,
  props<{ payload: GematikProfile }>(),
);
export const UpdateProfileSuccess = createAction(
  ProfileActions.UPDATE_PROFILE_SUCCESS,
  props<{ payload: GematikProfile }>(),
);
export const UpdateProfileFail = createAction(
  ProfileActions.UPDATE_PROFILE_FAIL,
  props<{ payload: GematikProfile }>(),
);
export const LoadAgis = createAction(ProfileActions.LOAD_AGIS);
export const LoadAgisSuccess = createAction(
  ProfileActions.LOAD_AGIS_SUCCESS,
  props<{ payload: AssignmentGroupIdentityExtendedDto[] }>(),
);
export const LoadAgisFail = createAction(ProfileActions.LOAD_AGIS_FAIL, props<{ payload: any }>());

export const LoadAgiAddresses = createAction(
  ProfileActions.LOAD_AGI_ADDRESSES,
  props<{ id: string }>(),
);
export const LoadAgiAddressesSuccess = createAction(
  ProfileActions.LOAD_AGI_ADDRESSES_SUCCESS,
  props<{ id: string; payload: AddressDto[] }>(),
);
export const LoadAgiAddressesFail = createAction(
  ProfileActions.LOAD_AGI_ADDRESSES_FAIL,
  props<{ payload: any }>(),
);
export const AddAgiAddress = createAction(
  ProfileActions.ADD_AGI_ADDRESS,
  props<{ agiId: string; payload: AddressDto }>(),
);
export const AddAgiAddressSuccess = createAction(
  ProfileActions.ADD_AGI_ADDRESS_SUCCESS,
  props<{ payload: AddressDto }>(),
);
export const AddAgiAddressFail = createAction(
  ProfileActions.ADD_AGI_ADDRESS_FAIL,
  props<{ payload: any }>(),
);
export const UpdateAgiAddress = createAction(
  ProfileActions.UPDATE_AGI_ADDRESS,
  props<{ agiId: string; payload: AddressDto }>(),
);
export const UpdateAgiAddressSuccess = createAction(
  ProfileActions.UPDATE_AGI_ADDRESS_SUCCESS,
  props<{ payload: AddressDto }>(),
);
export const UpdateAgiAddressFail = createAction(
  ProfileActions.UPDATE_AGI_ADDRESS_FAIL,
  props<{ payload: any }>(),
);
export const DeleteAgiAddress = createAction(
  ProfileActions.DELETE_AGI_ADDRESS,
  props<{ id: string }>(),
);
export const DeleteAgiAddressSuccess = createAction(
  ProfileActions.DELETE_AGI_ADDRESS_SUCCESS,
  props<{ payload: AddressDto }>(),
);
export const DeleteAgiAddressFail = createAction(
  ProfileActions.DELETE_AGI_ADDRESS_FAIL,
  props<{ payload: any }>(),
);
export const LoadAgAddresses = createAction(
  ProfileActions.LOAD_AG_ADDRESSES,
  props<{ id: string }>(),
);
export const LoadAgAddressesSuccess = createAction(
  ProfileActions.LOAD_AG_ADDRESSES_SUCCESS,
  props<{ id: string; payload: AddressDto[] }>(),
);
export const LoadAgAddressesFail = createAction(
  ProfileActions.LOAD_AG_ADDRESSES_FAIL,
  props<{ payload: any }>(),
);
export const AddAgAddress = createAction(
  ProfileActions.ADD_AG_ADDRESS,
  props<{ agId: string; payload: AddressDto }>(),
);
export const AddAgAddressSuccess = createAction(
  ProfileActions.ADD_AG_ADDRESS_SUCCESS,
  props<{ payload: AddressDto }>(),
);
export const AddAgAddressFail = createAction(
  ProfileActions.ADD_AG_ADDRESS_FAIL,
  props<{ payload: any }>(),
);
export const UpdateAgAddress = createAction(
  ProfileActions.UPDATE_AG_ADDRESS,
  props<{ agId: string; payload: AddressDto }>(),
);
export const UpdateAgAddressSuccess = createAction(
  ProfileActions.UPDATE_AG_ADDRESS_SUCCESS,
  props<{ payload: AddressDto }>(),
);
export const UpdateAgAddressFail = createAction(
  ProfileActions.UPDATE_AG_ADDRESS_FAIL,
  props<{ payload: any }>(),
);
export const DeleteAgAddress = createAction(
  ProfileActions.DELETE_AG_ADDRESS,
  props<{ id: string }>(),
);
export const DeleteAgAddressSuccess = createAction(
  ProfileActions.DELETE_AG_ADDRESS_SUCCESS,
  props<{ payload: AddressDto }>(),
);
export const DeleteAgAddressFail = createAction(
  ProfileActions.DELETE_AG_ADDRESS_FAIL,
  props<{ payload: any }>(),
);
export const LoadPartner = createAction(ProfileActions.LOAD_PARTNER, props<{ payload: string }>());
export const LoadPartnerSuccess = createAction(
  ProfileActions.LOAD_PARTNER_SUCCESS,
  props<{ payload: PartnerDto }>(),
);
export const LoadPartnerFail = createAction(
  ProfileActions.LOAD_PARTNER_FAIL,
  props<{ payload: any }>(),
);
export const UpdatePartner = createAction(
  ProfileActions.UPDATE_PARTNER,
  props<{ payload: PartnerDto }>(),
);
export const UpdatePartnerSuccess = createAction(
  ProfileActions.UPDATE_PARTNER_SUCCESS,
  props<{ payload: PartnerDto }>(),
);
export const UpdatePartnerFail = createAction(
  ProfileActions.UPDATE_PARTNER_FAIL,
  props<{ payload: any }>(),
);
export const LoadPartnerRoles = createAction(
  ProfileActions.LOAD_PARTNER_ROLES,
  props<{ payload: string }>(),
);
export const LoadPartnerRolesSuccess = createAction(
  ProfileActions.LOAD_PARTNER_ROLES_SUCCESS,
  props<{ payload: PartnerRoleDto[] }>(),
);
export const LoadPartnerRolesFail = createAction(
  ProfileActions.LOAD_PARTNER_ROLES_FAIL,
  props<{ payload: any }>(),
);

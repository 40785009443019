import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "oauth-redirect",
  templateUrl: "./oauth-redirect.component.html",
  styleUrls: ["./oauth-redirect.component.scss"],
})
export class OauthRedirectComponent implements OnInit {
  constructor(private route: ActivatedRoute, private router: Router) {}
  ngOnInit() {
    // this.router.navigate([localStorage.getItem("pathname")]);
  }
}

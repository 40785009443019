import { Injectable } from "@angular/core";

import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { map, switchMap, catchError } from "rxjs/operators";

import * as profileActions from "../actions/profile.actions";
import * as fromServices from "../../services";
import { SnackBarService } from "gematik-shared";

@Injectable()
export class ProfileEffects {
  constructor(
    private actions$: Actions,
    private profileService: fromServices.ProfileService,
    private snackBarService: SnackBarService,
  ) {}

  loadProfile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(profileActions.LoadProfile),
      switchMap((payload: any) => {
        return this.profileService.getProfile().pipe(
          map((response) => profileActions.LoadProfileSuccess({ payload: response })),
          catchError((error) => of(profileActions.LoadProfileFail(error))),
        );
      }),
    );
  });

  updateProfile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(profileActions.UpdateProfile),
      switchMap((obj) => {
        return this.profileService.updateProfile(obj.payload).pipe(
          map((response) => {
            this.snackBarService.openSuccess("Successfully updated profile");
            return profileActions.UpdateProfileSuccess({ payload: response });
          }),
          catchError((error) => of(profileActions.UpdateProfileFail(error))),
        );
      }),
    );
  });

  loadAssignmentGroupIdentities$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(profileActions.LoadAgis),
      switchMap((payload: any) => {
        return this.profileService.getAssignmentGroupIdentities().pipe(
          map((response) => profileActions.LoadAgisSuccess({ payload: response })),
          catchError((error) => of(profileActions.LoadAgisFail(error))),
        );
      }),
    );
  });

  loadAssignmentGroupIdentityAddresses$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(profileActions.LoadAgiAddresses),
      switchMap((payload: any) => {
        return this.profileService.getAssignmentGroupIdentityAddresses(payload.id).pipe(
          map((response) =>
            profileActions.LoadAgiAddressesSuccess({ id: payload.id, payload: response }),
          ),
          catchError((error) => of(profileActions.LoadAgiAddressesFail(error))),
        );
      }),
    );
  });

  addAssignmentGroupIdentityAddress$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(profileActions.AddAgiAddress),
      switchMap((payload: any) => {
        return this.profileService
          .addAssignmentGroupIdentityAddress(payload.agiId, payload.payload)
          .pipe(
            map((response) => profileActions.AddAgiAddressSuccess({ payload: response })),
            catchError((error) => of(profileActions.AddAgiAddressFail(error))),
          );
      }),
    );
  });

  updateAssignmentGroupIdentityAddress$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(profileActions.UpdateAgiAddress),
      switchMap((payload: any) => {
        return this.profileService
          .updateAssignmentGroupIdentityAddress(payload.agiId, payload.payload)
          .pipe(
            map((response) => profileActions.UpdateAgiAddressSuccess({ payload: response })),
            catchError((error) => of(profileActions.UpdateAgiAddressFail(error))),
          );
      }),
    );
  });

  deleteAssignmentGroupIdentityAddress$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(profileActions.DeleteAgiAddress),
      switchMap((payload: any) => {
        return this.profileService.deleteAssignmentGroupIdentityAddress(payload.id).pipe(
          map((response) => profileActions.DeleteAgiAddressSuccess({ payload: response })),
          catchError((error) => of(profileActions.DeleteAgiAddressFail(error))),
        );
      }),
    );
  });

  loadAssignmentGroupAddresses$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(profileActions.LoadAgAddresses),
      switchMap((payload: any) => {
        return this.profileService.getAssignmentGroupAddresses(payload.id).pipe(
          map((response) =>
            profileActions.LoadAgAddressesSuccess({ id: payload.id, payload: response }),
          ),
          catchError((error) => of(profileActions.LoadAgAddressesFail(error))),
        );
      }),
    );
  });

  addAssignmentGroupAddress$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(profileActions.AddAgAddress),
      switchMap((payload: any) => {
        return this.profileService.addAssignmentGroupAddress(payload.agId, payload.payload).pipe(
          map((response) => profileActions.AddAgAddressSuccess({ payload: response })),
          catchError((error) => of(profileActions.AddAgAddressFail(error))),
        );
      }),
    );
  });

  updateAssignmentGroupAddress$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(profileActions.UpdateAgAddress),
      switchMap((payload: any) => {
        return this.profileService.updateAssignmentGroupAddress(payload.agId, payload.payload).pipe(
          map((response) => profileActions.UpdateAgAddressSuccess({ payload: response })),
          catchError((error) => of(profileActions.UpdateAgAddressFail(error))),
        );
      }),
    );
  });

  deleteAssignmentGroupAddress$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(profileActions.DeleteAgAddress),
      switchMap((payload: any) => {
        return this.profileService.deleteAssignmentGroupAddress(payload.id).pipe(
          map((response) => profileActions.DeleteAgAddressSuccess({ payload: response })),
          catchError((error) => of(profileActions.DeleteAgAddressFail(error))),
        );
      }),
    );
  });

  loadPartner$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(profileActions.LoadPartner),
      switchMap((payload: any) => {
        return this.profileService.getPartner(payload.payload).pipe(
          map((response) => profileActions.LoadPartnerSuccess({ payload: response })),
          catchError((error) => of(profileActions.LoadPartnerFail(error))),
        );
      }),
    );
  });

  updatePartner$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(profileActions.UpdatePartner),
      switchMap((payload: any) => {
        return this.profileService.updatePartner(payload.payload).pipe(
          map((response) => profileActions.UpdatePartnerSuccess({ payload: response })),
          catchError((error) => of(profileActions.UpdatePartnerFail(error))),
        );
      }),
    );
  });

  loadPartnerRoles$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(profileActions.LoadPartnerRoles),
      switchMap((payload: any) => {
        return this.profileService.getPartnerRoles(payload.payload).pipe(
          map((response) => profileActions.LoadPartnerRolesSuccess({ payload: response })),
          catchError((error) => of(profileActions.LoadPartnerRolesFail(error))),
        );
      }),
    );
  });
}

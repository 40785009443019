import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogConfirmationErrorData {
  title: string;
  errors: Array<string>;
  confirmAction: any;
}

@Component({
  selector: 'gem-confirmation-error-dialog',
  templateUrl: './gem-confirmation-error-dialog.component.html',
  styleUrls: ['./gem-confirmation-error-dialog.component.scss'],
})
export class GemConfirmationErrorDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<GemConfirmationErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogConfirmationErrorData
  ) {}

  cancelAction(): void {
    this.dialogRef.close();
  }

  confirmAction(): void {
    this.data.confirmAction();
    this.dialogRef.close();
  }
}

import { FormHelperService } from "../../helper/form-helper.service";
import { TranslateService } from "@ngx-translate/core";
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { MatRadioChange } from "@angular/material/radio";

@Component({
  selector: "gem-radio-button-group",
  templateUrl: "./gem-radio-button-group.component.html",
  styleUrls: ["./gem-radio-button-group.component.scss"],
})
export class GemRadioButtonGroupComponent implements OnInit {
  inputControl: UntypedFormControl;

  requiredErrorMessage: string;
  isRequired: boolean;

  @Input() label;
  @Input() valueLabels;

  @Input() set control(controlObj: UntypedFormControl) {
    this.inputControl = controlObj;
    if (this.inputControl && this.inputControl.value === true) {
      this.inputControl.setValue("true");
    }
    if (this.inputControl && this.inputControl.value === false) {
      this.inputControl.setValue("false");
    }
    this.isRequired = this.formHelper.checkRequiredValidator(this.inputControl);
  }

  @Output() change: EventEmitter<string> = new EventEmitter();

  get control(): UntypedFormControl {
    return this.inputControl;
  }

  constructor(private translateService: TranslateService, private formHelper: FormHelperService) {}

  ngOnInit() {}

  onChange(event: MatRadioChange): void {
    this.change.emit(event.value);
  }
}

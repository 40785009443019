import { Component, EventEmitter, Output } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { ValueLabel } from "gematik-form-library/lib/gem-components/value-label";

@Component({
  selector: "gem-feedback-category-form",
  styleUrls: ["gem-feedback-category-form.component.scss"],
  template: `
    <div class="row mb-3">
      <div class="col-sm-12">
        <p [innerHTML]="'feedback.body' | translate"></p>
      </div>
      <div class="col-sm-12 form-width">
        <gem-select
          [placeholder]="'feedback.category'"
          [control]="this.form.get('category')"
          [options]="categories"
        >
        </gem-select>
      </div>

      <div class="col-sm-12 text-center">
        <button
          class="mat-focus-indicator btn btn-primary mat-button mat-button-base"
          (click)="onChangeStep()"
          cdkFocusInitial
          [disabled]="!isFormValid()"
        >
          {{ "feedback.start" | translate }}
        </button>
      </div>
    </div>
  `,
})
export class GemFeedbackCategoryFormComponent {
  @Output() changeStep: EventEmitter<string> = new EventEmitter<string>();

  form = this.fb.group({
    category: ["", Validators.required],
  });

  categories: ValueLabel[] = [
    { value: "GENERAL", label: this.translateService.instant("feedback.categoryGeneral") },
    {
      value: "FEATURE",
      label: this.translateService.instant("feedback.categoryFeature"),
    },
    { value: "BUG", label: this.translateService.instant("feedback.categoryBug") },
  ];

  constructor(private translateService: TranslateService, private fb: FormBuilder) {}

  onChangeStep() {
    this.changeStep.emit(this.form.get("category").value);
  }

  isFormValid(): boolean {
    return this.form.valid;
  }
}

<mat-form-field>
  <input
    tab-directive
    type="text"
    placeholder="{{ placeholder }}"
    matInput
    [formControl]="inputControl"
    [matAutocomplete]="auto"
    [ngClass]="{ strikethrough: delete }"
    (changeEvent)="selectedFromTabEvent($event)"
  />
  <mat-autocomplete
    [ngClass]="{ strikethrough: delete }"
    #auto="matAutocomplete"
    [displayWith]="displayFn"
    (optionSelected)="selected($event)"
    autoActiveFirstOption="true"
  >
    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
      {{ option.label }}
    </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="inputControl.hasError('required')">{{ requiredErrorMessage }}</mat-error>
</mat-form-field>

<div class="row mt-1 gib-flex-center text--large">
  <div class="col-sm-12 form-width">
    <div class="row">
      <div class="col-sm-12">
        <div class="d-flex justify-content-between mt-3">
          <h1>
            <span>Impressum</span>
          </h1>
        </div>
      </div>
    </div>
    <hr />

    <div class="row">
      <div class="col-sm-12">
        <p>Herausgeber</p>
        <p>gematik GmbH</p>
        <p>Friedrichstraße 136</p>
        <p>10117 Berlin</p>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-sm-12">
        <p>Tel. +49 30 400 41-0</p>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-sm-12">
        <p>Geschäftsführung: Dr. Florian Fuhrmann, Brenya Adjei, Dr. Florian Hartge</p>
        <p>Registergericht: Amtsgericht Berlin-Charlottenburg</p>
        <p>Handelsregister-Nr.: HRB 96351</p>
        <p>Umsatzsteueridentifikationsnummer: DE241843684</p>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-sm-12">
        <h2>
          <span>Verantwortlich für den Inhalt</span>
        </h2>
        <p>Dr. Florian Fuhrmann, Brenya Adjei, Dr. Florian Hartge</p>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-sm-12">
        <h2>
          <span>Konzept, Design und Realisierung</span>
        </h2>
        <p>gematik GmbH</p>
        <p>Friedrichstraße 136</p>
        <p>10117 Berlin</p>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-sm-12">
        <h2>
          <span>{{ "legal-notice.sources.title" | translate }}</span>
        </h2>
        <p>{{ "legal-notice.sources.text" | translate }}</p>
      </div>
    </div>

    <div class="row mt-3 mb-3">
      <div class="col-sm-12 support">
        <h2>
          <span>Support:</span>
        </h2>
        <ul>
          <li>
            {{ "legal-notice.support.bullet-point-1" | translate }}
            <mat-icon
              role="img"
              class=""
              svgIcon="gem-feedback"
              style="cursor: pointer; width: 32px; height: 32px"
              (click)="onFeedback()"
            ></mat-icon>
          </li>
          <li [innerHTML]="'legal-notice.support.bullet-point-2' | translate"></li>
        </ul>
      </div>
    </div>
  </div>
</div>

<mat-form-field class="gem-text-area">
  <mat-label>{{ placeholder }}</mat-label>
  <textarea
    matInput
    [formControl]="inputControl"
    [cdkTextareaAutosize]
    [cdkAutosizeMinRows]="1"
    [cdkAutosizeMaxRows]="10"
    [minlength]="minChars"
    [maxlength]="maxChars"
  ></textarea>

  <mat-hint>{{ hint }}</mat-hint>

  <mat-error *ngIf="inputControl.hasError('required')"
    >{{ requiredErrorMessage }}
    <mat-hint align="end">{{ hint }}</mat-hint>
  </mat-error>
  <mat-error *ngIf="inputControl.hasError('minlength')"
    >{{ minLengthErrorMessage }}
    <mat-hint align="end">{{ hint }}</mat-hint>
  </mat-error>
</mat-form-field>

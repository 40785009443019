import { Component, Input, OnInit } from "@angular/core";
import { ValueLabel } from "../value-label";
import { UntypedFormControl } from "@angular/forms";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { FormHelperService } from "../../helper/form-helper.service";
import { Subscription } from "rxjs";

@Component({
  selector: "gem-select",
  templateUrl: "./gem-select.component.html",
  styleUrls: ["./gem-select.component.scss"],
})
export class GemSelectComponent implements OnInit {
  inputControl: UntypedFormControl;
  requiredErrorMessage: string;
  langSubscription: Subscription;

  @Input() placeholder: string;
  @Input() hint = "";
  @Input() options: ValueLabel[];
  @Input() disabled: boolean;
  @Input() delete: boolean;

  isRequired: boolean;

  @Input() set control(controlObj: UntypedFormControl) {
    this.inputControl = controlObj;
    this.isRequired = this.formHelper.checkRequiredValidator(this.inputControl);

    const placeholderInputValue = this.placeholder;
    const hintInputValue = this.hint;

    if (this.placeholder && this.placeholder !== "") {
      this.placeholder = this.translateService.instant(this.placeholder);
    }
    if (this.hint !== "") {
      this.hint = this.translateService.instant(this.hint);
    }
    this.langSubscription = this.translateService.onLangChange.subscribe(
      (event: LangChangeEvent) => {
        if (this.placeholder && this.placeholder !== "") {
          this.placeholder = this.translateService.instant(placeholderInputValue);
        }
        if (this.hint !== "") {
          this.hint = this.translateService.instant(hintInputValue);
        }
        this.requiredErrorMessage = this.formHelper.createRequiredErrorMessage(this.placeholder);
      },
    );
    this.requiredErrorMessage = this.formHelper.createRequiredErrorMessage(this.placeholder);
  }

  get control(): UntypedFormControl {
    return this.inputControl;
  }

  getOptionLabel(value: string): string {
    if (this.options) {
      const option = this.options.filter((option) => option.value === value)[0];
      if (option) {
        return this.translateService.instant(option.label);
      }
    }
    return null;
  }

  constructor(private translateService: TranslateService, private formHelper: FormHelperService) {}

  ngOnInit() {}
}
